import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { PHONE_NUMBER } from '../../../constants/OneRealMortgageConstants.ts';
import { ApplicationApi, SectionOutro } from '../../../openapi/atlantis';
import ErrorService from '../../../services/ErrorService.ts';
import { useAppDispatch } from '../../../slices/store';
import { ApplicationStepWithNewContent } from '../../../utils/ConfigDrivenFormUtils';
import Logger from '../../../utils/Logger';
import { getSherlockConfiguration } from '../../../utils/OpenapiConfigurationUtils';
import { formatPhoneNumber } from '../../../utils/StringUtils.ts';
import Confetti from '../../Confetti';
import ConfigDrivenContainerLayout from '../../Layout/ConfigDrivenContainerLayout';
import DefaultSplash from '../../DefaultSplash';
import { fetchAuthUserDetail } from '../../../slices/AuthSlice.ts';
import {
  OnboardingResponseRoleEnum,
  OnboardingResponseStatusEnum,
} from '../../../openapi/yenta/api.ts';
import envConfig from '../../../config';
import { cn } from '../../../utils/classUtils';

interface ConfigDrivenOnboardingOutroContainerProps {
  config: ApplicationStepWithNewContent<SectionOutro>;
}

const applicationTitle = (applicationStatus: OnboardingResponseStatusEnum) => {
  switch (applicationStatus) {
    case OnboardingResponseStatusEnum.PendingApproval:
      return `We've received your application!`;
    case OnboardingResponseStatusEnum.Approved:
      return 'Your application was approved!';
    case OnboardingResponseStatusEnum.Rejected:
      return 'Your application was not approved.';
    default:
      return 'Your application is in!';
  }
};

const ConfigDrivenOnboardingOutroContainer = ({
  config,
}: ConfigDrivenOnboardingOutroContainerProps) => {
  const { applicationId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicationStatus, setApplicationStatus] = useState<
    OnboardingResponseStatusEnum | undefined
  >(undefined);

  const { id } = config.content || {};

  const handleAgentUserRedirection = useCallback(async () => {
    const { userData } = await dispatch(fetchAuthUserDetail());
    const agentOnboardingData = userData?.onboardings?.find(
      (onboarding) => onboarding.role === OnboardingResponseRoleEnum.Agent,
    );
    if (agentOnboardingData) {
      window.location.assign(
        `${envConfig.boltBaseUrl}/redirect?jwtToken=${Cookies.get('jwtToken')}`,
      );
    } else {
      const onboardingApplication = userData?.onboardings?.find(
        (application) =>
          application.role ===
          OnboardingResponseRoleEnum.TransactionCoordinator,
      );
      setApplicationStatus(onboardingApplication?.status);
      setIsLoading(false);
    }
  }, [dispatch]);

  const submitOnboardingApplication = useCallback(async () => {
    try {
      await new ApplicationApi(
        await getSherlockConfiguration(),
      ).submitApplication(applicationId!);
      await handleAgentUserRedirection();
    } catch (e: any) {
      Logger.error('Failed to submit application:', e);
      ErrorService.notify('Failed to submit application', e);
    }
  }, [applicationId, handleAgentUserRedirection]);

  useEffect(() => {
    setIsLoading(true);
    submitOnboardingApplication();
  }, [submitOnboardingApplication]);

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  return (
    <ConfigDrivenContainerLayout
      hideMyLoans={!!applicationId}
      hideChat={!!applicationId}
    >
      <div key={id}>
        <div>
          <p
            className={cn(
              'font-poppins-medium text-4xl',
              applicationStatus === OnboardingResponseStatusEnum.Rejected
                ? 'text-primary-coral'
                : 'text-primary-skyblue',
            )}
          >
            {applicationTitle(applicationStatus!)}
          </p>
          <p className='font-inter-regular text-base text-primary-graphite mt-4'>
            {applicationStatus === OnboardingResponseStatusEnum.Approved ? (
              <>
                You can now log in with your credential at{' '}
                <a
                  href={envConfig.boltBaseUrl}
                  className='text-primary-skyblue font-bolder'
                >
                  {envConfig.boltBaseUrl}
                </a>
              </>
            ) : applicationStatus ===
              OnboardingResponseStatusEnum.PendingApproval ? (
              'Your account approval is pending!'
            ) : (
              ''
            )}
          </p>
        </div>

        {applicationStatus !== OnboardingResponseStatusEnum.Approved && (
          <div className='bg-whitesmoke p-2 rounded-lg mt-5'>
            <div className='flex flex-row items-center border-b border-b-silver p-2'>
              <FontAwesomeIcon
                icon={faCircleQuestion}
                fontSize={16}
                className='text-aqua'
              />
              <p className='font-poppins-medium text-base ml-2'>Questions?</p>
            </div>
            <div className='p-2'>
              <div className='flex flex-row items-center'>
                <p className='font-inter-regular text-base text-black'>
                  Call us at{' '}
                </p>
                <a href={`tel:${PHONE_NUMBER}`} className='pl-2'>
                  <p className='font-inter-medium text-base text-primary-skyblue'>
                    {formatPhoneNumber(PHONE_NUMBER)}
                  </p>
                </a>
              </div>
              <p className='font-inter-regular text-sm text-primary-graphite'>
                Monday - Friday, 9am - 9pm Eastern.
              </p>
            </div>
          </div>
        )}

        <Confetti />
      </div>
    </ConfigDrivenContainerLayout>
  );
};

export default ConfigDrivenOnboardingOutroContainer;
