import { MAX_FILE_SIZE } from '../constants/FileConstants';

export const isDocSizeValid = (file: any): boolean => {
  if (file.size! > MAX_FILE_SIZE) {
    return false;
  }

  return true;
};

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 'N/A';
  }

  const index = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes?.length - 1,
  );

  if (index === 0) {
    return `${bytes} ${sizes[index]}`;
  }

  return `${(bytes / 1024 ** index)?.toFixed(0)} ${sizes[index]}`;
};

export const isDocSizeInvalid = (file: File, maxDocSize: number): boolean => {
  return file?.size > maxDocSize;
};

export const isDocsSizeInvalid = (
  files: File[] = [],
  maxDocSize: number,
): boolean => {
  return !!files?.length && files?.some((file) => file?.size > maxDocSize);
};

export const validDocs = (files: File[] = [], maxDocSize: number) => {
  const validFiles = files?.filter((file) => file?.size <= maxDocSize);

  return validFiles;
};
