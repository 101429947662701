import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApplicationApi,
  ApplicationProgressResponse,
  ApplicationStep,
} from '../openapi/atlantis';
import {
  ChecklistApi,
  ChecklistResponse,
  ApplicationApi as sherlockApplicationApi,
} from '../openapi/sherlock';
import { AppDispatch, ApplicationState } from '../types';
import Logger from '../utils/Logger';
import {
  getAtlantisConfiguration,
  getSherlockConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import ErrorService from '../services/ErrorService.ts';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: ApplicationState = {
  currentQuestion: undefined,
  progress: undefined,
  loanProgress: undefined,
};

const ApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    saveCurrentQuestion(state, action: PayloadAction<ApplicationStep>) {
      state.currentQuestion = action.payload;
    },
    saveApplicationProgress(
      state,
      action: PayloadAction<ApplicationProgressResponse>,
    ) {
      state.progress = action.payload;
    },
    saveLoanProgress(state, action: PayloadAction<ChecklistResponse>) {
      state.loanProgress = action.payload;
    },
  },
});

export const {
  saveCurrentQuestion,
  saveApplicationProgress,
  saveLoanProgress,
} = ApplicationSlice.actions;

export const fetchApplicationProgress =
  (borrowerId: string) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).getProgressSummary(borrowerId);
      dispatch(saveApplicationProgress(data));
    } catch (e: any) {
      Logger.error('Failed to fetch application progress:', e);
      ErrorService.notify('Failed to fetch application progress', e);
      dispatch(showErrorToast('Failed to fetch application progress'));
    }
  };

export const fetchOnboardingApplicationProgress =
  (applicationId: string) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await new sherlockApplicationApi(
        await getSherlockConfiguration(),
      ).getProgressSummary(applicationId!);
      dispatch(saveApplicationProgress(data));
    } catch (e: any) {
      Logger.error('Failed to fetch application progress:', e);
      ErrorService.notify('Failed to fetch application progress', e);
      dispatch(showErrorToast('Failed to fetch application progress'));
    }
  };

export const fetchLoanProgress =
  (roadToSuccessId: string) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await new ChecklistApi(
        await getSherlockConfiguration(),
      ).getChecklistById(roadToSuccessId);
      dispatch(saveLoanProgress(data));
    } catch (e: any) {
      Logger.error('Failed to fetch loan progress:', e);
      ErrorService.notify('Failed to fetch loan progress', e);
      dispatch(showErrorToast('Failed to fetch loan progress'));
    }
  };
export default ApplicationSlice.reducer;
