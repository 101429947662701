import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConfigDrivenContainer from '../components/ConfigDriven/ConfigDrivenContainer.tsx';
import ErrorService from '../services/ErrorService.ts';
import {
  fetchOnboardingApplicationProgress,
  saveCurrentQuestion,
} from '../slices/ApplicationSlice.ts';
import { useAppDispatch, useAppSelector } from '../slices/store.ts';
import Logger from '../utils/Logger.ts';
import { getSherlockConfiguration } from '../utils/OpenapiConfigurationUtils.tsx';
import {
  ContentItem,
  ApplicationApi,
  AnswerQuestionRequestAnswersInner,
} from '../openapi/sherlock/index.ts';
import { fetchDropboxById } from '../slices/DropboxSlice.ts';
import {
  AnswerApplicationQuestionRequest,
  BorrowerDto,
} from '../openapi/atlantis/index.ts';

interface OnboardingApplicationRouteProps {}

const OnboardingApplicationRoute: React.FC<
  OnboardingApplicationRouteProps
> = () => {
  const {
    auth: { authUserDetail },
    application: { currentQuestion },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { applicationId } = useParams();

  const fetchDropboxData = useCallback(async () => {
    dispatch(
      fetchDropboxById({
        id: authUserDetail?.id!,
        dropboxId: authUserDetail?.dropboxId!,
      } as BorrowerDto),
    );
  }, [authUserDetail?.dropboxId, authUserDetail?.id, dispatch]);

  useEffect(() => {
    fetchDropboxData();
  }, [fetchDropboxData]);

  const onStart = async (_stepId: string) => {
    try {
      await new ApplicationApi(
        await getSherlockConfiguration(),
      ).acknowledgeSection((currentQuestion as ContentItem)?.id!);
    } catch (e: any) {
      Logger.error('Failed to acknowledge section:', e);
      ErrorService.notify('Failed to acknowledge section', e);
    }
  };

  const onNext = async (): Promise<ContentItem> => {
    try {
      const { data } = await new ApplicationApi(
        await getSherlockConfiguration(),
      ).getNextStep(applicationId!);

      await dispatch(saveCurrentQuestion(data));

      await dispatch(fetchOnboardingApplicationProgress(applicationId!));

      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch next question:', e);
      ErrorService.notify('Failed to fetch next question', e);
      throw e;
    }
  };

  const onNavigateToQuestion = async (
    questionId: string,
  ): Promise<ContentItem> => {
    try {
      const { data } = await new ApplicationApi(
        await getSherlockConfiguration(),
      ).getAnsweredStep(questionId);

      await dispatch(saveCurrentQuestion(data));

      await dispatch(fetchOnboardingApplicationProgress(applicationId!));

      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch question by id:', e);
      ErrorService.notify('Failed to fetch question by id', e);
      throw e;
    }
  };

  const onSubmit = async (formData: AnswerApplicationQuestionRequest) => {
    try {
      await new ApplicationApi(await getSherlockConfiguration()).answerQuestion(
        (currentQuestion as ContentItem)?.id!,
        {
          answers: formData.answers as AnswerQuestionRequestAnswersInner[],
        },
      );
    } catch (e: any) {
      Logger.error('Failed to submit question answer:', e);
      ErrorService.notify('Failed to submit question answer', e);
    }
  };

  return (
    <ConfigDrivenContainer
      onStart={onStart}
      onNext={onNext}
      onSubmit={onSubmit}
      onNavigateToQuestion={onNavigateToQuestion}
    />
  );
};

export default OnboardingApplicationRoute;
