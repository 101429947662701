import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useAppSelector } from '../../slices/store';
import { formatPhoneNumber } from '../../utils/StringUtils';
import { getFullName } from '../../utils/commonUtils';
import Avatar from '../Avatar';

type LoanOfficerCardProps = {};

const LoanOfficerCard: React.FC<LoanOfficerCardProps> = () => {
  const { loanDetail } = useAppSelector((state) => ({
    loanDetail: state.loan.loanDetail,
  }));

  const loanOfficerPhoneNumber = loanDetail?.loanOfficer?.phoneNumber || '';

  return (
    <div className='bg-[#F6F8FC] rounded-[10px] p-4 lg:p-6 mb-6 lg:mb-8'>
      <div className='font-inter-medium mb-3 text-black text-sm lg:text-base'>
        Questions? Your Mortgage Loan Advisor is here to help.
      </div>
      <div className='pt-3 pt-4.5 border-t border-primary-graphite'>
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row gap-2.5 items-center'>
            <Avatar
              size={isMobile || isTablet ? 'md' : 'xl'}
              name={getFullName(loanDetail?.loanOfficer!)}
            />

            <div>
              <div className='font-inter-semibold text-sm lg:text-base leading-5 text-black'>
                {getFullName(loanDetail?.loanOfficer!)}
              </div>
              <a href={`tel:${loanOfficerPhoneNumber}`}>
                <p className='font-inter-semibold text-xs leading-[18px] lg:text-sm lg:leading-5 text-reskin-primary-blue'>
                  {formatPhoneNumber(loanOfficerPhoneNumber)}
                </p>
              </a>
            </div>
          </div>
          <div className='font-inter-regular text-sm leading-[18px] text-reskin-primary-dark text-right'>
            <p className='block'>Monday - Friday</p>
            <p className='block mt-1'>9am - 9pm EST</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanOfficerCard;
