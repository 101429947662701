import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '../components/Avatar';
import ChecklistFiles from '../components/Checklists/ChecklistFiles';
import ChecklistStatus from '../components/Checklists/ChecklistStatus';
import ConfigDrivenTitle from '../components/ConfigDriven/Misc/ConfigDrivenTitle';
import DefaultSplash from '../components/DefaultSplash';
import ConfigDrivenChecklistContainerLayout from '../components/Layout/ConfigDrivenChecklistContainerLayout';
import ReadMoreLess from '../components/ReadMoreLess';
import { Question } from '../openapi/atlantis';
import { fetchLoanChecklistDetail } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';
import { RootState } from '../types';
import { getISOFormattedDate } from '../utils/DateUtils';
import { getLoanOfficerFullName } from '../utils/commonUtils';

type PreApprovalFormTaskDetailRouteProps = {};

const PreApprovalFormTaskDetailRoute: React.FC<
  PreApprovalFormTaskDetailRouteProps
> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { borrowerId, taskId: checklistId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { checklistDetail, loanDetail } = useSelector((state: RootState) => ({
    checklistDetail: state.loan.checklistDetail,
    loanDetail: state.loan.loanDetail,
  }));

  const checklistDetailContent: Question = checklistDetail?.content || {};

  const fetchCheckListDetail = useCallback(async () => {
    if (borrowerId && checklistId) {
      try {
        await dispatch(fetchLoanChecklistDetail(borrowerId, checklistId));
      } finally {
        setIsLoading(false);
      }
    }
  }, [borrowerId, checklistId, dispatch]);

  useEffect(() => {
    fetchCheckListDetail();
  }, [fetchCheckListDetail]);

  return (
    <ConfigDrivenChecklistContainerLayout
      BackButtonComponent={
        <div
          className='inline-flex flex-row items-center cursor-pointer gap-1.5 border border-[#BFC3CA] rounded-md py-1 px-2 mb-8'
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className='text-reskin-primary-dark text-sm lg:text-lg'
          />
          <div className='font-inter-medium text text-sm text-reskin-primary-dark'>
            Back
          </div>
        </div>
      }
    >
      {isLoading ? (
        <div className='h-[80vh] w-full'>
          <DefaultSplash />
        </div>
      ) : (
        <div>
          <div className='mb-4'>
            <ConfigDrivenTitle
              title={checklistDetailContent?.title || ''}
              textStyle='font-poppins-medium !text-lg lg:!text-2xl text-reskin-primary-dark'
            />
          </div>

          {!!checklistDetailContent?.subtitle && (
            <div className='mb-2.5 md:mb-4'>
              <ReadMoreLess text={checklistDetailContent?.subtitle} />
            </div>
          )}

          <div className='w-full h-0.5 bg-silver' />
          <div className='flex flex-row items-center gap-3 mt-4 md:mt-6 mb-3'>
            <p className='font-inter-medium text-sm md:text-base leading-6 text-primary-graphite'>
              Requested By:
            </p>
            <div className='flex flex-row items-center'>
              <Avatar
                name={getLoanOfficerFullName(
                  checklistDetail?.assignor!,
                  loanDetail?.loanOfficer!,
                )}
                size='sm'
              />
              <p className='font-inter-light text:base md:text-lg leading-[26px] text-reskin-primary-dark'>
                {getLoanOfficerFullName(
                  checklistDetail?.assignor!,
                  loanDetail?.loanOfficer!,
                )}
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-3 mb-3'>
            <p className='font-inter-medium text-sm md:text-base text-primary-graphite'>
              Due Date:
            </p>
            <p className='font-inter-light text:base md:text-lg md:leading-[26px] text-reskin-primary-dark'>
              {!!checklistDetail?.dueDate
                ? getISOFormattedDate(checklistDetail?.dueDate)
                : '--'}
            </p>
          </div>
          <div className='flex flex-row items-center gap-3 mb-3'>
            <p className='font-inter-medium text-sm md:text-base leading-6 text-primary-graphite'>
              Status:
            </p>
            <p>
              <ChecklistStatus status={checklistDetail?.status!} />
            </p>
          </div>

          <div className='mt-3 md:mt-5'>
            <ChecklistFiles checklistId={checklistId!} />
          </div>
        </div>
      )}
    </ConfigDrivenChecklistContainerLayout>
  );
};

export default PreApprovalFormTaskDetailRoute;
