import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';
import Button, { ButtonVariant } from './Button.tsx';

export type ConfirmationModalSizeVariantType =
  | 'default'
  | 'small'
  | 'large'
  | 'extraLarge'
  | 'fixed';

export interface ConfirmationModalProps {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  hideTitleCloseIcon?: boolean;
  size?: ConfirmationModalSizeVariantType;
  onClose?(): void;
  onConfirm?(): void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonLeftIcon?: React.ReactElement;
  cancelButtonVariant?: ButtonVariant;
  confirmButtonText?: string;
  confirmButtonLeftIcon?: React.ReactElement;
  confirmButtonVariant?: ButtonVariant;
  hideFooterButtons?: boolean;
  hideCancelButton?: boolean;
  closeIconColor?: string;
  containerClassNames?: string;
  contentContainerClassNames?: string;
  titleClassName?: string;
  children?: React.ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  subtitle,
  onClose,
  onConfirm,
  size = 'default',
  isSubmitting,
  isDisabled,
  cancelButtonText = 'Cancel',
  cancelButtonLeftIcon,
  cancelButtonVariant = 'primary-outline',
  confirmButtonText = 'Confirm',
  confirmButtonLeftIcon,
  confirmButtonVariant = 'primary',
  hideFooterButtons = false,
  hideCancelButton = false,
  hideTitleCloseIcon = false,
  closeIconColor,
  containerClassNames,
  contentContainerClassNames,
  titleClassName,
  children,
}) => {
  const sizeVariant: EnumMap<ConfirmationModalSizeVariantType, string> = {
    default: 'md:w-1/2 lg:w-1/4',
    large: 'md:w-3/5 lg:w-1/3',
    small: 'md:w-2/5 lg:w-1/5',
    extraLarge: 'md:w-4/5 lg:w-6/12',
    fixed: 'w-[420px]',
  };

  const contentSizeVariant: EnumMap<ConfirmationModalSizeVariantType, string> =
    {
      default: 'lg:w-3/4',
      large: 'lg:w-2/3',
      small: 'lg:w-full',
      extraLarge: 'lg:w-2/3',
      fixed: 'lg:w-full',
    };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      title='confirmation-modal'
      className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window'
    >
      <div
        className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'
        role='button'
        aria-label='close'
        onClick={onClose}
      />
      <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
        <div
          aria-label='confirmation-modal'
          className={cn(
            'w-full bg-white rounded-2xl z-10  shadow-zen-web',
            containerClassNames || sizeVariant[size],
          )}
        >
          <div
            className={cn(
              'flex flex-row items-center bg-zen-light-blue rounded-t-xl p-3 border-[#CCCCCC]',
              { 'border-b': title },
            )}
          >
            <div className='flex flex-grow flex-shrink flex-col justify-center gap-y-2 my-1'>
              {title && (
                <p
                  className={cn(
                    'font-inter font-semibold text-reskin-primary-dark text-lg w-full text-center',
                    titleClassName,
                  )}
                >
                  {title}
                </p>
              )}
            </div>
            {!hideTitleCloseIcon && (
              <div className='w-6 h-6 flex items-center justify-center bg-zen-dark-10 rounded-full mx-2 my-3'>
                <FontAwesomeIcon
                  title='Close confirmation modal'
                  icon={faCircleXmark}
                  className={cn(
                    'text-[#B2BDC6] cursor-pointer text-2xl',
                    closeIconColor,
                  )}
                  onClick={onClose}
                />
              </div>
            )}
          </div>
          <div className='flex justify-center'>
            <div
              className={cn(
                'p-4',
                contentContainerClassNames || contentSizeVariant[size],
              )}
            >
              {subtitle && (
                <p className='font-inter text-base text-center text-gray-500'>
                  {subtitle}
                </p>
              )}
              {children}
              {!hideFooterButtons && (
                <div className='flex items-center justify-center gap-5 mb-4 mt-8'>
                  {!hideCancelButton && (
                    <Button
                      label={cancelButtonText}
                      variant={cancelButtonVariant}
                      leftIcon={cancelButtonLeftIcon}
                      onPress={() => onClose?.()}
                      buttonSize='xs'
                      fullWidth
                    />
                  )}
                  <Button
                    label={confirmButtonText}
                    variant={confirmButtonVariant}
                    onPress={() => onConfirm?.()}
                    buttonSize='xs'
                    leftIcon={confirmButtonLeftIcon}
                    isDisabled={isDisabled}
                    isSubmitting={isSubmitting}
                    fullWidth
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmationModal;
