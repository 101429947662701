import React, { useState } from 'react';
import ApplicationSubmittedSvg from '../../assets/img/application-submitted.svg';
import { cn } from '../../utils/classUtils';
import Confetti from '../Confetti';
import DefaultSplash from '../DefaultSplash';

type ApplicationSubmittedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ApplicationSubmittedModal: React.FC<ApplicationSubmittedModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  if (!isOpen) {
    return null;
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 w-full h-full-window !ml-0'>
      <div className='absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black bg-opacity-40'>
        <div className='flex items-center justify-center w-full h-full p-4 lg:p-0'>
          <Confetti />
          <div
            className={cn(
              'w-[537px] md:w-[715px] bg-white rounded-xl shadow-gemini-web p-4 z-10 pb-6 sm:pb-9',
            )}
          >
            <div className='mb-10 sm:mb-7'>
              <img
                src={ApplicationSubmittedSvg}
                alt='application-submitted'
                loading='lazy'
                onLoad={() => setIsImageLoaded(true)}
                className={cn('w-full object-contain', {
                  'w-0 h-0': !isImageLoaded,
                })}
              />
              {!isImageLoaded && (
                <div className='flex justify-center items-center h-[300px]'>
                  <DefaultSplash />
                </div>
              )}
            </div>
            <div className='w-full md:w-4/5 flex flex-col items-center justify-center m-auto'>
              <div className='text-center font-poppins-semibold text-reskin-primary-dark text-2xl lg:text-[42px] leading-7 mb-2 md:mb-5'>
                Application received!
              </div>
              <div className='text-center font-inter-regular sm:text-sm md:text-lg text-reskin-primary-dark'>
                You’re one step closer! Complete your tasks to keep{' '}
                <br className='hidden md:block' /> your application moving
                forward.
              </div>
              <div className='w-full md:w-3/5 mt-9 sm:mt-6'>
                <button
                  onClick={onClose}
                  className='w-full py-3.5 bg-primary-mint rounded-lg text-reskin-primary-dark font-poppins-medium text-base'
                >
                  Okay, got it.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSubmittedModal;
