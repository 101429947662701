import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FileResponse } from '../../openapi/dropbox';
import {
  addFileReferencesToChecklist,
  uploadToDropbox,
} from '../../slices/DropboxSlice';
import { fetchLoanChecklistDetail } from '../../slices/LoanSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { getDropboxFilesFromFileReferences } from '../../utils/DropboxUtils';
import ControlledDraggableDocumentUploadInput from '../inputs/ControlledDraggableDocumentUploadInput';
import ChecklistFile from './ChecklistFile';

type ChecklistDocumentFormProps = {
  files: File[] | undefined;
};

type ChecklistFilesProps = {
  checklistId: string;
};

const ChecklistFiles: React.FC<ChecklistFilesProps> = ({ checklistId }) => {
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { authUserId, borrowerDetail, checklistDetail, dropboxByBorrowerId } =
    useAppSelector((state) => ({
      authUserId: state.auth.authUserDetail?.id,
      borrowerDetail: state.loan.borrowerDetail,
      checklistDetail: state.loan.checklistDetail,
      dropboxByBorrowerId: state.dropbox.dropboxByBorrowerId,
    }));

  const { control, watch, setValue } = useForm<ChecklistDocumentFormProps>({
    defaultValues: { files: undefined },
  });

  const watchFiles = watch('files');
  const borrowerId = borrowerDetail?.id!;
  const dropbox = dropboxByBorrowerId[borrowerId];
  const fileReferences = checklistDetail?.fileReferences?.references || [];

  const uploadFiles = useCallback(
    async (files: File[]) => {
      setIsUploading(true);
      try {
        const uploadedFiles = await Promise.all(
          files.map(async (file) => {
            const data = await dispatch(
              uploadToDropbox(authUserId!, borrowerDetail!, file),
            );

            return data;
          }),
        ).then((files) => files.filter((file): file is FileResponse => !!file));

        await dispatch(
          addFileReferencesToChecklist(checklistId, uploadedFiles),
        );

        if (uploadedFiles.some((file) => file?.id)) {
          await dispatch(fetchLoanChecklistDetail(borrowerId, checklistId));
          setValue('files', undefined);
        }
      } finally {
        setIsUploading(false);
      }
    },
    [authUserId, borrowerDetail, borrowerId, checklistId, dispatch, setValue],
  );

  const files = getDropboxFilesFromFileReferences(
    fileReferences || [],
    dropbox!,
  );

  const isFileAvailable = files.length > 0;

  useEffect(() => {
    if (watchFiles?.length) {
      uploadFiles(watchFiles);
    }
  }, [uploadFiles, watchFiles]);

  return (
    <div>
      <ControlledDraggableDocumentUploadInput
        name='files'
        control={control}
        isUploading={isUploading}
        isMultiple
      />
      {isFileAvailable && (
        <div>
          {files.map((file) => {
            return <ChecklistFile key={file.id} file={file} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ChecklistFiles;
