import { faCommentLines } from '@fortawesome/pro-light-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import RealLogo from '../../assets/img/logo-white.svg';
import useDeviceType from '../../hooks/useDeviceType';
import ChatModal from '../ChatModal';

type ConfigDrivenContainerLayoutChatProps = {
  isChatOpen: boolean;
  setIsChatOpen: (isChatOpen: boolean) => void;
  isStepProgress?: boolean;
};

const ConfigDrivenContainerLayoutChat: React.FC<
  ConfigDrivenContainerLayoutChatProps
> = ({ isChatOpen, setIsChatOpen, isStepProgress }) => {
  const { isDesktop } = useDeviceType();

  if (!isDesktop) {
    return (
      <div className='relative'>
        {isChatOpen && (
          <div className='fixed right-0 left-0 top-0 w-[100vw] bg-black flex items-center justify-between lg:h-24 h-20 px-5 pb-2 z-50'>
            <div>
              <img src={RealLogo} alt='logo' width={100} height={35} />
            </div>
            <div>
              <FontAwesomeIcon
                onClick={() => setIsChatOpen(!isChatOpen)}
                icon={faXmark}
                fontSize={20}
                className='text-gray-3 cursor-pointer'
              />
            </div>
          </div>
        )}
        <div
          className={classNames(
            isChatOpen ? 'fixed left-0 right-0 bottom-0 top-20' : 'hidden',
          )}
        >
          <ChatModal />
        </div>
        {!isChatOpen && (
          <div
            className={classNames([
              'fixed left-5',
              isStepProgress ? 'bottom-32' : 'bottom-5',
            ])}
          >
            <button
              className='bg-primary-navy w-12 h-12 rounded-full items-center justify-center'
              onClick={() => setIsChatOpen(!isChatOpen)}
            >
              <FontAwesomeIcon
                icon={isChatOpen ? faXmark : faCommentLines}
                className='text-white text-2xl leading-9'
              />
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames('absolute z-50 right-10 bottom-12')}>
      {isChatOpen && (
        <div className='absolute right-0 bottom-16'>
          <ChatModal />
        </div>
      )}
      <button
        className='bg-primary-navy border-4 border-white w-16 h-16 rounded-full items-center justify-center'
        onClick={() => setIsChatOpen(!isChatOpen)}
      >
        <FontAwesomeIcon
          icon={isChatOpen ? faXmark : faCommentLines}
          className='text-white text-2xl leading-9'
        />
      </button>
    </div>
  );
};

export default ConfigDrivenContainerLayoutChat;
