import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { get, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RealLogo from '../assets/img/logo.svg';
import AnalyticsEventOnLoad from '../components/Analytics/AnalyticsEventOnLoad.tsx';
import AuthPageImage from '../components/AuthPageImage';
import Button from '../components/Button';
import PageLayout from '../components/PageLayout';
import ControlledTextInput from '../components/inputs/ControlledTextInput';
import {
  AuthControllerApi,
  JwtAuthenticationResponse,
  JwtAuthenticationResponseMfaTypeEnum,
  MfaControllerApi,
} from '../openapi/keymaker';
import ErrorService from '../services/ErrorService.ts';
import { fetchAuthUserDetail } from '../slices/AuthSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice.ts';
import { useAppDispatch } from '../slices/store';
import { AnalyticsEventEnum, ErrorCode } from '../types.ts';
import { setAuthCookie } from '../utils/AuthUtils';
import { getErrorMessage } from '../utils/ErrorUtils.ts';
import { getKeymakerConfiguration } from '../utils/OpenapiConfigurationUtils';
import { EMAIL_VALIDATIONS } from '../utils/Validations';

interface SignInRouteProps {}

interface FormData {
  usernameOrEmail: string;
  password: string;
  submit: string;
}

const SignInRoute: React.FC<SignInRouteProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { leadId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [hidePassword, setHidePassword] = useState(true);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({ mode: 'onBlur' });

  const onMFATypeRedirect = async (data: JwtAuthenticationResponse) => {
    if (data.mfaType === JwtAuthenticationResponseMfaTypeEnum.Sms) {
      try {
        await new MfaControllerApi(await getKeymakerConfiguration()).sendMfaSms(
          data.phoneNumber!,
        );
        navigate(`/2fa?phoneNumber=${data.phoneNumber}`);
      } catch (e: any) {
        ErrorService.notify('Failed to send SMS', e);
        dispatch(showErrorToast('Failed to send SMS'));
      }
    } else {
      navigate('/2fa');
    }
  };

  const onSubmit = async (values: FormData) => {
    try {
      const api = new AuthControllerApi(await getKeymakerConfiguration());
      const { data } = await api.authenticateUser(values);
      setAuthCookie(data?.accessToken!);
      if (data?.mfaType) {
        await onMFATypeRedirect(data);
      } else {
        const { isLoginSuccess } = await dispatch(
          fetchAuthUserDetail(leadId as string),
        );

        if (!isLoginSuccess) {
          setError('submit', {
            message:
              'Looks like the account you are trying to login as is an agent account. Please login to the ReZEN app instead.',
          });
        } else {
          navigate('/');
        }
      }
    } catch (e: any) {
      setError('submit', {
        message:
          e.response?.data?.errorMessage ||
          getErrorMessage(ErrorService.getErrorCode(e), 'login'),
      });
      const errorCode = ErrorService.getErrorCode(e);
      if (errorCode !== ErrorCode.UNAUTHORIZED) {
        ErrorService.notify('unable to login', e, { meta: { ...values } });
      }
    }
  };

  return (
    <PageLayout>
      <div className='order-2 md:order-1 md:col-span-5 md:h-screen grid md:justify-between max-w-lg mx-auto p-4'>
        <div className='md:row-span-1 md:my-auto md:block hidden'>
          <img src={RealLogo} alt='placeholder' width={100} height={35} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='md:row-span-4 md:my-auto mx-5 md:mx-0'
        >
          <div className='pb-8'>
            <p className='font-poppins-semibold md:text-5xl text-4xl text-black text-left'>
              Sign in to your account
            </p>
          </div>
          {get(errors, 'submit') && (
            <div className='mb-5 flex bg-primary-coral rounded-lg p-2'>
              <p className='font-inter-medium text-white text-base'>
                {get(errors, 'submit').message}
              </p>
            </div>
          )}
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'usernameOrEmail'>
              name='usernameOrEmail'
              control={control}
              label='Email address'
              placeholder='Email address'
              startAdornment={
                <FontAwesomeIcon
                  icon={faEnvelope}
                  fontSize={20}
                  className='text-primary-skyblue'
                />
              }
              shouldUnregister={false}
              rules={{
                required: 'Please enter email address',
                ...EMAIL_VALIDATIONS,
              }}
            />
          </div>
          <div className='pb-5'>
            <ControlledTextInput<FormData, 'password'>
              placeholder='Password'
              name='password'
              label='Password'
              control={control}
              shouldUnregister={false}
              rules={{ required: 'Please enter password' }}
              type={hidePassword ? 'password' : 'text'}
              defaultValue=''
              startAdornment={
                <FontAwesomeIcon
                  icon={faLock}
                  fontSize={20}
                  className='text-primary-skyblue'
                />
              }
              endAdornment={
                <FontAwesomeIcon
                  onClick={() => setHidePassword(!hidePassword)}
                  icon={hidePassword ? faEyeSlash : faEye}
                  fontSize={20}
                  className='text-primary-gray'
                />
              }
              isPassword
            />
          </div>
          <div className='justify-end flex pb-5'>
            <Link to='/forgot-password'>
              <span className='underline'>Forgot Password</span>
            </Link>
          </div>
          <div className='flex w-full'>
            <Button
              label='Continue'
              type='submit'
              fullWidth={isMobile}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  fontSize={16}
                  className='text-white'
                  data-test='loading-icon'
                />
              }
              gradientVariant='mintyfresh'
              isSubmitting={isSubmitting}
              onPress={() => {
                if (get(errors, 'submit')) {
                  clearErrors('submit');
                }
              }}
            />
          </div>
        </form>
        <div className='md:row-span-1 md:my-auto mt-5 flex justify-center md:flex-none md:justify-start'>
          <p>
            Don&apos;t have an account?
            <Link to={leadId ? `/signup?leadId=${leadId}` : '/signup'}>
              <span className='text-primary-blue underline pl-1'>Sign Up</span>
            </Link>
          </p>
        </div>
      </div>
      <AuthPageImage />
      <AnalyticsEventOnLoad
        eventName={AnalyticsEventEnum.LOGIN_SCREEN_VIEWED}
      />
    </PageLayout>
  );
};

export default SignInRoute;
