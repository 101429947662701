import React from 'react';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { EnumMap } from '../types';
import { cn } from '../utils/classUtils';

export type IconCircleButtonVariant = 'default' | 'danger';

type IconCircleButtonType = {
  icon: React.ReactElement;
  onClick(): void;
  variant?: IconCircleButtonVariant;
  toolTipText?: string;
  toolTipStyles?: string;
  toolTipConfig?: Config;
};

const IconCircleButton: React.FC<IconCircleButtonType> = ({
  icon,
  onClick,
  variant = 'default',
  toolTipText,
  toolTipConfig = {
    trigger: 'hover',
    placement: 'top',
  },
  toolTipStyles,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(toolTipConfig);

  const variantToTextStyleMap: EnumMap<IconCircleButtonVariant, string> = {
    default: 'text-reskin-primary-dark',
    danger: 'text-[#F84C6C] hover:text-white',
  };

  const variantToBackgroundStyleMap: EnumMap<IconCircleButtonVariant, string> =
    {
      default: 'hover:bg-silver',
      danger: 'hover:bg-[#F84C6C]',
    };

  return (
    <button
      ref={setTriggerRef}
      onClick={onClick}
      title={toolTipText}
      className={cn(
        'w-8 h-8 rounded-full bg-whitesmoke',
        variantToBackgroundStyleMap[variant],
        variantToTextStyleMap[variant],
      )}
    >
      {icon}
      {visible && toolTipText && (
        <div ref={setTooltipRef} {...getTooltipProps()}>
          <div {...getArrowProps()} />
          <div
            className={cn(
              'px-2.5 py-1 border rounded-md text-sm bg-white text-reskin-primary-dark border-reskin-primary-dark',
              toolTipStyles,
            )}
          >
            <div>{toolTipText}</div>
          </div>
        </div>
      )}
    </button>
  );
};

export default IconCircleButton;
