import { DateTime } from 'luxon';
import { ReactDatePickerProps } from 'react-datepicker';
import { UseControllerProps } from 'react-hook-form';
import { FieldObject } from '../openapi/atlantis';
import {
  GooglePlaceLocationType,
  getAddressFromAddressComponent,
} from './GoogleMapUtils';
import { EMAIL_REGEX } from './RegexUtils';

export type Validation = UseControllerProps['rules'];

export type FieldValidationType = {
  type: string;
  value: string;
};

export const EMAIL_VALIDATIONS: Validation = {
  pattern: {
    value: EMAIL_REGEX,
    message: 'Please enter valid email address',
  },
};

export const USERNAME_VALIDATIONS: Validation = {
  minLength: {
    value: 2,
    message: 'Username must be at least 2 characters',
  },
  maxLength: {
    value: 15,
    message: 'Username must be fewer than 15 characters',
  },
};

export const SYMBOLS_REGEX = /[!@#$%*()"';:,<>|\\\/=\-_^&]/;

export const PASSWORD_VALIDATIONS: Validation = {
  minLength: {
    value: 8,
    message: 'Password must have a minimum of 8 characters',
  },
  maxLength: {
    value: 24,
    message: 'Password must have a maximum of 24 characters',
  },
  required: 'Password is required',
  validate: (value: string) => {
    if (/[\s]/.test(value)) {
      return 'Password cannot contain any spaces';
    }

    const errors = [];
    if (!/[a-z]/.test(value)) {
      errors.push('1 lower-case character');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('1 upper-case character');
    }

    if (!/[0-9]/.test(value)) {
      errors.push('1 digit');
    }

    if (!SYMBOLS_REGEX.test(value)) {
      errors.push('1 symbol');
    }

    if (errors.length === 0) {
      return undefined;
    }

    return `Password must contain at least: ${errors.join(', ')}`;
  },
};

export const getInputValidations = (field: FieldObject): Validation => {
  let validations: Validation = {};

  if (field.required) {
    validations.required = 'Required';
  }

  for (let validation of field.validations || []) {
    if (validation.type === 'regex') {
      validations.pattern = {
        //@ts-ignore
        value: new RegExp(validation.value),
        //@ts-ignore
        message: validation.message,
      };
    }
  }

  return validations;
};

export const getDatePickerConfig = (field: FieldObject): any => {
  let config: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur' | 'readOnly'
  > = {};

  field.validations?.forEach((validation: any) => {
    //@ts-ignore
    const date = DateTime.fromISO(validation.effectiveValue);

    if (date.isValid) {
      if (validation.type === 'minDate') {
        config.minDate = date.toJSDate();
      }

      if (validation.type === 'maxDate') {
        config.maxDate = date.toJSDate();
      }
    }
  });

  return config;
};

export const getGoogleAutoCompleteInputValidations = (
  field: FieldObject,
): Validation => {
  return {
    validate: (value: GooglePlaceLocationType | undefined) => {
      // Check if the field is required and the value is empty
      if (field.required && !value?.formatted_address) {
        return 'Required';
      }

      if (value?.place_id) {
        // Get the address object from the address components
        const address = getAddressFromAddressComponent(
          value?.address_components!,
        );

        // Get the list of address component validations from the field validations
        const requiredAddressFieldValidation = [
          ...((field?.validations || []) as FieldValidationType[]),
        ].filter((validation) => validation.type === 'addressComponent');

        // Check if any required address component is missing or invalid
        const areFieldValidationsInvalid = requiredAddressFieldValidation
          .filter((v) => address.hasOwnProperty(v.value))
          .some((v) => !address[v.value as keyof typeof address]);

        if (areFieldValidationsInvalid) {
          return 'Please enter a valid address';
        }
      }

      // Return undefined if all validations pass
      return undefined;
    },
  };
};
