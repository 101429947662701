import {
  faCircleCheck,
  faDollarSign,
  faFileCheck,
  faFileImport,
  faHourglassHalf,
  faListCheck,
  faSignature,
  faStamp,
  faTimer,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BorrowerDtoStatusEnum } from '../../../openapi/atlantis';
import { EnumMap } from '../../../types';
import { capitalizeEnum, safeEnumMapGet } from '../../../utils/EnumHelper';
import { cn } from '../../../utils/classUtils';
import { LoanStatusProps } from '../LoanStatus';

export const variantToTextClassNameMap: EnumMap<BorrowerDtoStatusEnum, string> =
  {
    [BorrowerDtoStatusEnum.AccountCreated]: 'text-reskin-yellow-900',
    [BorrowerDtoStatusEnum.ApplicationInProgress]: 'text-reskin-orange-600',
    [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'text-reskin-purple-600',
    [BorrowerDtoStatusEnum.LoanPreApproved]: 'text-reskin-green-900',
    [BorrowerDtoStatusEnum.SubmittedToLender]: 'text-primary-navy',
    [BorrowerDtoStatusEnum.Underwriting]: 'text-yellow-900',
    [BorrowerDtoStatusEnum.ConditionalApproval]: 'text-reskin-orange-600',
    [BorrowerDtoStatusEnum.ClearToClose]: 'text-reskin-purple-600',
    [BorrowerDtoStatusEnum.Closing]: 'text-reskin-green-900',
    [BorrowerDtoStatusEnum.Funding]: 'text-reskin-green-900',
    [BorrowerDtoStatusEnum.Terminated]: 'text-reskin-red-600',
  };

export const variantToBgClassNameMap: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'bg-reskin-yellow-100',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'bg-reskin-orange-100',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'bg-reskin-purple-100',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'bg-reskin-green-100',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'bg-zen-light-gray-1',
  [BorrowerDtoStatusEnum.Underwriting]: 'bg-yellow-100',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'bg-reskin-orange-100',
  [BorrowerDtoStatusEnum.ClearToClose]: 'bg-reskin-purple-300',
  [BorrowerDtoStatusEnum.Closing]: 'bg-reskin-green-300',
  [BorrowerDtoStatusEnum.Funding]: 'bg-reskin-green-300',
  [BorrowerDtoStatusEnum.Terminated]: 'bg-reskin-red-100',
};

const LoanStatusGemini: React.FC<LoanStatusProps> = ({ status }) => {
  const bgStyles = safeEnumMapGet(
    variantToBgClassNameMap,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const textStyles = safeEnumMapGet(
    variantToTextClassNameMap,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const LoanStatusIconMap: EnumMap<BorrowerDtoStatusEnum, React.ReactElement> =
    {
      [BorrowerDtoStatusEnum.AccountCreated]: (
        <FontAwesomeIcon icon={faFileImport} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.ApplicationInProgress]: (
        <FontAwesomeIcon icon={faHourglassHalf} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.ApplicationSubmitted]: (
        <FontAwesomeIcon icon={faFileCheck} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.SubmittedToLender]: (
        <FontAwesomeIcon icon={faFileImport} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.Underwriting]: (
        <FontAwesomeIcon icon={faListCheck} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.LoanPreApproved]: (
        <FontAwesomeIcon icon={faCircleCheck} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.ConditionalApproval]: (
        <FontAwesomeIcon icon={faStamp} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.ClearToClose]: (
        <FontAwesomeIcon icon={faTimer} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.Closing]: (
        <FontAwesomeIcon icon={faSignature} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.Funding]: (
        <FontAwesomeIcon icon={faDollarSign} className={textStyles} />
      ),
      [BorrowerDtoStatusEnum.Terminated]: (
        <FontAwesomeIcon icon={faXmark} className={textStyles} />
      ),
    };

  const icon = safeEnumMapGet(
    LoanStatusIconMap,
    status,
    LoanStatusIconMap[BorrowerDtoStatusEnum.AccountCreated],
  );

  return (
    <div
      className={cn(
        'px-2.5 py-1.5 w-max flex justify-center items-center rounded-full text-center gap-x-2',
        bgStyles,
      )}
    >
      {icon}
      <p
        className={cn('font-inter font-medium text-xs lg:text-sm', textStyles)}
      >
        {capitalizeEnum(status)}
      </p>
    </div>
  );
};

export default LoanStatusGemini;
