import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { FileResponse } from '../../openapi/dropbox';
import { useAppSelector } from '../../slices/store';
import DocumentCard from './DocumentCard';

type DocumentsListProps = {
  files: FileResponse[];
};

const DocumentsList: React.FC<DocumentsListProps> = ({ files = [] }) => {
  const { preApprovalFileId } = useAppSelector((state) => ({
    preApprovalFileId: state.loan.borrowerDetail?.preApprovalFileId,
  }));

  const allFiles = useMemo(() => {
    const modifiedFiles = [...files];

    if (preApprovalFileId) {
      modifiedFiles.unshift({
        id: preApprovalFileId,
        filename: 'Pre-Approval Letter',
      });
    }

    return modifiedFiles;
  }, [files, preApprovalFileId]);

  if (allFiles.length === 0) {
    return (
      <div className='w-full h-[60vh] flex flex-col justify-center items-center'>
        <div className='mb-6'>
          <FontAwesomeIcon
            className='text-reskin-silver-400'
            icon={faFolderOpen}
            fontSize={54}
          />
        </div>
        <div className='font-inter-light text-base text-reskin-primary-dark'>
          No Documents
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='mt-12 mb-4'>Documents Uploaded ({allFiles.length})</div>
      {allFiles.map((file) => (
        <div key={file.id} className='mb-4'>
          <DocumentCard
            file={file}
            hideDelete={file.id === preApprovalFileId}
          />
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;
