import React, { useEffect, useState } from 'react';
import ConfettiGif from '../assets/img/confetti.gif';

interface ConfettiProps {}

const Confetti: React.FC<ConfettiProps> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='h-screen w-screen absolute z-50 left-0 top-0 bottom-0'>
      <img src={ConfettiGif} className='m-auto' alt='confetti' />
    </div>
  );
};

export default Confetti;
