import { BorrowerDto, BorrowerDtoStatusEnum } from '../openapi/atlantis';

export const isLoanSubmitted = (borrower?: BorrowerDto) => {
  if (!borrower?.status) {
    return false;
  }

  return [
    BorrowerDtoStatusEnum.AccountCreated,
    BorrowerDtoStatusEnum.ApplicationInProgress,
  ].includes(borrower.status as any);
};
