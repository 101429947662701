import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DefaultSplash from '../components/DefaultSplash';
import DocumentsList from '../components/Documents/DocumentsList';
import ConfigDrivenChecklistContainerLayout from '../components/Layout/ConfigDrivenChecklistContainerLayout';
import ControlledDraggableDocumentUploadInput from '../components/inputs/ControlledDraggableDocumentUploadInput';
import { uploadToDropbox } from '../slices/DropboxSlice';
import { fetchLoanChecklists } from '../slices/LoanSlice';
import { useAppDispatch, useAppSelector } from '../slices/store';
import { isLoanSubmitted } from '../utils/LoanUtils';

type FileCabinetFormProps = {
  files: File[] | undefined;
};

const PreApprovalFormFileCabinetRoute = () => {
  const { borrowerId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { authUserId, borrowerDetail, dropboxByBorrowerId } = useAppSelector(
    (state) => ({
      authUserId: state.auth.authUserDetail?.id,
      borrowerDetail: state.loan.borrowerDetail,
      dropboxByBorrowerId: state.dropbox.dropboxByBorrowerId,
    }),
  );

  const { control, watch, setValue } = useForm<FileCabinetFormProps>({
    defaultValues: { files: undefined },
  });

  const watchFiles = watch('files');
  const borrowerDropboxFiles = dropboxByBorrowerId[borrowerId!]?.files || [];

  const fetchChecklists = useCallback(async () => {
    if (borrowerId) {
      try {
        await dispatch(fetchLoanChecklists(borrowerId, true));
      } finally {
        setIsLoading(false);
      }
    }
  }, [borrowerId, dispatch]);

  useEffect(() => {
    fetchChecklists();
  }, [fetchChecklists]);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      setIsUploading(true);

      try {
        await Promise.all(
          files.map(async (file) => {
            await dispatch(uploadToDropbox(authUserId!, borrowerDetail!, file));
          }),
        );
        setValue('files', undefined);
      } finally {
        setIsUploading(false);
      }
    },
    [authUserId, borrowerDetail, dispatch, setValue],
  );

  useEffect(() => {
    if (watchFiles?.length) {
      uploadFiles(watchFiles);
    }
  }, [watchFiles, uploadFiles]);

  return (
    <ConfigDrivenChecklistContainerLayout
      hideMyTasks={isLoanSubmitted(borrowerDetail)}
    >
      {isLoading ? (
        <div className='h-[80vh] w-full'>
          <DefaultSplash />
        </div>
      ) : (
        <div>
          <ControlledDraggableDocumentUploadInput
            name='files'
            control={control}
            isUploading={isUploading}
            isMultiple
          />
          <DocumentsList files={borrowerDropboxFiles} />
        </div>
      )}
    </ConfigDrivenChecklistContainerLayout>
  );
};

export default PreApprovalFormFileCabinetRoute;
