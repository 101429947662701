import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigDrivenContainer from '../components/ConfigDriven/ConfigDrivenContainer.tsx';
import {
  AcknowledgeSectionStepRequest,
  AnswerApplicationQuestionRequest,
  ApplicationApi,
  ApplicationStep,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService.ts';
import {
  fetchApplicationProgress,
  saveCurrentQuestion,
} from '../slices/ApplicationSlice';
import { showSuccessToast } from '../slices/ToastNotificationSlice.ts';
import { useAppDispatch } from '../slices/store';
import { NextActionType } from '../utils/ConfigDrivenFormUtils.tsx';
import Logger from '../utils/Logger';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { isSectionOutro } from '../utils/TypeUtils.tsx';

interface PreApprovalFormRouteProps {}

const PreApprovalFormRoute: React.FC<PreApprovalFormRouteProps> = () => {
  const { borrowerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onStart = async (stepId: string) => {
    const request: AcknowledgeSectionStepRequest = {
      stepId: stepId,
    };
    try {
      await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).acknowledgeSection(borrowerId!, request);
    } catch (e: any) {
      Logger.error('Failed to acknowledge section:', e);
      ErrorService.notify('Failed to acknowledge section', e);
    }
  };

  const onNext = async (
    actionType: NextActionType,
  ): Promise<ApplicationStep> => {
    try {
      const { data } = await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).getNextApplicationStep(borrowerId!);

      if (isSectionOutro(data)) {
        if (actionType === NextActionType.INITIATE) {
          navigate('tasks');
        }

        if (actionType === NextActionType.BUTTON_ACTION) {
          navigate(`/loans?borrowerId=${borrowerId}`);
          dispatch(showSuccessToast('Application submitted successfully'));
        }
      } else {
        await dispatch(saveCurrentQuestion(data));
        await dispatch(fetchApplicationProgress(borrowerId!));
      }
      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch next question:', e);
      ErrorService.notify('Failed to fetch next question', e);
      throw e;
    }
  };

  const onNavigateToQuestion = async (
    questionId: string,
  ): Promise<ApplicationStep> => {
    try {
      const { data } = await new ApplicationApi(
        await getAtlantisConfiguration(),
      ).getAnsweredApplicationStep(borrowerId!, questionId);

      await dispatch(saveCurrentQuestion(data));

      await dispatch(fetchApplicationProgress(borrowerId!));

      return data;
    } catch (e: any) {
      Logger.error('Failed to fetch question by id:', e);
      ErrorService.notify('Failed to fetch question by id', e);
      throw e;
    }
  };

  const onSubmit = async (formData: AnswerApplicationQuestionRequest) => {
    try {
      await new ApplicationApi(await getAtlantisConfiguration()).answerQuestion(
        borrowerId!,
        formData,
      );
    } catch (e: any) {
      Logger.error('Failed to submit question answer:', e);
      ErrorService.notify('Failed to submit question answer', e);
    }
  };

  return (
    <ConfigDrivenContainer
      onStart={onStart}
      onNext={onNext}
      onSubmit={onSubmit}
      onNavigateToQuestion={onNavigateToQuestion}
    />
  );
};

export default PreApprovalFormRoute;
