import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Select, { MultiValue, SingleValue } from 'react-select';
import { useState } from 'react';
import { cn } from '../../utils/classUtils';
import FieldErrorMessage from './FieldErrorMessage';
import ControlledInputLabelComponent from './ControlledInputLabelComponent';

export interface CustomSelectOptions {
  LabelComponent?: React.ReactNode;
  value: string;
  label: string | React.ReactElement;
  isDisabled?: boolean;
}

interface ControlledReactSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  options: Array<CustomSelectOptions>;
  placeholderLabel?: string;
  disabled?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  readOnly?: boolean;
  customClassName?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  isRequired?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isMulti?: boolean;
}

const ControlledReactSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  placeholder = 'Select',
  options,
  shouldUnregister = false,
  placeholderLabel,
  disabled = false,
  readOnly,
  startAdornment,
  endAdornment,
  customClassName = '',
  menuPlacement = 'auto',
  closeMenuOnSelect = false,
  isSearchable = true,
  isClearable = true,
  isRequired,
  isMulti = false,
  ...controllerProps
}: ControlledReactSelectInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    field: { value, onChange, name, onBlur, ref },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    onBlur();
    setIsFocused(false);
  };

  const handleSelectChange = (
    value: MultiValue<CustomSelectOptions> | SingleValue<CustomSelectOptions>,
  ) => {
    onChange(value);
    setIsFocused(false);
  };

  const isShowLabel = !!label && (isFocused || !!value);

  return (
    <div>
      {label && isShowLabel && <ControlledInputLabelComponent label={label!} />}

      <div className='flex flex-row items-center flex-grow'>
        {startAdornment && <div>{startAdornment}</div>}

        <div className='w-full'>
          <Select<CustomSelectOptions, boolean>
            isSearchable={isSearchable}
            options={options}
            placeholder={placeholder}
            name={name}
            isMulti={isMulti}
            value={value}
            isDisabled={disabled || readOnly}
            defaultValue={value}
            onChange={handleSelectChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            closeMenuOnSelect={closeMenuOnSelect}
            isClearable={isClearable}
            ref={ref}
            className='react-select-container font-inter font-normal'
            classNames={{
              control: (state) =>
                cn([
                  '!bg-transparent !rounded-lg py-1 !cursor-pointer',
                  state.isFocused
                    ? '!border-black !shadow-none'
                    : '!border-silver',
                ]),
              menu: () => '!border !shadow-none',
              option: (state) =>
                cn(
                  '!text-base !text-primary-dark !cursor-pointer hover:!bg-blue-100',
                  [
                    state.isFocused && '!bg-white',
                    state.isSelected && '!bg-light-blue-100',
                  ],
                ),
            }}
            classNamePrefix='react-select-noborder'
            inputId={name}
            menuPlacement={menuPlacement}
          />
        </div>
      </div>
      {error && <FieldErrorMessage message={error.message} />}
    </div>
  );
};

export default ControlledReactSelectInput;
