import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LegacyRef, useRef } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { MAX_FILE_SIZE } from '../../constants/FileConstants';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch } from '../../slices/store';
import {
  bytesToSize,
  isDocsSizeInvalid,
  validDocs,
} from '../../utils/FileUtils';
import DefaultLoader from '../DefaultLoader';
import FieldErrorMessage from './FieldErrorMessage';

type ControlledDraggableDocumentUploadInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  onChangeSpy?: (value: File[]) => void;
  isMultiple?: boolean;
  isUploading?: boolean;
  maxUploadSize?: number;
};

const ControlledDraggableDocumentUploadInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onChangeSpy,
  isMultiple = false,
  isUploading,
  maxUploadSize = MAX_FILE_SIZE,
  ...props
}: ControlledDraggableDocumentUploadInputProps<TFieldValues, TName>) => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLDivElement>(null);
  const {
    field: { onChange },
    fieldState,
  } = useController({
    name,
    control,
  });

  return (
    <div>
      <div
        className='flex flex-col bg-[#F6F8FC] hover:bg-[#F2F2F4] p-6 border-2 rounded-[10px] border-[#F2F2F4] items-center justify-center cursor-pointer'
        onClick={() => {
          if (!isUploading) {
            fileInputRef.current?.click();
          }
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={async (e) => {
          if (!isUploading) {
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files);
            const validFiles = validDocs(files, maxUploadSize);

            if (validFiles?.length) {
              if (isMultiple) {
                onChange(validFiles);
                if (onChangeSpy) {
                  onChangeSpy(validFiles);
                }
              } else {
                onChange(validFiles?.slice(0, 1));
                if (onChangeSpy) {
                  onChangeSpy(validFiles?.slice(0, 1));
                }
              }
            }

            if (isDocsSizeInvalid(files, maxUploadSize)) {
              dispatch(
                showErrorToast(
                  `File size exceeds maximum limit of ${bytesToSize(
                    maxUploadSize,
                  )}.`,
                ),
              );
            }
          }
        }}
      >
        <input
          {...props}
          id={name}
          data-testId={name}
          ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
          className='hidden w-full h-full'
          type='file'
          accept='.xlsx, .xls, .doc, .docx, .pdf, image/*'
          onChange={(e) => {
            const files = Array.from(e.target.files!);
            const validFiles = validDocs(files, maxUploadSize);

            if (validFiles?.length) {
              onChange(validFiles);

              if (onChangeSpy) {
                onChangeSpy(validFiles);
              }
            }

            if (isDocsSizeInvalid(files, maxUploadSize)) {
              dispatch(
                showErrorToast(
                  `File size exceeds maximum limit of ${bytesToSize(
                    maxUploadSize,
                  )}.`,
                ),
              );
            }
          }}
          onClick={(e) => {
            // This is to reset the file input value when the user tries to upload the same file again
            e.currentTarget.value = '';
          }}
          multiple={isMultiple}
          disabled={isUploading}
        />
        {isUploading ? (
          <div>
            <DefaultLoader />
          </div>
        ) : (
          <div className='text-center'>
            <FontAwesomeIcon
              icon={faArrowUpFromBracket}
              fontSize={18}
              className='text-reskin-primary-dark'
            />
            <p className='font-inter-medium text-base leading-6 text-reskin-primary-dark mt-1.5'>
              <span className='hidden md:inline-block'>
                Drag & Drop or&nbsp;
              </span>
              <span className='text-reskin-primary-blue underline cursor-pointer'>
                Choose File
              </span>{' '}
              to upload
            </p>
            <p className='font-inter-light text-base leading-6 text-reskin-primary-dark mt-0.5'>
              Max file size: {bytesToSize(maxUploadSize)}.
            </p>
          </div>
        )}
      </div>
      <FieldErrorMessage message={fieldState.error?.message} />
    </div>
  );
};

export default ControlledDraggableDocumentUploadInput;
