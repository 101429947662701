import qs from 'qs';
import config from '../config';
import { SignatureWidgetModeEnum } from '../types';

interface WidgetUrlOptions {
  showDownload?: boolean;
  showMobileTooltip?: boolean;
  showWebHelperButtons?: boolean;
  browserMode?: boolean;
}

export const getWidgetUrl = (
  mode: SignatureWidgetModeEnum,
  token: string,
  options: WidgetUrlOptions = {},
) => {
  const { showDownload, showMobileTooltip, showWebHelperButtons, browserMode } =
    options;
  const params: Partial<Record<string, any>> = {
    ...(showDownload && { showDownload: 'true' }),
    ...(showMobileTooltip && { showMobileTooltip: 'true' }),
    ...(showWebHelperButtons && { showWebHelperButtons: 'true' }),
    ...(browserMode && { browserMode: 'true' }),
  };
  const queryString = qs.stringify(params);
  const url = `${config.realSignatureBaseUrl}/widget/${mode}/${token}?${queryString}`;
  return url;
};
