/* tslint:disable */
/* eslint-disable */
/**
 * Signature API
 * Signature Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddDocumentToFolderRequest
 */
export interface AddDocumentToFolderRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddDocumentToFolderRequest
     */
    'documentTemplateIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AddRecipientsRequest
 */
export interface AddRecipientsRequest {
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof AddRecipientsRequest
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
}
/**
 * 
 * @export
 * @interface AuthTokenResponse
 */
export interface AuthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenResponse
     */
    'signeeToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenResponse
     */
    'documentId': string;
}
/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface CreateAgreementRequest
 */
export interface CreateAgreementRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {SenderDto}
     * @memberof CreateAgreementRequest
     */
    'sender': SenderDto;
    /**
     * 
     * @type {Array<DocumentSigneeInfoDto>}
     * @memberof CreateAgreementRequest
     */
    'signees': Array<DocumentSigneeInfoDto>;
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof CreateAgreementRequest
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof CreateAgreementRequest
     */
    'documentFieldValues': Array<DocumentFieldValueDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementRequest
     */
    'renderMode'?: CreateAgreementRequestRenderModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAgreementRequest
     */
    'sendEmail'?: boolean;
    /**
     * 
     * @type {CreatePacketRequestZoneId}
     * @memberof CreateAgreementRequest
     */
    'zoneId'?: CreatePacketRequestZoneId;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAgreementRequestRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface CreateAgreementResponse
 */
export interface CreateAgreementResponse {
    /**
     * 
     * @type {Array<SigneeAuthTokenDto>}
     * @memberof CreateAgreementResponse
     */
    'signeesAuthTokens': Array<SigneeAuthTokenDto>;
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementResponse
     */
    'documentId': string;
}
/**
 * 
 * @export
 * @interface CreateBuyerBrokerAgreementRequest
 */
export interface CreateBuyerBrokerAgreementRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'signeeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'signeeEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'renderMode'?: CreateBuyerBrokerAgreementRequestRenderModeEnum;
    /**
     * 
     * @type {SenderDto}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'sendingAgent': SenderDto;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'documentFieldValues'?: Array<DocumentFieldValueDto>;
    /**
     * 
     * @type {CreatePacketRequestZoneId}
     * @memberof CreateBuyerBrokerAgreementRequest
     */
    'zoneId'?: CreatePacketRequestZoneId;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateBuyerBrokerAgreementRequestRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface CreateDocumentOnBehalfOfTemplateOwnerRequest
 */
export interface CreateDocumentOnBehalfOfTemplateOwnerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'signeeName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'signeeEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'renderMode'?: CreateDocumentOnBehalfOfTemplateOwnerRequestRenderModeEnum;
    /**
     * 
     * @type {CreatePacketRequestZoneId}
     * @memberof CreateDocumentOnBehalfOfTemplateOwnerRequest
     */
    'zoneId'?: CreatePacketRequestZoneId;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateDocumentOnBehalfOfTemplateOwnerRequestRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface CreateDocumentRequest
 */
export interface CreateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof CreateDocumentRequest
     */
    'documentFieldValues'?: Array<DocumentFieldValueDto>;
    /**
     * 
     * @type {Array<DocumentSigneeInfoDto>}
     * @memberof CreateDocumentRequest
     */
    'signees'?: Array<DocumentSigneeInfoDto>;
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof CreateDocumentRequest
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
    /**
     * 
     * @type {SenderDto}
     * @memberof CreateDocumentRequest
     */
    'sender': SenderDto;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'emailSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'emailBody'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'renderMode'?: CreateDocumentRequestRenderModeEnum;
    /**
     * 
     * @type {CreatePacketRequestZoneId}
     * @memberof CreateDocumentRequest
     */
    'zoneId'?: CreatePacketRequestZoneId;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateDocumentRequestRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface CreateDocumentTemplateRequest
 */
export interface CreateDocumentTemplateRequest {
    /**
     * 
     * @type {File}
     * @memberof CreateDocumentTemplateRequest
     */
    'file': File;
    /**
     * 
     * @type {DocumentTemplateRequest}
     * @memberof CreateDocumentTemplateRequest
     */
    'documentTemplateRequest'?: DocumentTemplateRequest;
}
/**
 * 
 * @export
 * @interface CreateDraftDocumentTemplate
 */
export interface CreateDraftDocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof CreateDraftDocumentTemplate
     */
    'sourceDocumentTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDraftDocumentTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof CreateDraftDocumentTemplate
     */
    'documentFieldValues'?: Array<DocumentFieldValueDto>;
    /**
     * 
     * @type {DocumentTemplateDefaultsDto}
     * @memberof CreateDraftDocumentTemplate
     */
    'defaults'?: DocumentTemplateDefaultsDto;
}
/**
 * 
 * @export
 * @interface CreateDraftPacketTemplate
 */
export interface CreateDraftPacketTemplate {
    /**
     * 
     * @type {string}
     * @memberof CreateDraftPacketTemplate
     */
    'sourcePacketTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDraftPacketTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {Array<CreateDraftDocumentTemplate>}
     * @memberof CreateDraftPacketTemplate
     */
    'documentTemplates'?: Array<CreateDraftDocumentTemplate>;
}
/**
 * 
 * @export
 * @interface CreateFolderRequest
 */
export interface CreateFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'folderType': CreateFolderRequestFolderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderRequest
     */
    'description'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateFolderRequestFolderTypeEnum {
    Global = 'GLOBAL',
    StateOrProvince = 'STATE_OR_PROVINCE',
    Generic = 'GENERIC'
}

/**
 * 
 * @export
 * @interface CreatePacketRequest
 */
export interface CreatePacketRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'packetTemplateId': string;
    /**
     * 
     * @type {Array<PacketDocumentFieldValueDto>}
     * @memberof CreatePacketRequest
     */
    'documentFieldValues'?: Array<PacketDocumentFieldValueDto>;
    /**
     * 
     * @type {Array<DocumentSigneeInfoDto>}
     * @memberof CreatePacketRequest
     */
    'signees'?: Array<DocumentSigneeInfoDto>;
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof CreatePacketRequest
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
    /**
     * 
     * @type {SenderDto}
     * @memberof CreatePacketRequest
     */
    'sender': SenderDto;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'emailSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'emailBody'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequest
     */
    'renderMode'?: CreatePacketRequestRenderModeEnum;
    /**
     * 
     * @type {CreatePacketRequestZoneId}
     * @memberof CreatePacketRequest
     */
    'zoneId'?: CreatePacketRequestZoneId;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePacketRequestRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface CreatePacketRequestZoneId
 */
export interface CreatePacketRequestZoneId {
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneId
     */
    'id'?: string;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRules}
     * @memberof CreatePacketRequestZoneId
     */
    'rules'?: CreatePacketRequestZoneIdRules;
}
/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRules
 */
export interface CreatePacketRequestZoneIdRules {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRules
     */
    'fixedOffset'?: boolean;
    /**
     * 
     * @type {Array<CreatePacketRequestZoneIdRulesTransitionsInner>}
     * @memberof CreatePacketRequestZoneIdRules
     */
    'transitions'?: Array<CreatePacketRequestZoneIdRulesTransitionsInner>;
    /**
     * 
     * @type {Array<CreatePacketRequestZoneIdRulesTransitionRulesInner>}
     * @memberof CreatePacketRequestZoneIdRules
     */
    'transitionRules'?: Array<CreatePacketRequestZoneIdRulesTransitionRulesInner>;
}
/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRulesTransitionRulesInner
 */
export interface CreatePacketRequestZoneIdRulesTransitionRulesInner {
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'month'?: CreatePacketRequestZoneIdRulesTransitionRulesInnerMonthEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'timeDefinition'?: CreatePacketRequestZoneIdRulesTransitionRulesInnerTimeDefinitionEnum;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'standardOffset'?: CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'offsetBefore'?: CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'offsetAfter'?: CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'dayOfWeek'?: CreatePacketRequestZoneIdRulesTransitionRulesInnerDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'dayOfMonthIndicator'?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'localTime'?: LocalTime;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionRulesInner
     */
    'midnightEndOfDay'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePacketRequestZoneIdRulesTransitionRulesInnerMonthEnum {
    January = 'JANUARY',
    February = 'FEBRUARY',
    March = 'MARCH',
    April = 'APRIL',
    May = 'MAY',
    June = 'JUNE',
    July = 'JULY',
    August = 'AUGUST',
    September = 'SEPTEMBER',
    October = 'OCTOBER',
    November = 'NOVEMBER',
    December = 'DECEMBER'
}
/**
    * @export
    * @enum {string}
    */
export enum CreatePacketRequestZoneIdRulesTransitionRulesInnerTimeDefinitionEnum {
    Utc = 'UTC',
    Wall = 'WALL',
    Standard = 'STANDARD'
}
/**
    * @export
    * @enum {string}
    */
export enum CreatePacketRequestZoneIdRulesTransitionRulesInnerDayOfWeekEnum {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY'
}

/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRulesTransitionsInner
 */
export interface CreatePacketRequestZoneIdRulesTransitionsInner {
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'offsetBefore'?: CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'offsetAfter'?: CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore;
    /**
     * 
     * @type {CreatePacketRequestZoneIdRulesTransitionsInnerDuration}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'duration'?: CreatePacketRequestZoneIdRulesTransitionsInnerDuration;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'gap'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'dateTimeBefore'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'dateTimeAfter'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'instant'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInner
     */
    'overlap'?: boolean;
}
/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRulesTransitionsInnerDuration
 */
export interface CreatePacketRequestZoneIdRulesTransitionsInnerDuration {
    /**
     * 
     * @type {number}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDuration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDuration
     */
    'nano'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDuration
     */
    'negative'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDuration
     */
    'zero'?: boolean;
    /**
     * 
     * @type {Array<CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner>}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDuration
     */
    'units'?: Array<CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner>;
}
/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner
 */
export interface CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner
     */
    'dateBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner
     */
    'timeBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerDurationUnitsInner
     */
    'durationEstimated'?: boolean;
}
/**
 * 
 * @export
 * @interface CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore
 */
export interface CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore {
    /**
     * 
     * @type {number}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore
     */
    'totalSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePacketRequestZoneIdRulesTransitionsInnerOffsetBefore
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CreatePacketTemplateRequest
 */
export interface CreatePacketTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePacketTemplateRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePacketTemplateRequest
     */
    'oneTimeUse'?: boolean;
    /**
     * 
     * @type {Array<DocumentTemplateIdRequest>}
     * @memberof CreatePacketTemplateRequest
     */
    'documentTemplates'?: Array<DocumentTemplateIdRequest>;
}
/**
 * 
 * @export
 * @interface DocumentAuthToken
 */
export interface DocumentAuthToken {
    /**
     * 
     * @type {string}
     * @memberof DocumentAuthToken
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DocumentCertificateResponse
 */
export interface DocumentCertificateResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateResponse
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentTemplateResponse}
     * @memberof DocumentCertificateResponse
     */
    'documentTemplate'?: DocumentTemplateResponse;
    /**
     * 
     * @type {Array<DocumentField>}
     * @memberof DocumentCertificateResponse
     */
    'fields'?: Array<DocumentField>;
    /**
     * 
     * @type {Sender}
     * @memberof DocumentCertificateResponse
     */
    'sender'?: Sender;
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentCertificateResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentCertificateResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {Array<DocumentCertificateSignatureResponse>}
     * @memberof DocumentCertificateResponse
     */
    'signatures'?: Array<DocumentCertificateSignatureResponse>;
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateResponse
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface DocumentCertificateSignatureResponse
 */
export interface DocumentCertificateSignatureResponse {
    /**
     * 
     * @type {DocumentCertificateSigneeResponse}
     * @memberof DocumentCertificateSignatureResponse
     */
    'signeeUser'?: DocumentCertificateSigneeResponse;
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateSignatureResponse
     */
    'signeeType'?: DocumentCertificateSignatureResponseSigneeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentCertificateSignatureResponse
     */
    'signeeIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentCertificateSignatureResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentCertificateSignatureResponse
     */
    'signedAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentCertificateSignatureResponseSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentCertificateSigneeResponse
 */
export interface DocumentCertificateSigneeResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateSigneeResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCertificateSigneeResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface DocumentField
 */
export interface DocumentField {
    /**
     * 
     * @type {number}
     * @memberof DocumentField
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentField
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentField
     */
    'completed'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentFieldDefinition
 */
export interface DocumentFieldDefinition {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldDefinition
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinition
     */
    'type': DocumentFieldDefinitionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinition
     */
    'signeeType': DocumentFieldDefinitionSigneeTypeEnum;
    /**
     * 
     * @type {DocumentFieldFormatting}
     * @memberof DocumentFieldDefinition
     */
    'formatting': DocumentFieldFormatting;
    /**
     * 
     * @type {Array<DocumentFieldLocation>}
     * @memberof DocumentFieldDefinition
     */
    'fieldLocations'?: Array<DocumentFieldLocation>;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinition
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldDefinition
     */
    'signeeIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinition
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFieldDefinition
     */
    'readonly'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldDefinitionTypeEnum {
    Boolean = 'BOOLEAN',
    Date = 'DATE',
    String = 'STRING',
    Number = 'NUMBER',
    Textarea = 'TEXTAREA',
    Strikethrough = 'STRIKETHROUGH',
    Radio = 'RADIO',
    Initials = 'INITIALS',
    Signature = 'SIGNATURE',
    SignedDate = 'SIGNED_DATE',
    SignedDateOnly = 'SIGNED_DATE_ONLY',
    SignedTimeOnly = 'SIGNED_TIME_ONLY',
    CreatedAtDate = 'CREATED_AT_DATE',
    SigneeName = 'SIGNEE_NAME',
    SigneeEmail = 'SIGNEE_EMAIL',
    AgentName = 'AGENT_NAME',
    AgentEmail = 'AGENT_EMAIL',
    AgentBrokerage = 'AGENT_BROKERAGE',
    AgentBrokerageLicenseId = 'AGENT_BROKERAGE_LICENSE_ID',
    AgentBrokerageAddress = 'AGENT_BROKERAGE_ADDRESS',
    AgentBrokerName = 'AGENT_BROKER_NAME',
    AgentBrokerPhone = 'AGENT_BROKER_PHONE',
    AgentBrokerEmail = 'AGENT_BROKER_EMAIL',
    AgentFullName = 'AGENT_FULL_NAME',
    AgentEmailAddress = 'AGENT_EMAIL_ADDRESS',
    AgentPhone = 'AGENT_PHONE',
    AgentLicenseNumber = 'AGENT_LICENSE_NUMBER',
    TransactionAddress = 'TRANSACTION_ADDRESS',
    TransactionAddressStreet = 'TRANSACTION_ADDRESS_STREET',
    TransactionAddressCityStateAndZip = 'TRANSACTION_ADDRESS_CITY_STATE_AND_ZIP',
    TransactionAddressStreetNumber = 'TRANSACTION_ADDRESS_STREET_NUMBER',
    TransactionAddressStreetName = 'TRANSACTION_ADDRESS_STREET_NAME',
    TransactionAddressUnitNumber = 'TRANSACTION_ADDRESS_UNIT_NUMBER',
    TransactionAddressCity = 'TRANSACTION_ADDRESS_CITY',
    TransactionAddressState = 'TRANSACTION_ADDRESS_STATE',
    TransactionAddressZip = 'TRANSACTION_ADDRESS_ZIP',
    TransactionAddressCountry = 'TRANSACTION_ADDRESS_COUNTRY',
    TransactionMlsNumber = 'TRANSACTION_MLS_NUMBER',
    TransactionEscrowNumber = 'TRANSACTION_ESCROW_NUMBER',
    TransactionYearBuilt = 'TRANSACTION_YEAR_BUILT',
    TransactionEstimatedClosingDate = 'TRANSACTION_ESTIMATED_CLOSING_DATE',
    TransactionEmailAddress = 'TRANSACTION_EMAIL_ADDRESS'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldDefinitionSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentFieldDefinitionDto
 */
export interface DocumentFieldDefinitionDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldDefinitionDto
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinitionDto
     */
    'type': DocumentFieldDefinitionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinitionDto
     */
    'signeeType': DocumentFieldDefinitionDtoSigneeTypeEnum;
    /**
     * 
     * @type {DocumentFieldFormattingDto}
     * @memberof DocumentFieldDefinitionDto
     */
    'formatting'?: DocumentFieldFormattingDto;
    /**
     * 
     * @type {Array<DocumentFieldLocationDto>}
     * @memberof DocumentFieldDefinitionDto
     */
    'fieldLocations'?: Array<DocumentFieldLocationDto>;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinitionDto
     */
    'displayName': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldDefinitionDto
     */
    'signeeIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldDefinitionDto
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentFieldDefinitionDto
     */
    'readonly'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldDefinitionDtoTypeEnum {
    Boolean = 'BOOLEAN',
    Date = 'DATE',
    String = 'STRING',
    Number = 'NUMBER',
    Textarea = 'TEXTAREA',
    Strikethrough = 'STRIKETHROUGH',
    Radio = 'RADIO',
    Initials = 'INITIALS',
    Signature = 'SIGNATURE',
    SignedDate = 'SIGNED_DATE',
    SignedDateOnly = 'SIGNED_DATE_ONLY',
    SignedTimeOnly = 'SIGNED_TIME_ONLY',
    CreatedAtDate = 'CREATED_AT_DATE',
    SigneeName = 'SIGNEE_NAME',
    SigneeEmail = 'SIGNEE_EMAIL',
    AgentName = 'AGENT_NAME',
    AgentEmail = 'AGENT_EMAIL',
    AgentBrokerage = 'AGENT_BROKERAGE',
    AgentBrokerageLicenseId = 'AGENT_BROKERAGE_LICENSE_ID',
    AgentBrokerageAddress = 'AGENT_BROKERAGE_ADDRESS',
    AgentBrokerName = 'AGENT_BROKER_NAME',
    AgentBrokerPhone = 'AGENT_BROKER_PHONE',
    AgentBrokerEmail = 'AGENT_BROKER_EMAIL',
    AgentFullName = 'AGENT_FULL_NAME',
    AgentEmailAddress = 'AGENT_EMAIL_ADDRESS',
    AgentPhone = 'AGENT_PHONE',
    AgentLicenseNumber = 'AGENT_LICENSE_NUMBER',
    TransactionAddress = 'TRANSACTION_ADDRESS',
    TransactionAddressStreet = 'TRANSACTION_ADDRESS_STREET',
    TransactionAddressCityStateAndZip = 'TRANSACTION_ADDRESS_CITY_STATE_AND_ZIP',
    TransactionAddressStreetNumber = 'TRANSACTION_ADDRESS_STREET_NUMBER',
    TransactionAddressStreetName = 'TRANSACTION_ADDRESS_STREET_NAME',
    TransactionAddressUnitNumber = 'TRANSACTION_ADDRESS_UNIT_NUMBER',
    TransactionAddressCity = 'TRANSACTION_ADDRESS_CITY',
    TransactionAddressState = 'TRANSACTION_ADDRESS_STATE',
    TransactionAddressZip = 'TRANSACTION_ADDRESS_ZIP',
    TransactionAddressCountry = 'TRANSACTION_ADDRESS_COUNTRY',
    TransactionMlsNumber = 'TRANSACTION_MLS_NUMBER',
    TransactionEscrowNumber = 'TRANSACTION_ESCROW_NUMBER',
    TransactionYearBuilt = 'TRANSACTION_YEAR_BUILT',
    TransactionEstimatedClosingDate = 'TRANSACTION_ESTIMATED_CLOSING_DATE',
    TransactionEmailAddress = 'TRANSACTION_EMAIL_ADDRESS'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldDefinitionDtoSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentFieldFormatting
 */
export interface DocumentFieldFormatting {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldFormatting
     */
    'fontSize': number;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldFormatting
     */
    'rotationAngle'?: number;
}
/**
 * 
 * @export
 * @interface DocumentFieldFormattingDto
 */
export interface DocumentFieldFormattingDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldFormattingDto
     */
    'fontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldFormattingDto
     */
    'rotationAngle'?: number;
}
/**
 * 
 * @export
 * @interface DocumentFieldLocation
 */
export interface DocumentFieldLocation {
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentFieldLocation
     */
    'position': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldLocation
     */
    'pageNum'?: number;
}
/**
 * 
 * @export
 * @interface DocumentFieldLocationDto
 */
export interface DocumentFieldLocationDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentFieldLocationDto
     */
    'position': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldLocationDto
     */
    'pageNum'?: number;
}
/**
 * 
 * @export
 * @interface DocumentFieldValidation
 */
export interface DocumentFieldValidation {
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldValidation
     */
    'validationType': DocumentFieldValidationValidationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldValidation
     */
    'fieldIndex'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentFieldValidation
     */
    'relatedFieldIndexes'?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldValidationValidationTypeEnum {
    Required = 'REQUIRED',
    RequiredAtLeastOne = 'REQUIRED_AT_LEAST_ONE',
    RequiredIf = 'REQUIRED_IF',
    RequiredOnlyOne = 'REQUIRED_ONLY_ONE',
    RequiredIfAny = 'REQUIRED_IF_ANY',
    RequiredIfAll = 'REQUIRED_IF_ALL'
}

/**
 * 
 * @export
 * @interface DocumentFieldValidationDto
 */
export interface DocumentFieldValidationDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldValidationDto
     */
    'validationType': DocumentFieldValidationDtoValidationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldValidationDto
     */
    'fieldIndex'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentFieldValidationDto
     */
    'relatedFieldIndexes'?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentFieldValidationDtoValidationTypeEnum {
    Required = 'REQUIRED',
    RequiredAtLeastOne = 'REQUIRED_AT_LEAST_ONE',
    RequiredIf = 'REQUIRED_IF',
    RequiredOnlyOne = 'REQUIRED_ONLY_ONE',
    RequiredIfAny = 'REQUIRED_IF_ANY',
    RequiredIfAll = 'REQUIRED_IF_ALL'
}

/**
 * 
 * @export
 * @interface DocumentFieldValueDto
 */
export interface DocumentFieldValueDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldValueDto
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldValueDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface DocumentImagePage
 */
export interface DocumentImagePage {
    /**
     * 
     * @type {number}
     * @memberof DocumentImagePage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {any}
     * @memberof DocumentImagePage
     */
    'viewport'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof DocumentImagePage
     */
    'annotations'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DocumentImagePage
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface DocumentImages
 */
export interface DocumentImages {
    /**
     * 
     * @type {Array<DocumentImagePage>}
     * @memberof DocumentImages
     */
    'pages'?: Array<DocumentImagePage>;
    /**
     * 
     * @type {number}
     * @memberof DocumentImages
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface DocumentMigrationPageableResponse
 */
export interface DocumentMigrationPageableResponse {
    /**
     * 
     * @type {Array<DocumentMigrationResponse>}
     * @memberof DocumentMigrationPageableResponse
     */
    'data'?: Array<DocumentMigrationResponse>;
    /**
     * 
     * @type {number}
     * @memberof DocumentMigrationPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMigrationPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMigrationPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface DocumentMigrationRequest
 */
export interface DocumentMigrationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentMigrationRequest
     */
    'documentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DocumentMigrationResponse
 */
export interface DocumentMigrationResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'documentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'templateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'templateFilePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'beforeSigningV1'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'beforeSigningV2'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'signedPdfV1'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'signedPdfV2'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'generatePdfStatus'?: DocumentMigrationResponseGeneratePdfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentMigrationResponse
     */
    'migrateStatus'?: DocumentMigrationResponseMigrateStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentMigrationResponseGeneratePdfStatusEnum {
    NotStarted = 'NOT_STARTED',
    Running = 'RUNNING',
    Success = 'SUCCESS',
    Failed = 'FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentMigrationResponseMigrateStatusEnum {
    NotStarted = 'NOT_STARTED',
    Running = 'RUNNING',
    Success = 'SUCCESS',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface DocumentRecipientInfoDto
 */
export interface DocumentRecipientInfoDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentRecipientInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentRecipientInfoDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DocumentResponse
 */
export interface DocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentTemplateResponse}
     * @memberof DocumentResponse
     */
    'documentTemplate'?: DocumentTemplateResponse;
    /**
     * 
     * @type {Array<Signature>}
     * @memberof DocumentResponse
     */
    'signatures'?: Array<Signature>;
    /**
     * 
     * @type {Array<SigneeUser>}
     * @memberof DocumentResponse
     */
    'recipients'?: Array<SigneeUser>;
    /**
     * 
     * @type {Array<DocumentField>}
     * @memberof DocumentResponse
     */
    'fields'?: Array<DocumentField>;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentResponse
     */
    'documentOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentResponse
     */
    'signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentResponse
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'certificateAuthToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentResponse
     */
    'renderMode'?: DocumentResponseRenderModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentResponseRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface DocumentSigneeConfig
 */
export interface DocumentSigneeConfig {
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeConfig
     */
    'signeeType': DocumentSigneeConfigSigneeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeConfig
     */
    'minNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeConfig
     */
    'maxNum'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentSigneeConfigSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentSigneeConfigDto
 */
export interface DocumentSigneeConfigDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeConfigDto
     */
    'signeeType': DocumentSigneeConfigDtoSigneeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeConfigDto
     */
    'minNum': number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeConfigDto
     */
    'maxNum': number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentSigneeConfigDtoSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentSigneeInfo
 */
export interface DocumentSigneeInfo {
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfo
     */
    'signeeType': DocumentSigneeInfoSigneeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfo
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeInfo
     */
    'signeeIndex': number;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeInfo
     */
    'signOrder'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentSigneeInfoSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentSigneeInfoDto
 */
export interface DocumentSigneeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfoDto
     */
    'signeeType': DocumentSigneeInfoDtoSigneeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfoDto
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeInfoDto
     */
    'signeeIndex': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeInfoDto
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentSigneeInfoDto
     */
    'signOrder'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentSigneeInfoDtoSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface DocumentSigneeTypeResponse
 */
export interface DocumentSigneeTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeTypeResponse
     */
    'signeeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSigneeTypeResponse
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface DocumentSigneeTypesResponse
 */
export interface DocumentSigneeTypesResponse {
    /**
     * 
     * @type {Array<DocumentSigneeTypeResponse>}
     * @memberof DocumentSigneeTypesResponse
     */
    'data'?: Array<DocumentSigneeTypeResponse>;
}
/**
 * 
 * @export
 * @interface DocumentTemplateDefaults
 */
export interface DocumentTemplateDefaults {
    /**
     * 
     * @type {Array<DocumentSigneeInfo>}
     * @memberof DocumentTemplateDefaults
     */
    'signees'?: Array<DocumentSigneeInfo>;
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof DocumentTemplateDefaults
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
    /**
     * 
     * @type {Sender}
     * @memberof DocumentTemplateDefaults
     */
    'sender'?: Sender;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaults
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaults
     */
    'emailSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaults
     */
    'emailBody'?: string;
}
/**
 * 
 * @export
 * @interface DocumentTemplateDefaultsDto
 */
export interface DocumentTemplateDefaultsDto {
    /**
     * 
     * @type {Array<DocumentSigneeInfoDto>}
     * @memberof DocumentTemplateDefaultsDto
     */
    'signees'?: Array<DocumentSigneeInfoDto>;
    /**
     * 
     * @type {Array<DocumentRecipientInfoDto>}
     * @memberof DocumentTemplateDefaultsDto
     */
    'recipients'?: Array<DocumentRecipientInfoDto>;
    /**
     * 
     * @type {SenderDto}
     * @memberof DocumentTemplateDefaultsDto
     */
    'sender'?: SenderDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaultsDto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaultsDto
     */
    'emailSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateDefaultsDto
     */
    'emailBody'?: string;
}
/**
 * 
 * @export
 * @interface DocumentTemplateIdListRequest
 */
export interface DocumentTemplateIdListRequest {
    /**
     * 
     * @type {Array<DocumentTemplateIdRequest>}
     * @memberof DocumentTemplateIdListRequest
     */
    'documentTemplates'?: Array<DocumentTemplateIdRequest>;
}
/**
 * 
 * @export
 * @interface DocumentTemplateIdRequest
 */
export interface DocumentTemplateIdRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateIdRequest
     */
    'documentTemplateId': string;
}
/**
 * 
 * @export
 * @interface DocumentTemplateImagesResponse
 */
export interface DocumentTemplateImagesResponse {
    /**
     * 
     * @type {DocumentImages}
     * @memberof DocumentTemplateImagesResponse
     */
    'documentImages'?: DocumentImages;
}
/**
 * 
 * @export
 * @interface DocumentTemplateMetadataResponse
 */
export interface DocumentTemplateMetadataResponse {
    /**
     * 
     * @type {Array<FolderTemplate>}
     * @memberof DocumentTemplateMetadataResponse
     */
    'folderTemplates'?: Array<FolderTemplate>;
    /**
     * 
     * @type {Array<SharedTemplate>}
     * @memberof DocumentTemplateMetadataResponse
     */
    'sharedTemplates'?: Array<SharedTemplate>;
    /**
     * 
     * @type {MyTemplate}
     * @memberof DocumentTemplateMetadataResponse
     */
    'myTemplate'?: MyTemplate;
}
/**
 * 
 * @export
 * @interface DocumentTemplatePageableResponse
 */
export interface DocumentTemplatePageableResponse {
    /**
     * 
     * @type {Array<DocumentTemplateResponse>}
     * @memberof DocumentTemplatePageableResponse
     */
    'data'?: Array<DocumentTemplateResponse>;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplatePageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplatePageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplatePageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface DocumentTemplatePreview
 */
export interface DocumentTemplatePreview {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplatePreview
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplatePreview
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplatePreview
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplatePreview
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplatePreview
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplatePreview
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplatePreview
     */
    'type'?: DocumentTemplatePreviewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplatePreview
     */
    'draft'?: boolean;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof DocumentTemplatePreview
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
    /**
     * 
     * @type {DocumentImages}
     * @memberof DocumentTemplatePreview
     */
    'documentImages'?: DocumentImages;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentTemplatePreviewTypeEnum {
    UserPdf = 'USER_PDF',
    Agreement = 'AGREEMENT'
}

/**
 * 
 * @export
 * @interface DocumentTemplatePreviewResponse
 */
export interface DocumentTemplatePreviewResponse {
    /**
     * 
     * @type {Array<DocumentTemplatePreview>}
     * @memberof DocumentTemplatePreviewResponse
     */
    'documentTemplatePreviews'?: Array<DocumentTemplatePreview>;
}
/**
 * 
 * @export
 * @interface DocumentTemplateRequest
 */
export interface DocumentTemplateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateRequest
     */
    'sharingConsentFormSigned'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentTemplateResponse
 */
export interface DocumentTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplateResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplateResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'type'?: DocumentTemplateResponseTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'draft'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'editable'?: boolean;
    /**
     * 
     * @type {Array<DocumentFieldDefinition>}
     * @memberof DocumentTemplateResponse
     */
    'fieldDefinitions'?: Array<DocumentFieldDefinition>;
    /**
     * 
     * @type {Array<DocumentSigneeConfig>}
     * @memberof DocumentTemplateResponse
     */
    'signeeConfigs'?: Array<DocumentSigneeConfig>;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof DocumentTemplateResponse
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
    /**
     * 
     * @type {Array<DocumentFieldValidation>}
     * @memberof DocumentTemplateResponse
     */
    'documentFieldValidations'?: Array<DocumentFieldValidation>;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplateResponse
     */
    'pagesCount'?: number;
    /**
     * 
     * @type {DocumentTemplateDefaults}
     * @memberof DocumentTemplateResponse
     */
    'defaults'?: DocumentTemplateDefaults;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'hasImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'imagesEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateResponse
     */
    'renderMode'?: DocumentTemplateResponseRenderModeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'oneTimeUse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateResponse
     */
    'oneTimeUsePacket'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentTemplateResponseTypeEnum {
    UserPdf = 'USER_PDF',
    Agreement = 'AGREEMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentTemplateResponseRenderModeEnum {
    Canvas = 'CANVAS',
    Images = 'IMAGES'
}

/**
 * 
 * @export
 * @interface DocumentTimezoneResponse
 */
export interface DocumentTimezoneResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentTimezoneResponse
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface DownloadUrlResponse
 */
export interface DownloadUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadUrlResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadUrlResponse
     */
    'attachmentUrl'?: string;
}
/**
 * 
 * @export
 * @interface EntityIdResponse
 */
export interface EntityIdResponse {
    /**
     * 
     * @type {string}
     * @memberof EntityIdResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageClientTypeEnum {
    Producer = 'PRODUCER',
    Consumer = 'CONSUMER'
}
/**
    * @export
    * @enum {string}
    */
export enum FailedMessageStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FailedMessageUpdateStatusRequestStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface Folder
 */
export interface Folder {
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FolderDocumentTemplate
 */
export interface FolderDocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof FolderDocumentTemplate
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDocumentTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDocumentTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentTemplate
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentTemplate
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FolderDocumentTemplate
     */
    'draft'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FolderDocumentTemplate
     */
    'owner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FolderDocumentTemplate
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDocumentTemplate
     */
    'ownerId'?: string;
    /**
     * 
     * @type {Folder}
     * @memberof FolderDocumentTemplate
     */
    'parent'?: Folder;
    /**
     * 
     * @type {Array<Folder>}
     * @memberof FolderDocumentTemplate
     */
    'parents'?: Array<Folder>;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof FolderDocumentTemplate
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
}
/**
 * 
 * @export
 * @interface FolderDocumentTemplatePageableResponse
 */
export interface FolderDocumentTemplatePageableResponse {
    /**
     * 
     * @type {Array<FolderDocumentTemplate>}
     * @memberof FolderDocumentTemplatePageableResponse
     */
    'data'?: Array<FolderDocumentTemplate>;
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentTemplatePageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentTemplatePageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderDocumentTemplatePageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface FolderPageableResponse
 */
export interface FolderPageableResponse {
    /**
     * 
     * @type {Array<FolderResponse>}
     * @memberof FolderPageableResponse
     */
    'data'?: Array<FolderResponse>;
    /**
     * 
     * @type {number}
     * @memberof FolderPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface FolderResponse
 */
export interface FolderResponse {
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'folderType'?: FolderResponseFolderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'parentFolder'?: string;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof FolderResponse
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof FolderResponse
     */
    'owner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderResponse
     */
    'ownerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FolderResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FolderResponse
     */
    'updatedAt'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FolderResponseFolderTypeEnum {
    Global = 'GLOBAL',
    StateOrProvince = 'STATE_OR_PROVINCE',
    Generic = 'GENERIC'
}

/**
 * 
 * @export
 * @interface FolderTemplate
 */
export interface FolderTemplate {
    /**
     * 
     * @type {string}
     * @memberof FolderTemplate
     */
    'folderType'?: FolderTemplateFolderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FolderTemplate
     */
    'folderName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FolderTemplate
     */
    'count'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum FolderTemplateFolderTypeEnum {
    Global = 'GLOBAL',
    StateOrProvince = 'STATE_OR_PROVINCE',
    Generic = 'GENERIC'
}

/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

/**
    * @export
    * @enum {string}
    */
export enum GenericSearchResponseFailedMessageSortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface ItemSigneeUserResponse
 */
export interface ItemSigneeUserResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemSigneeUserResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemSigneeUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSigneeUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSigneeUserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemSigneeUserResponse
     */
    'signOrder'?: number;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface MoveTemplatesRequest
 */
export interface MoveTemplatesRequest {
    /**
     * 
     * @type {Array<MoveToFoldersRequest>}
     * @memberof MoveTemplatesRequest
     */
    'moveToFolders': Array<MoveToFoldersRequest>;
}
/**
 * 
 * @export
 * @interface MoveToFoldersRequest
 */
export interface MoveToFoldersRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveToFoldersRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MoveToFoldersRequest
     */
    'folderIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MyTemplate
 */
export interface MyTemplate {
    /**
     * 
     * @type {number}
     * @memberof MyTemplate
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface OneTimeTemplate
 */
export interface OneTimeTemplate {
    /**
     * 
     * @type {string}
     * @memberof OneTimeTemplate
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeTemplate
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplate
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplate
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplate
     */
    'documentTemplateCount'?: number;
    /**
     * 
     * @type {DocumentTemplateDefaults}
     * @memberof OneTimeTemplate
     */
    'defaults'?: DocumentTemplateDefaults;
}
/**
 * 
 * @export
 * @interface OneTimeTemplatePageableResponse
 */
export interface OneTimeTemplatePageableResponse {
    /**
     * 
     * @type {Array<OneTimeTemplate>}
     * @memberof OneTimeTemplatePageableResponse
     */
    'data'?: Array<OneTimeTemplate>;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplatePageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplatePageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTemplatePageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface PacketDocumentFieldValueDto
 */
export interface PacketDocumentFieldValueDto {
    /**
     * 
     * @type {string}
     * @memberof PacketDocumentFieldValueDto
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {number}
     * @memberof PacketDocumentFieldValueDto
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof PacketDocumentFieldValueDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PacketDownloadUrlResponse
 */
export interface PacketDownloadUrlResponse {
    /**
     * 
     * @type {Array<DownloadUrlResponse>}
     * @memberof PacketDownloadUrlResponse
     */
    'documentUrls'?: Array<DownloadUrlResponse>;
}
/**
 * 
 * @export
 * @interface PacketResponse
 */
export interface PacketResponse {
    /**
     * 
     * @type {string}
     * @memberof PacketResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<DocumentResponse>}
     * @memberof PacketResponse
     */
    'documents'?: Array<DocumentResponse>;
    /**
     * 
     * @type {string}
     * @memberof PacketResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PacketResponse
     */
    'signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PacketResponse
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface PacketTemplatePageableResponse
 */
export interface PacketTemplatePageableResponse {
    /**
     * 
     * @type {Array<PacketTemplateResponse>}
     * @memberof PacketTemplatePageableResponse
     */
    'data'?: Array<PacketTemplateResponse>;
    /**
     * 
     * @type {number}
     * @memberof PacketTemplatePageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketTemplatePageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketTemplatePageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface PacketTemplateResponse
 */
export interface PacketTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof PacketTemplateResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PacketTemplateResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PacketTemplateResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof PacketTemplateResponse
     */
    'ownerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketTemplateResponse
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PacketTemplateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<DocumentTemplateResponse>}
     * @memberof PacketTemplateResponse
     */
    'documentTemplates'?: Array<DocumentTemplateResponse>;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof PacketTemplateResponse
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PacketTemplateResponse
     */
    'oneTimeUse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PacketTemplateResponse
     */
    'owner'?: boolean;
}
/**
 * 
 * @export
 * @interface PacketWithDocumentTokensResponse
 */
export interface PacketWithDocumentTokensResponse {
    /**
     * 
     * @type {string}
     * @memberof PacketWithDocumentTokensResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<DocumentAuthToken>}
     * @memberof PacketWithDocumentTokensResponse
     */
    'documentAuthTokens'?: Array<DocumentAuthToken>;
}
/**
 * 
 * @export
 * @interface Sender
 */
export interface Sender {
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Sender
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SenderDto
 */
export interface SenderDto {
    /**
     * 
     * @type {string}
     * @memberof SenderDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SenderDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SenderDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SentItemPageableResponse
 */
export interface SentItemPageableResponse {
    /**
     * 
     * @type {Array<SentItemResponse>}
     * @memberof SentItemPageableResponse
     */
    'data'?: Array<SentItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof SentItemPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentItemPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentItemPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface SentItemResponse
 */
export interface SentItemResponse {
    /**
     * 
     * @type {string}
     * @memberof SentItemResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentItemResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SentItemResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof SentItemResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SentItemResponse
     */
    'signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SentItemResponse
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SentItemResponse
     */
    'documentsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SentItemResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {Array<ItemSigneeUserResponse>}
     * @memberof SentItemResponse
     */
    'sentTo'?: Array<ItemSigneeUserResponse>;
}
/**
 * 
 * @export
 * @interface SentItemTotalsResponse
 */
export interface SentItemTotalsResponse {
    /**
     * 
     * @type {number}
     * @memberof SentItemTotalsResponse
     */
    'activeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentItemTotalsResponse
     */
    'signedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentItemTotalsResponse
     */
    'incompleteCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SentItemTotalsResponse
     */
    'voidedCount'?: number;
}
/**
 * 
 * @export
 * @interface ShareConfigResponse
 */
export interface ShareConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ShareConfigResponse
     */
    'sharedWithType'?: ShareConfigResponseSharedWithTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShareConfigResponse
     */
    'sharedWithId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareConfigResponse
     */
    'accessLevel'?: ShareConfigResponseAccessLevelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ShareConfigResponseSharedWithTypeEnum {
    Team = 'TEAM',
    User = 'USER',
    All = 'ALL'
}
/**
    * @export
    * @enum {string}
    */
export enum ShareConfigResponseAccessLevelEnum {
    Reader = 'READER',
    Editor = 'EDITOR',
    Owner = 'OWNER'
}

/**
 * 
 * @export
 * @interface ShareFoldersWithTeamsRequest
 */
export interface ShareFoldersWithTeamsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareFoldersWithTeamsRequest
     */
    'folderIds': Array<string>;
    /**
     * 
     * @type {Array<ShareWithTeamRequest>}
     * @memberof ShareFoldersWithTeamsRequest
     */
    'shareWithTeams': Array<ShareWithTeamRequest>;
}
/**
 * 
 * @export
 * @interface ShareFoldersWithUsersRequest
 */
export interface ShareFoldersWithUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareFoldersWithUsersRequest
     */
    'folderIds': Array<string>;
    /**
     * 
     * @type {Array<ShareWithUserRequest>}
     * @memberof ShareFoldersWithUsersRequest
     */
    'shareWithUsers': Array<ShareWithUserRequest>;
}
/**
 * 
 * @export
 * @interface ShareTemplateWithTeamRequest
 */
export interface ShareTemplateWithTeamRequest {
    /**
     * 
     * @type {Team}
     * @memberof ShareTemplateWithTeamRequest
     */
    'team': Team;
    /**
     * 
     * @type {boolean}
     * @memberof ShareTemplateWithTeamRequest
     */
    'editor'?: boolean;
}
/**
 * 
 * @export
 * @interface ShareTemplateWithUserRequest
 */
export interface ShareTemplateWithUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareTemplateWithUserRequest
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShareTemplateWithUserRequest
     */
    'editor'?: boolean;
}
/**
 * 
 * @export
 * @interface ShareTemplatesWithTeamsRequest
 */
export interface ShareTemplatesWithTeamsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareTemplatesWithTeamsRequest
     */
    'templateIds': Array<string>;
    /**
     * 
     * @type {Array<ShareTemplateWithTeamRequest>}
     * @memberof ShareTemplatesWithTeamsRequest
     */
    'shareWithTeams': Array<ShareTemplateWithTeamRequest>;
}
/**
 * 
 * @export
 * @interface ShareTemplatesWithUsersRequest
 */
export interface ShareTemplatesWithUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareTemplatesWithUsersRequest
     */
    'templateIds': Array<string>;
    /**
     * 
     * @type {Array<ShareTemplateWithUserRequest>}
     * @memberof ShareTemplatesWithUsersRequest
     */
    'shareWithUsers': Array<ShareTemplateWithUserRequest>;
}
/**
 * 
 * @export
 * @interface ShareWithTeamRequest
 */
export interface ShareWithTeamRequest {
    /**
     * 
     * @type {Team}
     * @memberof ShareWithTeamRequest
     */
    'team': Team;
    /**
     * 
     * @type {string}
     * @memberof ShareWithTeamRequest
     */
    'accessLevel': ShareWithTeamRequestAccessLevelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ShareWithTeamRequestAccessLevelEnum {
    Reader = 'READER',
    Editor = 'EDITOR',
    Owner = 'OWNER'
}

/**
 * 
 * @export
 * @interface ShareWithUserRequest
 */
export interface ShareWithUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareWithUserRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ShareWithUserRequest
     */
    'accessLevel': ShareWithUserRequestAccessLevelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ShareWithUserRequestAccessLevelEnum {
    Reader = 'READER',
    Editor = 'EDITOR',
    Owner = 'OWNER'
}

/**
 * 
 * @export
 * @interface SharedByInfoPageableResponse
 */
export interface SharedByInfoPageableResponse {
    /**
     * 
     * @type {Array<SharedByUserInfo>}
     * @memberof SharedByInfoPageableResponse
     */
    'data'?: Array<SharedByUserInfo>;
    /**
     * 
     * @type {number}
     * @memberof SharedByInfoPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharedByInfoPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharedByInfoPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface SharedByUserInfo
 */
export interface SharedByUserInfo {
    /**
     * 
     * @type {string}
     * @memberof SharedByUserInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedByUserInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedByUserInfo
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SharedTemplate
 */
export interface SharedTemplate {
    /**
     * 
     * @type {string}
     * @memberof SharedTemplate
     */
    'sharedWithType'?: SharedTemplateSharedWithTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SharedTemplate
     */
    'count'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SharedTemplateSharedWithTypeEnum {
    Team = 'TEAM',
    User = 'USER',
    All = 'ALL'
}

/**
 * 
 * @export
 * @interface SharedWithInfo
 */
export interface SharedWithInfo {
    /**
     * 
     * @type {string}
     * @memberof SharedWithInfo
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedWithInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedWithInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedWithInfo
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SharedWithInfoPageableResponse
 */
export interface SharedWithInfoPageableResponse {
    /**
     * 
     * @type {Array<SharedWithInfo>}
     * @memberof SharedWithInfoPageableResponse
     */
    'data'?: Array<SharedWithInfo>;
    /**
     * 
     * @type {number}
     * @memberof SharedWithInfoPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharedWithInfoPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharedWithInfoPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface Signature
 */
export interface Signature {
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Signature
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Signature
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {SigneeUser}
     * @memberof Signature
     */
    'signee'?: SigneeUser;
    /**
     * 
     * @type {Array<DocumentField>}
     * @memberof Signature
     */
    'fields'?: Array<DocumentField>;
    /**
     * 
     * @type {number}
     * @memberof Signature
     */
    'signedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    'signeeType'?: SignatureSigneeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Signature
     */
    'signeeIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof Signature
     */
    'signOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Signature
     */
    'signed'?: boolean;
    /**
     * 
     * @type {{ [key: string]: DocumentField; }}
     * @memberof Signature
     */
    'fieldsByIndex'?: { [key: string]: DocumentField; };
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    'shortenedId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SignatureSigneeTypeEnum {
    Sender = 'SENDER',
    Buyer = 'BUYER',
    BuyersBroker = 'BUYERS_BROKER',
    BuyersLawyer = 'BUYERS_LAWYER',
    Seller = 'SELLER',
    BuyersAgent = 'BUYERS_AGENT',
    SellersAgent = 'SELLERS_AGENT',
    SellersBroker = 'SELLERS_BROKER',
    SellersLawyer = 'SELLERS_LAWYER',
    SellingAgent = 'SELLING_AGENT',
    SellingBroker = 'SELLING_BROKER',
    Signer = 'SIGNER',
    Landlord = 'LANDLORD',
    Tenant = 'TENANT',
    Lessor = 'LESSOR',
    Lessee = 'LESSEE',
    LeasingLesseeAgent = 'LEASING_LESSEE_AGENT',
    BuyerTenant = 'BUYER_TENANT',
    BuyersSellersAgent = 'BUYERS_SELLERS_AGENT',
    ListingAgent = 'LISTING_AGENT',
    ListingBroker = 'LISTING_BROKER',
    SellerLandlord = 'SELLER_LANDLORD',
    Witness = 'WITNESS',
    Other = 'OTHER',
    Customer = 'CUSTOMER',
    Owner = 'OWNER',
    Approver = 'APPROVER',
    BuyerTenantAgent = 'BUYER_TENANT_AGENT',
    SellerLandlordAgent = 'SELLER_LANDLORD_AGENT',
    OtherAgent = 'OTHER_AGENT',
    TeamLeader = 'TEAM_LEADER',
    TeamMember = 'TEAM_MEMBER',
    TransactionCoordinator = 'TRANSACTION_COORDINATOR',
    Escrow = 'ESCROW',
    Title = 'TITLE',
    ManagementCompany = 'MANAGEMENT_COMPANY',
    Attorney = 'ATTORNEY',
    Trustee = 'TRUSTEE',
    Referral = 'REFERRAL'
}

/**
 * 
 * @export
 * @interface SigneeAuthTokenDto
 */
export interface SigneeAuthTokenDto {
    /**
     * 
     * @type {string}
     * @memberof SigneeAuthTokenDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SigneeAuthTokenDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SigneeAuthTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SigneeDocumentAuthTokenResponse
 */
export interface SigneeDocumentAuthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof SigneeDocumentAuthTokenResponse
     */
    'documentId'?: string;
    /**
     * 
     * @type {SigneeUser}
     * @memberof SigneeDocumentAuthTokenResponse
     */
    'signeeUser'?: SigneeUser;
    /**
     * 
     * @type {string}
     * @memberof SigneeDocumentAuthTokenResponse
     */
    'authToken'?: string;
}
/**
 * 
 * @export
 * @interface SigneeIdListRequest
 */
export interface SigneeIdListRequest {
    /**
     * 
     * @type {Array<SigneeIdRequest>}
     * @memberof SigneeIdListRequest
     */
    'signees'?: Array<SigneeIdRequest>;
}
/**
 * 
 * @export
 * @interface SigneeIdRequest
 */
export interface SigneeIdRequest {
    /**
     * 
     * @type {string}
     * @memberof SigneeIdRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SigneePacketAuthTokenResponse
 */
export interface SigneePacketAuthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof SigneePacketAuthTokenResponse
     */
    'packetId'?: string;
    /**
     * 
     * @type {SigneeUser}
     * @memberof SigneePacketAuthTokenResponse
     */
    'signeeUser'?: SigneeUser;
    /**
     * 
     * @type {string}
     * @memberof SigneePacketAuthTokenResponse
     */
    'authToken'?: string;
}
/**
 * 
 * @export
 * @interface SigneeUser
 */
export interface SigneeUser {
    /**
     * 
     * @type {string}
     * @memberof SigneeUser
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SigneeUser
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SigneeUser
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof SigneeUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SigneeUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SigneeUser
     */
    'shortenedId'?: string;
}
/**
 * 
 * @export
 * @interface SubmitSignatureRequest
 */
export interface SubmitSignatureRequest {
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof SubmitSignatureRequest
     */
    'documentFieldValues': Array<DocumentFieldValueDto>;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TemplatesSearchPageableResponse
 */
export interface TemplatesSearchPageableResponse {
    /**
     * 
     * @type {Array<TemplatesSearchResponse>}
     * @memberof TemplatesSearchPageableResponse
     */
    'data'?: Array<TemplatesSearchResponse>;
    /**
     * 
     * @type {number}
     * @memberof TemplatesSearchPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplatesSearchPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplatesSearchPageableResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface TemplatesSearchResponse
 */
export interface TemplatesSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof TemplatesSearchResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesSearchResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesSearchResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplatesSearchResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplatesSearchResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TemplatesSearchResponse
     */
    'draft'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TemplatesSearchResponse
     */
    'owner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplatesSearchResponse
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplatesSearchResponse
     */
    'ownerId'?: string;
    /**
     * 
     * @type {Array<Folder>}
     * @memberof TemplatesSearchResponse
     */
    'parents'?: Array<Folder>;
    /**
     * 
     * @type {Array<ShareConfigResponse>}
     * @memberof TemplatesSearchResponse
     */
    'shareConfigs'?: Array<ShareConfigResponse>;
}
/**
 * 
 * @export
 * @interface UnshareWithTeamsRequest
 */
export interface UnshareWithTeamsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnshareWithTeamsRequest
     */
    'teamIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UnshareWithUsersRequest
 */
export interface UnshareWithUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnshareWithUsersRequest
     */
    'userIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDocumentTemplateRequest
 */
export interface UpdateDocumentTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentTemplateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentTemplateRequest
     */
    'type': UpdateDocumentTemplateRequestTypeEnum;
    /**
     * 
     * @type {Array<DocumentFieldDefinitionDto>}
     * @memberof UpdateDocumentTemplateRequest
     */
    'fieldDefinitions'?: Array<DocumentFieldDefinitionDto>;
    /**
     * 
     * @type {Array<DocumentSigneeConfigDto>}
     * @memberof UpdateDocumentTemplateRequest
     */
    'signeeConfigs'?: Array<DocumentSigneeConfigDto>;
    /**
     * 
     * @type {Array<DocumentFieldValidationDto>}
     * @memberof UpdateDocumentTemplateRequest
     */
    'documentFieldValidations'?: Array<DocumentFieldValidationDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateDocumentTemplateRequest
     */
    'pagesCount'?: number;
    /**
     * 
     * @type {DocumentTemplateDefaultsDto}
     * @memberof UpdateDocumentTemplateRequest
     */
    'defaults'?: DocumentTemplateDefaultsDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentTemplateRequest
     */
    'draft'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateDocumentTemplateRequestTypeEnum {
    UserPdf = 'USER_PDF',
    Agreement = 'AGREEMENT'
}

/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateFailedMessageStatusBatchStatusEnum {
    Blocked = 'BLOCKED',
    Unprocessed = 'UNPROCESSED',
    Processed = 'PROCESSED',
    Failed = 'FAILED',
    ManualOverride = 'MANUAL_OVERRIDE',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

/**
 * 
 * @export
 * @interface UpdateFolderRequest
 */
export interface UpdateFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePacketTemplateRequest
 */
export interface UpdatePacketTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePacketTemplateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateSigneeRequest
 */
export interface UpdateSigneeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSigneeRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSigneeRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * AgreementControllerApi - axios parameter creator
 * @export
 */
export const AgreementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create agreement
         * @param {CreateAgreementRequest} createAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreement: async (createAgreementRequest: CreateAgreementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgreementRequest' is not null or undefined
            assertParamExists('createAgreement', 'createAgreementRequest', createAgreementRequest)
            const localVarPath = `/api/v1/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgreementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementControllerApi - functional programming interface
 * @export
 */
export const AgreementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create agreement
         * @param {CreateAgreementRequest} createAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgreement(createAgreementRequest: CreateAgreementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreement(createAgreementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementControllerApi - factory interface
 * @export
 */
export const AgreementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create agreement
         * @param {CreateAgreementRequest} createAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreement(createAgreementRequest: CreateAgreementRequest, options?: any): AxiosPromise<CreateAgreementResponse> {
            return localVarFp.createAgreement(createAgreementRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgreementControllerApi - object-oriented interface
 * @export
 * @class AgreementControllerApi
 * @extends {BaseAPI}
 */
export class AgreementControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create agreement
     * @param {CreateAgreementRequest} createAgreementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementControllerApi
     */
    public createAgreement(createAgreementRequest: CreateAgreementRequest, options?: AxiosRequestConfig) {
        return AgreementControllerApiFp(this.configuration).createAgreement(createAgreementRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentCertificateControllerApi - axios parameter creator
 * @export
 */
export const DocumentCertificateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve document certificate info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentCertificate: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getDocumentCertificate', 'token', token)
            const localVarPath = `/api/v1/document-certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentCertificateControllerApi - functional programming interface
 * @export
 */
export const DocumentCertificateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentCertificateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve document certificate info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentCertificate(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentCertificate(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentCertificateControllerApi - factory interface
 * @export
 */
export const DocumentCertificateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentCertificateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve document certificate info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentCertificate(token: string, options?: any): AxiosPromise<DocumentCertificateResponse> {
            return localVarFp.getDocumentCertificate(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentCertificateControllerApi - object-oriented interface
 * @export
 * @class DocumentCertificateControllerApi
 * @extends {BaseAPI}
 */
export class DocumentCertificateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve document certificate info
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCertificateControllerApi
     */
    public getDocumentCertificate(token: string, options?: AxiosRequestConfig) {
        return DocumentCertificateControllerApiFp(this.configuration).getDocumentCertificate(token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentControllerApi - axios parameter creator
 * @export
 */
export const DocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add recipients that will receive a document after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipientsForDocument: async (id: string, addRecipientsRequest: AddRecipientsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addRecipientsForDocument', 'id', id)
            // verify required parameter 'addRecipientsRequest' is not null or undefined
            assertParamExists('addRecipientsForDocument', 'addRecipientsRequest', addRecipientsRequest)
            const localVarPath = `/api/v1/documents/{id}/recipients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRecipientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create document
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (createDocumentRequest: CreateDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentRequest' is not null or undefined
            assertParamExists('createDocument', 'createDocumentRequest', createDocumentRequest)
            const localVarPath = `/api/v1/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocument', 'id', id)
            const localVarPath = `/api/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocument', 'id', id)
            const localVarPath = `/api/v1/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download file url for document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentDownloadUrl', 'id', id)
            const localVarPath = `/api/v1/documents/{id}/download-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download file url for document sent for signatures. This includes the before signed file(signed document) and pre signed(unsigned document).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDocumentFileUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentFileUrl', 'id', id)
            const localVarPath = `/api/v1/documents/{id}/sent-file-urls`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFileUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentTemplateFileUrl', 'id', id)
            const localVarPath = `/api/v1/documents/{id}/document-template/file-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImages: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentTemplateImages', 'id', id)
            const localVarPath = `/api/v1/documents/{id}/page-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDocumentUrl1: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getSignedDocumentUrl1', 'documentId', documentId)
            const localVarPath = `/api/v1/documents/{documentId}/signed-file-url`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Determine default document timezone
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimezone: async (transactionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documents/timezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSignatureEmailForDocument: async (id: string, signeeIdListRequest?: SigneeIdListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resendSignatureEmailForDocument', 'id', id)
            const localVarPath = `/api/v1/documents/{id}/resend-signature-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signeeIdListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a document signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSigneeForDocument: async (id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSigneeForDocument', 'id', id)
            // verify required parameter 'signeeId' is not null or undefined
            assertParamExists('updateSigneeForDocument', 'signeeId', signeeId)
            // verify required parameter 'updateSigneeRequest' is not null or undefined
            assertParamExists('updateSigneeForDocument', 'updateSigneeRequest', updateSigneeRequest)
            const localVarPath = `/api/v1/documents/{id}/signees/{signeeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"signeeId"}}`, encodeURIComponent(String(signeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSigneeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add recipients that will receive a document after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecipientsForDocument(id: string, addRecipientsRequest: AddRecipientsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecipientsForDocument(id, addRecipientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create document
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(createDocumentRequest: CreateDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(createDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get download file url for document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDownloadUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDownloadUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get download file url for document sent for signatures. This includes the before signed file(signed document) and pre signed(unsigned document).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDocumentFileUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentFileUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateFileUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateFileUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateImages(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateImages(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedDocumentUrl1(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedDocumentUrl1(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Determine default document timezone
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimezone(transactionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTimezoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimezone(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendSignatureEmailForDocument(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendSignatureEmailForDocument(id, signeeIdListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a document signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSigneeForDocument(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSigneeForDocument(id, signeeId, updateSigneeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add recipients that will receive a document after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipientsForDocument(id: string, addRecipientsRequest: AddRecipientsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addRecipientsForDocument(id, addRecipientsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create document
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(createDocumentRequest: CreateDocumentRequest, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createDocument(createDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: string, options?: any): AxiosPromise<DocumentResponse> {
            return localVarFp.getDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get download file url for document
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDownloadUrl(id: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getDocumentDownloadUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get download file url for document sent for signatures. This includes the before signed file(signed document) and pre signed(unsigned document).
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDocumentFileUrl(id: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getDocumentFileUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFileUrl(id: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getDocumentTemplateFileUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImages(id: string, options?: any): AxiosPromise<DocumentTemplateImagesResponse> {
            return localVarFp.getDocumentTemplateImages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDocumentUrl1(documentId: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getSignedDocumentUrl1(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Determine default document timezone
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimezone(transactionId?: string, options?: any): AxiosPromise<DocumentTimezoneResponse> {
            return localVarFp.getTimezone(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSignatureEmailForDocument(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendSignatureEmailForDocument(id, signeeIdListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a document signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSigneeForDocument(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSigneeForDocument(id, signeeId, updateSigneeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add recipients that will receive a document after it has been signed
     * @param {string} id 
     * @param {AddRecipientsRequest} addRecipientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public addRecipientsForDocument(id: string, addRecipientsRequest: AddRecipientsRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).addRecipientsForDocument(id, addRecipientsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create document
     * @param {CreateDocumentRequest} createDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public createDocument(createDocumentRequest: CreateDocumentRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).createDocument(createDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete document
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public deleteDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).deleteDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get download file url for document
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentDownloadUrl(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentDownloadUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get download file url for document sent for signatures. This includes the before signed file(signed document) and pre signed(unsigned document).
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentFileUrl(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentFileUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document template file URL
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentTemplateFileUrl(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentTemplateFileUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document images
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getDocumentTemplateImages(id: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getDocumentTemplateImages(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest signed document file URL
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getSignedDocumentUrl1(documentId: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getSignedDocumentUrl1(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Determine default document timezone
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public getTimezone(transactionId?: string, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).getTimezone(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend signature email to signees
     * @param {string} id 
     * @param {SigneeIdListRequest} [signeeIdListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public resendSignatureEmailForDocument(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).resendSignatureEmailForDocument(id, signeeIdListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a document signee
     * @param {string} id 
     * @param {string} signeeId 
     * @param {UpdateSigneeRequest} updateSigneeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public updateSigneeForDocument(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: AxiosRequestConfig) {
        return DocumentControllerApiFp(this.configuration).updateSigneeForDocument(id, signeeId, updateSigneeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentMigrationControllerApi - axios parameter creator
 * @export
 */
export const DocumentMigrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate V2 pdf migration documents
         * @param {DocumentMigrationRequest} documentMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateV2Pdf: async (documentMigrationRequest: DocumentMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentMigrationRequest' is not null or undefined
            assertParamExists('generateV2Pdf', 'documentMigrationRequest', documentMigrationRequest)
            const localVarPath = `/api/v1/documents/migrations/generate-v2-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMigrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve V2 pdf migration documents
         * @param {Array<string>} [documentIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfMigrations: async (documentIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/documents/migrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentIds) {
                localVarQueryParameter['documentIds'] = Array.from(documentIds);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy V2 pdf migration to document
         * @param {string} migrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateV2PdfToDocument: async (migrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrationId' is not null or undefined
            assertParamExists('migrateV2PdfToDocument', 'migrationId', migrationId)
            const localVarPath = `/api/v1/documents/migrations/{migrationId}/copy-pdf-to-document`
                .replace(`{${"migrationId"}}`, encodeURIComponent(String(migrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentMigrationControllerApi - functional programming interface
 * @export
 */
export const DocumentMigrationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentMigrationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate V2 pdf migration documents
         * @param {DocumentMigrationRequest} documentMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateV2Pdf(documentMigrationRequest: DocumentMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateV2Pdf(documentMigrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve V2 pdf migration documents
         * @param {Array<string>} [documentIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfMigrations(documentIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMigrationPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfMigrations(documentIds, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copy V2 pdf migration to document
         * @param {string} migrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateV2PdfToDocument(migrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateV2PdfToDocument(migrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentMigrationControllerApi - factory interface
 * @export
 */
export const DocumentMigrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentMigrationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate V2 pdf migration documents
         * @param {DocumentMigrationRequest} documentMigrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateV2Pdf(documentMigrationRequest: DocumentMigrationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.generateV2Pdf(documentMigrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve V2 pdf migration documents
         * @param {Array<string>} [documentIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfMigrations(documentIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<DocumentMigrationPageableResponse> {
            return localVarFp.getPdfMigrations(documentIds, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copy V2 pdf migration to document
         * @param {string} migrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateV2PdfToDocument(migrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.migrateV2PdfToDocument(migrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentMigrationControllerApi - object-oriented interface
 * @export
 * @class DocumentMigrationControllerApi
 * @extends {BaseAPI}
 */
export class DocumentMigrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Generate V2 pdf migration documents
     * @param {DocumentMigrationRequest} documentMigrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMigrationControllerApi
     */
    public generateV2Pdf(documentMigrationRequest: DocumentMigrationRequest, options?: AxiosRequestConfig) {
        return DocumentMigrationControllerApiFp(this.configuration).generateV2Pdf(documentMigrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve V2 pdf migration documents
     * @param {Array<string>} [documentIds] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMigrationControllerApi
     */
    public getPdfMigrations(documentIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return DocumentMigrationControllerApiFp(this.configuration).getPdfMigrations(documentIds, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copy V2 pdf migration to document
     * @param {string} migrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentMigrationControllerApi
     */
    public migrateV2PdfToDocument(migrationId: string, options?: AxiosRequestConfig) {
        return DocumentMigrationControllerApiFp(this.configuration).migrateV2PdfToDocument(migrationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentTemplateControllerApi - axios parameter creator
 * @export
 */
export const DocumentTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone document template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneDocumentTemplate: async (id: string, automaticallyShareClone?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cloneDocumentTemplate', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (automaticallyShareClone !== undefined) {
                localVarQueryParameter['automaticallyShareClone'] = automaticallyShareClone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new document template from uploaded PDF
         * @param {File} file 
         * @param {boolean} [oneTimeUse] 
         * @param {DocumentTemplateRequest} [documentTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate: async (file: File, oneTimeUse?: boolean, documentTemplateRequest?: DocumentTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createDocumentTemplate', 'file', file)
            const localVarPath = `/api/v1/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (documentTemplateRequest !== undefined) { 
                localVarFormParams.append('documentTemplateRequest', new Blob([JSON.stringify(documentTemplateRequest)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clone document template from document
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplateFromDocument: async (id: string, oneTimeUse?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDocumentTemplateFromDocument', 'id', id)
            const localVarPath = `/api/v1/document-templates/from-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create draft document template
         * @param {CreateDraftDocumentTemplate} createDraftDocumentTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftDocumentTemplate: async (createDraftDocumentTemplate: CreateDraftDocumentTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDraftDocumentTemplate' is not null or undefined
            assertParamExists('createDraftDocumentTemplate', 'createDraftDocumentTemplate', createDraftDocumentTemplate)
            const localVarPath = `/api/v1/document-templates/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDraftDocumentTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumentTemplate', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentTemplate', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document template file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentTemplateFile', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}/file-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document template images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImages1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentTemplateImages1', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}/page-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document templates metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateMetadata: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve document templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplates: async (sharedWithMe?: boolean, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sharedWithMe !== undefined) {
                localVarQueryParameter['sharedWithMe'] = sharedWithMe;
            }

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyUpdatedDocumentTemplates: async (viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', recentCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates/recently-updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }

            if (recentCount !== undefined) {
                localVarQueryParameter['recentCount'] = recentCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users who have shared any document template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo2: async (name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates/shared-by-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any document template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo2: async (type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates/shared-with-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move document templates to folders
         * @param {MoveTemplatesRequest} moveTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTemplatesToFolders: async (moveTemplatesRequest: MoveTemplatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moveTemplatesRequest' is not null or undefined
            assertParamExists('moveTemplatesToFolders', 'moveTemplatesRequest', moveTemplatesRequest)
            const localVarPath = `/api/v1/document-templates/move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mine and shared document templates
         * @param {Array<string>} [folderIds] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocumentTemplateIncludeSharedDocuments: async (folderIds?: Array<string>, oneTimeUse?: boolean, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-templates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (folderIds) {
                localVarQueryParameter['folderIds'] = Array.from(folderIds);
            }

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share document templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentTemplateWithTeams: async (shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareTemplatesWithTeamsRequest' is not null or undefined
            assertParamExists('shareDocumentTemplateWithTeams', 'shareTemplatesWithTeamsRequest', shareTemplatesWithTeamsRequest)
            const localVarPath = `/api/v1/document-templates/share/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareTemplatesWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share document templates
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentTemplatesWithUsers: async (shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareTemplatesWithUsersRequest' is not null or undefined
            assertParamExists('shareDocumentTemplatesWithUsers', 'shareTemplatesWithUsersRequest', shareTemplatesWithUsersRequest)
            const localVarPath = `/api/v1/document-templates/share/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareTemplatesWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign consent form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signConsentForm: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('signConsentForm', 'id', id)
            const localVarPath = `/api/v1/document-templates/{id}/consent-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare document template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unShareDocumentTemplateWithTeams: async (id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unShareDocumentTemplateWithTeams', 'id', id)
            // verify required parameter 'unshareWithTeamsRequest' is not null or undefined
            assertParamExists('unShareDocumentTemplateWithTeams', 'unshareWithTeamsRequest', unshareWithTeamsRequest)
            const localVarPath = `/api/v1/document-templates/{id}/shared/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare document template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentTemplateWithUsers: async (id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unshareDocumentTemplateWithUsers', 'id', id)
            // verify required parameter 'unshareWithUsersRequest' is not null or undefined
            assertParamExists('unshareDocumentTemplateWithUsers', 'unshareWithUsersRequest', unshareWithUsersRequest)
            const localVarPath = `/api/v1/document-templates/{id}/shared/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update document template
         * @param {string} id 
         * @param {UpdateDocumentTemplateRequest} updateDocumentTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplate: async (id: string, updateDocumentTemplateRequest: UpdateDocumentTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDocumentTemplate', 'id', id)
            // verify required parameter 'updateDocumentTemplateRequest' is not null or undefined
            assertParamExists('updateDocumentTemplate', 'updateDocumentTemplateRequest', updateDocumentTemplateRequest)
            const localVarPath = `/api/v1/document-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateControllerApi - functional programming interface
 * @export
 */
export const DocumentTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone document template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneDocumentTemplate(id: string, automaticallyShareClone?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneDocumentTemplate(id, automaticallyShareClone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new document template from uploaded PDF
         * @param {File} file 
         * @param {boolean} [oneTimeUse] 
         * @param {DocumentTemplateRequest} [documentTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentTemplate(file: File, oneTimeUse?: boolean, documentTemplateRequest?: DocumentTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentTemplate(file, oneTimeUse, documentTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clone document template from document
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentTemplateFromDocument(id: string, oneTimeUse?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentTemplateFromDocument(id, oneTimeUse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create draft document template
         * @param {CreateDraftDocumentTemplate} createDraftDocumentTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftDocumentTemplate(createDraftDocumentTemplate: CreateDraftDocumentTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftDocumentTemplate(createDraftDocumentTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document template file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document template images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateImages1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateImages1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document templates metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateMetadata(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateMetadata(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve document templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplates(sharedWithMe?: boolean, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplates(sharedWithMe, oneTimeUse, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyUpdatedDocumentTemplates(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', recentCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplatePreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyUpdatedDocumentTemplates(viewType, recentCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users who have shared any document template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedByInfo2(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedByInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedByInfo2(name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any document template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedWithInfo2(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedWithInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedWithInfo2(type, name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move document templates to folders
         * @param {MoveTemplatesRequest} moveTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveTemplatesToFolders(moveTemplatesRequest: MoveTemplatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveTemplatesToFolders(moveTemplatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get mine and shared document templates
         * @param {Array<string>} [folderIds] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDocumentTemplateIncludeSharedDocuments(folderIds?: Array<string>, oneTimeUse?: boolean, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDocumentTemplateIncludeSharedDocuments(folderIds, oneTimeUse, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share document templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share document templates
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign consent form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signConsentForm(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signConsentForm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare document template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unShareDocumentTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unShareDocumentTemplateWithTeams(id, unshareWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare document template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshareDocumentTemplateWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshareDocumentTemplateWithUsers(id, unshareWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update document template
         * @param {string} id 
         * @param {UpdateDocumentTemplateRequest} updateDocumentTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentTemplate(id: string, updateDocumentTemplateRequest: UpdateDocumentTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentTemplate(id, updateDocumentTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplateControllerApi - factory interface
 * @export
 */
export const DocumentTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone document template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneDocumentTemplate(id: string, automaticallyShareClone?: boolean, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.cloneDocumentTemplate(id, automaticallyShareClone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new document template from uploaded PDF
         * @param {File} file 
         * @param {boolean} [oneTimeUse] 
         * @param {DocumentTemplateRequest} [documentTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplate(file: File, oneTimeUse?: boolean, documentTemplateRequest?: DocumentTemplateRequest, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createDocumentTemplate(file, oneTimeUse, documentTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clone document template from document
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentTemplateFromDocument(id: string, oneTimeUse?: boolean, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createDocumentTemplateFromDocument(id, oneTimeUse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create draft document template
         * @param {CreateDraftDocumentTemplate} createDraftDocumentTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftDocumentTemplate(createDraftDocumentTemplate: CreateDraftDocumentTemplate, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createDraftDocumentTemplate(createDraftDocumentTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocumentTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplate(id: string, options?: any): AxiosPromise<DocumentTemplateResponse> {
            return localVarFp.getDocumentTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document template file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFile(id: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getDocumentTemplateFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document template images
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImages1(id: string, options?: any): AxiosPromise<DocumentTemplateImagesResponse> {
            return localVarFp.getDocumentTemplateImages1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document templates metadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateMetadata(options?: any): AxiosPromise<DocumentTemplateMetadataResponse> {
            return localVarFp.getDocumentTemplateMetadata(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve document templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplates(sharedWithMe?: boolean, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<DocumentTemplatePageableResponse> {
            return localVarFp.getDocumentTemplates(sharedWithMe, oneTimeUse, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyUpdatedDocumentTemplates(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', recentCount?: number, options?: any): AxiosPromise<DocumentTemplatePreviewResponse> {
            return localVarFp.getRecentlyUpdatedDocumentTemplates(viewType, recentCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users who have shared any document template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo2(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedByInfoPageableResponse> {
            return localVarFp.getSharedByInfo2(name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any document template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo2(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedWithInfoPageableResponse> {
            return localVarFp.getSharedWithInfo2(type, name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move document templates to folders
         * @param {MoveTemplatesRequest} moveTemplatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTemplatesToFolders(moveTemplatesRequest: MoveTemplatesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.moveTemplatesToFolders(moveTemplatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mine and shared document templates
         * @param {Array<string>} [folderIds] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocumentTemplateIncludeSharedDocuments(folderIds?: Array<string>, oneTimeUse?: boolean, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: any): AxiosPromise<DocumentTemplatePageableResponse> {
            return localVarFp.searchDocumentTemplateIncludeSharedDocuments(folderIds, oneTimeUse, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share document templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share document templates
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign consent form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signConsentForm(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.signConsentForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare document template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unShareDocumentTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unShareDocumentTemplateWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare document template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDocumentTemplateWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unshareDocumentTemplateWithUsers(id, unshareWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update document template
         * @param {string} id 
         * @param {UpdateDocumentTemplateRequest} updateDocumentTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplate(id: string, updateDocumentTemplateRequest: UpdateDocumentTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentTemplate(id, updateDocumentTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateControllerApi - object-oriented interface
 * @export
 * @class DocumentTemplateControllerApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Clone document template
     * @param {string} id 
     * @param {boolean} [automaticallyShareClone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public cloneDocumentTemplate(id: string, automaticallyShareClone?: boolean, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).cloneDocumentTemplate(id, automaticallyShareClone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new document template from uploaded PDF
     * @param {File} file 
     * @param {boolean} [oneTimeUse] 
     * @param {DocumentTemplateRequest} [documentTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public createDocumentTemplate(file: File, oneTimeUse?: boolean, documentTemplateRequest?: DocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).createDocumentTemplate(file, oneTimeUse, documentTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clone document template from document
     * @param {string} id 
     * @param {boolean} [oneTimeUse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public createDocumentTemplateFromDocument(id: string, oneTimeUse?: boolean, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).createDocumentTemplateFromDocument(id, oneTimeUse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create draft document template
     * @param {CreateDraftDocumentTemplate} createDraftDocumentTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public createDraftDocumentTemplate(createDraftDocumentTemplate: CreateDraftDocumentTemplate, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).createDraftDocumentTemplate(createDraftDocumentTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete document template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public deleteDocumentTemplate(id: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).deleteDocumentTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplate(id: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document template file
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplateFile(id: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplateFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document template images
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplateImages1(id: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplateImages1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document templates metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplateMetadata(options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplateMetadata(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve document templates
     * @param {boolean} [sharedWithMe] 
     * @param {boolean} [oneTimeUse] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getDocumentTemplates(sharedWithMe?: boolean, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getDocumentTemplates(sharedWithMe, oneTimeUse, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recently updated Document templates
     * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
     * @param {number} [recentCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getRecentlyUpdatedDocumentTemplates(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', recentCount?: number, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getRecentlyUpdatedDocumentTemplates(viewType, recentCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users who have shared any document template with the user
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getSharedByInfo2(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getSharedByInfo2(name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users & teams with whom the user has shared any document template
     * @param {'TEAM' | 'USER' | 'ALL'} [type] 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public getSharedWithInfo2(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).getSharedWithInfo2(type, name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move document templates to folders
     * @param {MoveTemplatesRequest} moveTemplatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public moveTemplatesToFolders(moveTemplatesRequest: MoveTemplatesRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).moveTemplatesToFolders(moveTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get mine and shared document templates
     * @param {Array<string>} [folderIds] 
     * @param {boolean} [oneTimeUse] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public searchDocumentTemplateIncludeSharedDocuments(folderIds?: Array<string>, oneTimeUse?: boolean, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).searchDocumentTemplateIncludeSharedDocuments(folderIds, oneTimeUse, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share document templates with teams
     * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).shareDocumentTemplateWithTeams(shareTemplatesWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share document templates
     * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).shareDocumentTemplatesWithUsers(shareTemplatesWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign consent form
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public signConsentForm(id: string, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).signConsentForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare document template with teams
     * @param {string} id 
     * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public unShareDocumentTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).unShareDocumentTemplateWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare document template with users
     * @param {string} id 
     * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public unshareDocumentTemplateWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).unshareDocumentTemplateWithUsers(id, unshareWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update document template
     * @param {string} id 
     * @param {UpdateDocumentTemplateRequest} updateDocumentTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateControllerApi
     */
    public updateDocumentTemplate(id: string, updateDocumentTemplateRequest: UpdateDocumentTemplateRequest, options?: AxiosRequestConfig) {
        return DocumentTemplateControllerApiFp(this.configuration).updateDocumentTemplate(id, updateDocumentTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderControllerApi - axios parameter creator
 * @export
 */
export const FolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add document templates to a folder
         * @param {string} id 
         * @param {AddDocumentToFolderRequest} addDocumentToFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentTemplate: async (id: string, addDocumentToFolderRequest: AddDocumentToFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addDocumentTemplate', 'id', id)
            // verify required parameter 'addDocumentToFolderRequest' is not null or undefined
            assertParamExists('addDocumentTemplate', 'addDocumentToFolderRequest', addDocumentToFolderRequest)
            const localVarPath = `/api/v1/folders/{id}/document-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDocumentToFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (createFolderRequest: CreateFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolderRequest' is not null or undefined
            assertParamExists('createFolder', 'createFolderRequest', createFolderRequest)
            const localVarPath = `/api/v1/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFolder', 'id', id)
            const localVarPath = `/api/v1/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get folder by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolderById', 'id', id)
            const localVarPath = `/api/v1/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users who have shared any folder with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo1: async (name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/folders/shared-by-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any folder
         * @param {string} [name] 
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo1: async (name?: string, type?: 'TEAM' | 'USER' | 'ALL', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/folders/shared-with-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document templates from a folder
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplates: async (id: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listDocumentTemplates', 'id', id)
            const localVarPath = `/api/v1/folders/{id}/document-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove document template from a folder
         * @param {string} id 
         * @param {string} documentTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentTemplate: async (id: string, documentTemplateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeDocumentTemplate', 'id', id)
            // verify required parameter 'documentTemplateId' is not null or undefined
            assertParamExists('removeDocumentTemplate', 'documentTemplateId', documentTemplateId)
            const localVarPath = `/api/v1/folders/{id}/document-templates/{documentTemplateId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentTemplateId"}}`, encodeURIComponent(String(documentTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for folders
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {string} [folderName] 
         * @param {Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>} [folderType] 
         * @param {string} [parentFolderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFolders: async (viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, folderName?: string, folderType?: Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>, parentFolderId?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/folders/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }

            if (sharedUserIds) {
                localVarQueryParameter['sharedUserIds'] = Array.from(sharedUserIds);
            }

            if (sharedTeamIds) {
                localVarQueryParameter['sharedTeamIds'] = Array.from(sharedTeamIds);
            }

            if (sharedByUserIds) {
                localVarQueryParameter['sharedByUserIds'] = Array.from(sharedByUserIds);
            }

            if (folderName !== undefined) {
                localVarQueryParameter['folderName'] = folderName;
            }

            if (folderType) {
                localVarQueryParameter['folderType'] = Array.from(folderType);
            }

            if (parentFolderId !== undefined) {
                localVarQueryParameter['parentFolderId'] = parentFolderId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign consent to share folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareConsent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shareConsent', 'id', id)
            const localVarPath = `/api/v1/folders/{id}/share-consent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share folders with teams
         * @param {ShareFoldersWithTeamsRequest} shareFoldersWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareFoldersWithTeams: async (shareFoldersWithTeamsRequest: ShareFoldersWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareFoldersWithTeamsRequest' is not null or undefined
            assertParamExists('shareFoldersWithTeams', 'shareFoldersWithTeamsRequest', shareFoldersWithTeamsRequest)
            const localVarPath = `/api/v1/folders/share/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareFoldersWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share folders with users
         * @param {ShareFoldersWithUsersRequest} shareFoldersWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareFoldersWithUsers: async (shareFoldersWithUsersRequest: ShareFoldersWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareFoldersWithUsersRequest' is not null or undefined
            assertParamExists('shareFoldersWithUsers', 'shareFoldersWithUsersRequest', shareFoldersWithUsersRequest)
            const localVarPath = `/api/v1/folders/share/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareFoldersWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare folders with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareFolderWithTeams: async (id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unshareFolderWithTeams', 'id', id)
            // verify required parameter 'unshareWithTeamsRequest' is not null or undefined
            assertParamExists('unshareFolderWithTeams', 'unshareWithTeamsRequest', unshareWithTeamsRequest)
            const localVarPath = `/api/v1/folders/{id}/shared/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare folders with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareFolderWithUsers: async (id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unshareFolderWithUsers', 'id', id)
            // verify required parameter 'unshareWithUsersRequest' is not null or undefined
            assertParamExists('unshareFolderWithUsers', 'unshareWithUsersRequest', unshareWithUsersRequest)
            const localVarPath = `/api/v1/folders/{id}/shared/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} id 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (id: string, updateFolderRequest: UpdateFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFolder', 'id', id)
            // verify required parameter 'updateFolderRequest' is not null or undefined
            assertParamExists('updateFolder', 'updateFolderRequest', updateFolderRequest)
            const localVarPath = `/api/v1/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderControllerApi - functional programming interface
 * @export
 */
export const FolderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add document templates to a folder
         * @param {string} id 
         * @param {AddDocumentToFolderRequest} addDocumentToFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocumentTemplate(id: string, addDocumentToFolderRequest: AddDocumentToFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocumentTemplate(id, addDocumentToFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(createFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get folder by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users who have shared any folder with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedByInfo1(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedByInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedByInfo1(name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any folder
         * @param {string} [name] 
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedWithInfo1(name?: string, type?: 'TEAM' | 'USER' | 'ALL', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedWithInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedWithInfo1(name, type, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document templates from a folder
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocumentTemplates(id: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocumentTemplates(id, name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove document template from a folder
         * @param {string} id 
         * @param {string} documentTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDocumentTemplate(id: string, documentTemplateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDocumentTemplate(id, documentTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for folders
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {string} [folderName] 
         * @param {Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>} [folderType] 
         * @param {string} [parentFolderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFolders(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, folderName?: string, folderType?: Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>, parentFolderId?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFolders(viewType, sharedUserIds, sharedTeamIds, sharedByUserIds, folderName, folderType, parentFolderId, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign consent to share folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareConsent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareConsent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share folders with teams
         * @param {ShareFoldersWithTeamsRequest} shareFoldersWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareFoldersWithTeams(shareFoldersWithTeamsRequest: ShareFoldersWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareFoldersWithTeams(shareFoldersWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share folders with users
         * @param {ShareFoldersWithUsersRequest} shareFoldersWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareFoldersWithUsers(shareFoldersWithUsersRequest: ShareFoldersWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareFoldersWithUsers(shareFoldersWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare folders with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshareFolderWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshareFolderWithTeams(id, unshareWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare folders with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshareFolderWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshareFolderWithUsers(id, unshareWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} id 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(id: string, updateFolderRequest: UpdateFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(id, updateFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderControllerApi - factory interface
 * @export
 */
export const FolderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add document templates to a folder
         * @param {string} id 
         * @param {AddDocumentToFolderRequest} addDocumentToFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentTemplate(id: string, addDocumentToFolderRequest: AddDocumentToFolderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addDocumentTemplate(id, addDocumentToFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderRequest} createFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(createFolderRequest: CreateFolderRequest, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createFolder(createFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get folder by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderById(id: string, options?: any): AxiosPromise<FolderResponse> {
            return localVarFp.getFolderById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users who have shared any folder with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo1(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedByInfoPageableResponse> {
            return localVarFp.getSharedByInfo1(name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any folder
         * @param {string} [name] 
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo1(name?: string, type?: 'TEAM' | 'USER' | 'ALL', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedWithInfoPageableResponse> {
            return localVarFp.getSharedWithInfo1(name, type, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document templates from a folder
         * @param {string} id 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentTemplates(id: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: any): AxiosPromise<DocumentTemplatePageableResponse> {
            return localVarFp.listDocumentTemplates(id, name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove document template from a folder
         * @param {string} id 
         * @param {string} documentTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentTemplate(id: string, documentTemplateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeDocumentTemplate(id, documentTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for folders
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {string} [folderName] 
         * @param {Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>} [folderType] 
         * @param {string} [parentFolderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFolders(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, folderName?: string, folderType?: Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>, parentFolderId?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: any): AxiosPromise<FolderPageableResponse> {
            return localVarFp.searchFolders(viewType, sharedUserIds, sharedTeamIds, sharedByUserIds, folderName, folderType, parentFolderId, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign consent to share folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareConsent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.shareConsent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share folders with teams
         * @param {ShareFoldersWithTeamsRequest} shareFoldersWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareFoldersWithTeams(shareFoldersWithTeamsRequest: ShareFoldersWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shareFoldersWithTeams(shareFoldersWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share folders with users
         * @param {ShareFoldersWithUsersRequest} shareFoldersWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareFoldersWithUsers(shareFoldersWithUsersRequest: ShareFoldersWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.shareFoldersWithUsers(shareFoldersWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare folders with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareFolderWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unshareFolderWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare folders with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareFolderWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unshareFolderWithUsers(id, unshareWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} id 
         * @param {UpdateFolderRequest} updateFolderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(id: string, updateFolderRequest: UpdateFolderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFolder(id, updateFolderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderControllerApi - object-oriented interface
 * @export
 * @class FolderControllerApi
 * @extends {BaseAPI}
 */
export class FolderControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add document templates to a folder
     * @param {string} id 
     * @param {AddDocumentToFolderRequest} addDocumentToFolderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public addDocumentTemplate(id: string, addDocumentToFolderRequest: AddDocumentToFolderRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).addDocumentTemplate(id, addDocumentToFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a folder
     * @param {CreateFolderRequest} createFolderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public createFolder(createFolderRequest: CreateFolderRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).createFolder(createFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a folder
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public deleteFolder(id: string, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).deleteFolder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get folder by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public getFolderById(id: string, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).getFolderById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users who have shared any folder with the user
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public getSharedByInfo1(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).getSharedByInfo1(name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users & teams with whom the user has shared any folder
     * @param {string} [name] 
     * @param {'TEAM' | 'USER' | 'ALL'} [type] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public getSharedWithInfo1(name?: string, type?: 'TEAM' | 'USER' | 'ALL', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).getSharedWithInfo1(name, type, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document templates from a folder
     * @param {string} id 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public listDocumentTemplates(id: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT' | 'FOLDER_NAME'>, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).listDocumentTemplates(id, name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove document template from a folder
     * @param {string} id 
     * @param {string} documentTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public removeDocumentTemplate(id: string, documentTemplateId: string, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).removeDocumentTemplate(id, documentTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for folders
     * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
     * @param {Array<string>} [sharedUserIds] 
     * @param {Array<string>} [sharedTeamIds] 
     * @param {Array<string>} [sharedByUserIds] 
     * @param {string} [folderName] 
     * @param {Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>} [folderType] 
     * @param {string} [parentFolderId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public searchFolders(viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, folderName?: string, folderType?: Array<'GLOBAL' | 'STATE_OR_PROVINCE' | 'GENERIC'>, parentFolderId?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).searchFolders(viewType, sharedUserIds, sharedTeamIds, sharedByUserIds, folderName, folderType, parentFolderId, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign consent to share folder
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public shareConsent(id: string, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).shareConsent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share folders with teams
     * @param {ShareFoldersWithTeamsRequest} shareFoldersWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public shareFoldersWithTeams(shareFoldersWithTeamsRequest: ShareFoldersWithTeamsRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).shareFoldersWithTeams(shareFoldersWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share folders with users
     * @param {ShareFoldersWithUsersRequest} shareFoldersWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public shareFoldersWithUsers(shareFoldersWithUsersRequest: ShareFoldersWithUsersRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).shareFoldersWithUsers(shareFoldersWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare folders with teams
     * @param {string} id 
     * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public unshareFolderWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).unshareFolderWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare folders with users
     * @param {string} id 
     * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public unshareFolderWithUsers(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).unshareFolderWithUsers(id, unshareWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a folder
     * @param {string} id 
     * @param {UpdateFolderRequest} updateFolderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public updateFolder(id: string, updateFolderRequest: UpdateFolderRequest, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).updateFolder(id, updateFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderDocumentTemplateControllerApi - axios parameter creator
 * @export
 */
export const FolderDocumentTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search document templates and folders
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {Array<string>} [folderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchDocumentTemplatesAndFolders: async (sharedWithMe?: boolean, sharedOnly?: boolean, folderId?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/document-template-and-folders/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sharedWithMe !== undefined) {
                localVarQueryParameter['sharedWithMe'] = sharedWithMe;
            }

            if (sharedOnly !== undefined) {
                localVarQueryParameter['sharedOnly'] = sharedOnly;
            }

            if (folderId) {
                localVarQueryParameter['folderId'] = Array.from(folderId);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderDocumentTemplateControllerApi - functional programming interface
 * @export
 */
export const FolderDocumentTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderDocumentTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search document templates and folders
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {Array<string>} [folderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async searchDocumentTemplatesAndFolders(sharedWithMe?: boolean, sharedOnly?: boolean, folderId?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderDocumentTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDocumentTemplatesAndFolders(sharedWithMe, sharedOnly, folderId, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderDocumentTemplateControllerApi - factory interface
 * @export
 */
export const FolderDocumentTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderDocumentTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search document templates and folders
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {Array<string>} [folderId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchDocumentTemplatesAndFolders(sharedWithMe?: boolean, sharedOnly?: boolean, folderId?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<FolderDocumentTemplatePageableResponse> {
            return localVarFp.searchDocumentTemplatesAndFolders(sharedWithMe, sharedOnly, folderId, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderDocumentTemplateControllerApi - object-oriented interface
 * @export
 * @class FolderDocumentTemplateControllerApi
 * @extends {BaseAPI}
 */
export class FolderDocumentTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search document templates and folders
     * @param {boolean} [sharedWithMe] 
     * @param {boolean} [sharedOnly] 
     * @param {Array<string>} [folderId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FolderDocumentTemplateControllerApi
     */
    public searchDocumentTemplatesAndFolders(sharedWithMe?: boolean, sharedOnly?: boolean, folderId?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return FolderDocumentTemplateControllerApiFp(this.configuration).searchDocumentTemplatesAndFolders(sharedWithMe, sharedOnly, folderId, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneTimeTemplateControllerApi - axios parameter creator
 * @export
 */
export const OneTimeTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve one-time-use packet and document templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTimeItems: async (templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', name?: string, page?: number, size?: number, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/one-time-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateType !== undefined) {
                localVarQueryParameter['templateType'] = templateType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneTimeTemplateControllerApi - functional programming interface
 * @export
 */
export const OneTimeTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneTimeTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve one-time-use packet and document templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneTimeItems(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', name?: string, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OneTimeTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneTimeItems(templateType, name, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneTimeTemplateControllerApi - factory interface
 * @export
 */
export const OneTimeTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneTimeTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve one-time-use packet and document templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneTimeItems(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', name?: string, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<OneTimeTemplatePageableResponse> {
            return localVarFp.getOneTimeItems(templateType, name, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneTimeTemplateControllerApi - object-oriented interface
 * @export
 * @class OneTimeTemplateControllerApi
 * @extends {BaseAPI}
 */
export class OneTimeTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve one-time-use packet and document templates
     * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneTimeTemplateControllerApi
     */
    public getOneTimeItems(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', name?: string, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return OneTimeTemplateControllerApiFp(this.configuration).getOneTimeItems(templateType, name, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PacketControllerApi - axios parameter creator
 * @export
 */
export const PacketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add recipients that will receive a packet after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipientsForPacket: async (id: string, addRecipientsRequest: AddRecipientsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addRecipientsForPacket', 'id', id)
            // verify required parameter 'addRecipientsRequest' is not null or undefined
            assertParamExists('addRecipientsForPacket', 'addRecipientsRequest', addRecipientsRequest)
            const localVarPath = `/api/v1/packets/{id}/recipients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRecipientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create packet
         * @param {CreatePacketRequest} createPacketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacket: async (createPacketRequest: CreatePacketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPacketRequest' is not null or undefined
            assertParamExists('createPacket', 'createPacketRequest', createPacketRequest)
            const localVarPath = `/api/v1/packets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPacketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePacket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePacket', 'id', id)
            const localVarPath = `/api/v1/packets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download file urls for packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrls: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownloadUrls', 'id', id)
            const localVarPath = `/api/v1/packets/{id}/download-urls`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPacket', 'id', id)
            const localVarPath = `/api/v1/packets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get download file urls for packet sent for signatures
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketDownloadUrls: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPacketDownloadUrls', 'id', id)
            const localVarPath = `/api/v1/packets/{id}/sent-file-urls`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSignatureEmailForPacket: async (id: string, signeeIdListRequest?: SigneeIdListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resendSignatureEmailForPacket', 'id', id)
            const localVarPath = `/api/v1/packets/{id}/resend-signature-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signeeIdListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a packet signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSigneeForPacket: async (id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSigneeForPacket', 'id', id)
            // verify required parameter 'signeeId' is not null or undefined
            assertParamExists('updateSigneeForPacket', 'signeeId', signeeId)
            // verify required parameter 'updateSigneeRequest' is not null or undefined
            assertParamExists('updateSigneeForPacket', 'updateSigneeRequest', updateSigneeRequest)
            const localVarPath = `/api/v1/packets/{id}/signees/{signeeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"signeeId"}}`, encodeURIComponent(String(signeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSigneeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PacketControllerApi - functional programming interface
 * @export
 */
export const PacketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PacketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add recipients that will receive a packet after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecipientsForPacket(id: string, addRecipientsRequest: AddRecipientsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecipientsForPacket(id, addRecipientsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create packet
         * @param {CreatePacketRequest} createPacketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPacket(createPacketRequest: CreatePacketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPacket(createPacketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePacket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePacket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get download file urls for packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadUrls(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrls(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get download file urls for packet sent for signatures
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketDownloadUrls(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketDownloadUrls(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendSignatureEmailForPacket(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendSignatureEmailForPacket(id, signeeIdListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a packet signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSigneeForPacket(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSigneeForPacket(id, signeeId, updateSigneeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PacketControllerApi - factory interface
 * @export
 */
export const PacketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PacketControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add recipients that will receive a packet after it has been signed
         * @param {string} id 
         * @param {AddRecipientsRequest} addRecipientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipientsForPacket(id: string, addRecipientsRequest: AddRecipientsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addRecipientsForPacket(id, addRecipientsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create packet
         * @param {CreatePacketRequest} createPacketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacket(createPacketRequest: CreatePacketRequest, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createPacket(createPacketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePacket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePacket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get download file urls for packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrls(id: string, options?: any): AxiosPromise<PacketDownloadUrlResponse> {
            return localVarFp.getDownloadUrls(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get packet
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacket(id: string, options?: any): AxiosPromise<PacketResponse> {
            return localVarFp.getPacket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get download file urls for packet sent for signatures
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketDownloadUrls(id: string, options?: any): AxiosPromise<PacketDownloadUrlResponse> {
            return localVarFp.getPacketDownloadUrls(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend signature email to signees
         * @param {string} id 
         * @param {SigneeIdListRequest} [signeeIdListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSignatureEmailForPacket(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendSignatureEmailForPacket(id, signeeIdListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a packet signee
         * @param {string} id 
         * @param {string} signeeId 
         * @param {UpdateSigneeRequest} updateSigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSigneeForPacket(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSigneeForPacket(id, signeeId, updateSigneeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PacketControllerApi - object-oriented interface
 * @export
 * @class PacketControllerApi
 * @extends {BaseAPI}
 */
export class PacketControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add recipients that will receive a packet after it has been signed
     * @param {string} id 
     * @param {AddRecipientsRequest} addRecipientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public addRecipientsForPacket(id: string, addRecipientsRequest: AddRecipientsRequest, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).addRecipientsForPacket(id, addRecipientsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create packet
     * @param {CreatePacketRequest} createPacketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public createPacket(createPacketRequest: CreatePacketRequest, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).createPacket(createPacketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete packet
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public deletePacket(id: string, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).deletePacket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get download file urls for packet
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public getDownloadUrls(id: string, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).getDownloadUrls(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get packet
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public getPacket(id: string, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).getPacket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get download file urls for packet sent for signatures
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public getPacketDownloadUrls(id: string, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).getPacketDownloadUrls(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend signature email to signees
     * @param {string} id 
     * @param {SigneeIdListRequest} [signeeIdListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public resendSignatureEmailForPacket(id: string, signeeIdListRequest?: SigneeIdListRequest, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).resendSignatureEmailForPacket(id, signeeIdListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a packet signee
     * @param {string} id 
     * @param {string} signeeId 
     * @param {UpdateSigneeRequest} updateSigneeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketControllerApi
     */
    public updateSigneeForPacket(id: string, signeeId: string, updateSigneeRequest: UpdateSigneeRequest, options?: AxiosRequestConfig) {
        return PacketControllerApiFp(this.configuration).updateSigneeForPacket(id, signeeId, updateSigneeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PacketTemplateControllerApi - axios parameter creator
 * @export
 */
export const PacketTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add document template to existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentTemplates: async (id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addDocumentTemplates', 'id', id)
            // verify required parameter 'documentTemplateIdListRequest' is not null or undefined
            assertParamExists('addDocumentTemplates', 'documentTemplateIdListRequest', documentTemplateIdListRequest)
            const localVarPath = `/api/v1/packet-templates/{id}/document-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentTemplateIdListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clone packet template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePacketTemplate: async (id: string, automaticallyShareClone?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clonePacketTemplate', 'id', id)
            const localVarPath = `/api/v1/packet-templates/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (automaticallyShareClone !== undefined) {
                localVarQueryParameter['automaticallyShareClone'] = automaticallyShareClone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create draft packet template
         * @param {CreateDraftPacketTemplate} createDraftPacketTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftPacketTemplate: async (createDraftPacketTemplate: CreateDraftPacketTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDraftPacketTemplate' is not null or undefined
            assertParamExists('createDraftPacketTemplate', 'createDraftPacketTemplate', createDraftPacketTemplate)
            const localVarPath = `/api/v1/packet-templates/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDraftPacketTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new packet template
         * @param {CreatePacketTemplateRequest} createPacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacketTemplate: async (createPacketTemplateRequest: CreatePacketTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPacketTemplateRequest' is not null or undefined
            assertParamExists('createPacketTemplate', 'createPacketTemplateRequest', createPacketTemplateRequest)
            const localVarPath = `/api/v1/packet-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPacketTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clone packet template from packet
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacketTemplateFromPacket: async (id: string, oneTimeUse?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createPacketTemplateFromPacket', 'id', id)
            const localVarPath = `/api/v1/packet-templates/from-packet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePacketTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePacketTemplate', 'id', id)
            const localVarPath = `/api/v1/packet-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPacketTemplate', 'id', id)
            const localVarPath = `/api/v1/packet-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve packet templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {string} [name] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketTemplates: async (sharedWithMe?: boolean, sharedOnly?: boolean, name?: string, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/packet-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sharedWithMe !== undefined) {
                localVarQueryParameter['sharedWithMe'] = sharedWithMe;
            }

            if (sharedOnly !== undefined) {
                localVarQueryParameter['sharedOnly'] = sharedOnly;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (oneTimeUse !== undefined) {
                localVarQueryParameter['oneTimeUse'] = oneTimeUse;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users who have shared any packet template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo: async (name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/packet-templates/shared-by-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any packet template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo: async (type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/packet-templates/shared-with-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove document template from existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentTemplates: async (id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeDocumentTemplates', 'id', id)
            // verify required parameter 'documentTemplateIdListRequest' is not null or undefined
            assertParamExists('removeDocumentTemplates', 'documentTemplateIdListRequest', documentTemplateIdListRequest)
            const localVarPath = `/api/v1/packet-templates/{id}/document-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentTemplateIdListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share packet templates with users
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePacketTemplate: async (shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareTemplatesWithUsersRequest' is not null or undefined
            assertParamExists('sharePacketTemplate', 'shareTemplatesWithUsersRequest', shareTemplatesWithUsersRequest)
            const localVarPath = `/api/v1/packet-templates/share/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareTemplatesWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share packet templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePacketTemplateWithTeams: async (shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareTemplatesWithTeamsRequest' is not null or undefined
            assertParamExists('sharePacketTemplateWithTeams', 'shareTemplatesWithTeamsRequest', shareTemplatesWithTeamsRequest)
            const localVarPath = `/api/v1/packet-templates/share/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareTemplatesWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare packet template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsharePacketTemplate: async (id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unsharePacketTemplate', 'id', id)
            // verify required parameter 'unshareWithUsersRequest' is not null or undefined
            assertParamExists('unsharePacketTemplate', 'unshareWithUsersRequest', unshareWithUsersRequest)
            const localVarPath = `/api/v1/packet-templates/{id}/shared/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unshare packet template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsharePacketTemplateWithTeams: async (id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unsharePacketTemplateWithTeams', 'id', id)
            // verify required parameter 'unshareWithTeamsRequest' is not null or undefined
            assertParamExists('unsharePacketTemplateWithTeams', 'unshareWithTeamsRequest', unshareWithTeamsRequest)
            const localVarPath = `/api/v1/packet-templates/{id}/shared/teams`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unshareWithTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update what document templates are included in an existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplates: async (id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDocumentTemplates', 'id', id)
            // verify required parameter 'documentTemplateIdListRequest' is not null or undefined
            assertParamExists('updateDocumentTemplates', 'documentTemplateIdListRequest', documentTemplateIdListRequest)
            const localVarPath = `/api/v1/packet-templates/{id}/document-templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentTemplateIdListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update packet template
         * @param {string} id 
         * @param {UpdatePacketTemplateRequest} updatePacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePacketTemplate: async (id: string, updatePacketTemplateRequest: UpdatePacketTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePacketTemplate', 'id', id)
            // verify required parameter 'updatePacketTemplateRequest' is not null or undefined
            assertParamExists('updatePacketTemplate', 'updatePacketTemplateRequest', updatePacketTemplateRequest)
            const localVarPath = `/api/v1/packet-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePacketTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PacketTemplateControllerApi - functional programming interface
 * @export
 */
export const PacketTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PacketTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add document template to existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocumentTemplates(id, documentTemplateIdListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clone packet template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clonePacketTemplate(id: string, automaticallyShareClone?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clonePacketTemplate(id, automaticallyShareClone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create draft packet template
         * @param {CreateDraftPacketTemplate} createDraftPacketTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftPacketTemplate(createDraftPacketTemplate: CreateDraftPacketTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftPacketTemplate(createDraftPacketTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new packet template
         * @param {CreatePacketTemplateRequest} createPacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPacketTemplate(createPacketTemplateRequest: CreatePacketTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPacketTemplate(createPacketTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clone packet template from packet
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPacketTemplateFromPacket(id: string, oneTimeUse?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPacketTemplateFromPacket(id, oneTimeUse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePacketTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePacketTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve packet templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {string} [name] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketTemplates(sharedWithMe?: boolean, sharedOnly?: boolean, name?: string, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketTemplatePageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketTemplates(sharedWithMe, sharedOnly, name, oneTimeUse, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users who have shared any packet template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedByInfo(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedByInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedByInfo(name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any packet template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedWithInfo(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedWithInfoPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedWithInfo(type, name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove document template from existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDocumentTemplates(id, documentTemplateIdListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share packet templates with users
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharePacketTemplate(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharePacketTemplate(shareTemplatesWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share packet templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare packet template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsharePacketTemplate(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsharePacketTemplate(id, unshareWithUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unshare packet template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsharePacketTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsharePacketTemplateWithTeams(id, unshareWithTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update what document templates are included in an existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumentTemplates(id, documentTemplateIdListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update packet template
         * @param {string} id 
         * @param {UpdatePacketTemplateRequest} updatePacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePacketTemplate(id: string, updatePacketTemplateRequest: UpdatePacketTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePacketTemplate(id, updatePacketTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PacketTemplateControllerApi - factory interface
 * @export
 */
export const PacketTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PacketTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add document template to existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clone packet template
         * @param {string} id 
         * @param {boolean} [automaticallyShareClone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clonePacketTemplate(id: string, automaticallyShareClone?: boolean, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.clonePacketTemplate(id, automaticallyShareClone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create draft packet template
         * @param {CreateDraftPacketTemplate} createDraftPacketTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftPacketTemplate(createDraftPacketTemplate: CreateDraftPacketTemplate, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createDraftPacketTemplate(createDraftPacketTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new packet template
         * @param {CreatePacketTemplateRequest} createPacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacketTemplate(createPacketTemplateRequest: CreatePacketTemplateRequest, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createPacketTemplate(createPacketTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clone packet template from packet
         * @param {string} id 
         * @param {boolean} [oneTimeUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPacketTemplateFromPacket(id: string, oneTimeUse?: boolean, options?: any): AxiosPromise<EntityIdResponse> {
            return localVarFp.createPacketTemplateFromPacket(id, oneTimeUse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePacketTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePacketTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve packet template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketTemplate(id: string, options?: any): AxiosPromise<PacketTemplateResponse> {
            return localVarFp.getPacketTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve packet templates
         * @param {boolean} [sharedWithMe] 
         * @param {boolean} [sharedOnly] 
         * @param {string} [name] 
         * @param {boolean} [oneTimeUse] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketTemplates(sharedWithMe?: boolean, sharedOnly?: boolean, name?: string, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<PacketTemplatePageableResponse> {
            return localVarFp.getPacketTemplates(sharedWithMe, sharedOnly, name, oneTimeUse, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users who have shared any packet template with the user
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedByInfo(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedByInfoPageableResponse> {
            return localVarFp.getSharedByInfo(name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users & teams with whom the user has shared any packet template
         * @param {'TEAM' | 'USER' | 'ALL'} [type] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithInfo(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: any): AxiosPromise<SharedWithInfoPageableResponse> {
            return localVarFp.getSharedWithInfo(type, name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove document template from existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share packet templates with users
         * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePacketTemplate(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sharePacketTemplate(shareTemplatesWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share packet templates with teams
         * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare packet template with users
         * @param {string} id 
         * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsharePacketTemplate(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unsharePacketTemplate(id, unshareWithUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unshare packet template with teams
         * @param {string} id 
         * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsharePacketTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unsharePacketTemplateWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update what document templates are included in an existing packet template
         * @param {string} id 
         * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update packet template
         * @param {string} id 
         * @param {UpdatePacketTemplateRequest} updatePacketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePacketTemplate(id: string, updatePacketTemplateRequest: UpdatePacketTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePacketTemplate(id, updatePacketTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PacketTemplateControllerApi - object-oriented interface
 * @export
 * @class PacketTemplateControllerApi
 * @extends {BaseAPI}
 */
export class PacketTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add document template to existing packet template
     * @param {string} id 
     * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public addDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).addDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clone packet template
     * @param {string} id 
     * @param {boolean} [automaticallyShareClone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public clonePacketTemplate(id: string, automaticallyShareClone?: boolean, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).clonePacketTemplate(id, automaticallyShareClone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create draft packet template
     * @param {CreateDraftPacketTemplate} createDraftPacketTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public createDraftPacketTemplate(createDraftPacketTemplate: CreateDraftPacketTemplate, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).createDraftPacketTemplate(createDraftPacketTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new packet template
     * @param {CreatePacketTemplateRequest} createPacketTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public createPacketTemplate(createPacketTemplateRequest: CreatePacketTemplateRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).createPacketTemplate(createPacketTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clone packet template from packet
     * @param {string} id 
     * @param {boolean} [oneTimeUse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public createPacketTemplateFromPacket(id: string, oneTimeUse?: boolean, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).createPacketTemplateFromPacket(id, oneTimeUse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete packet template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public deletePacketTemplate(id: string, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).deletePacketTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve packet template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public getPacketTemplate(id: string, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).getPacketTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve packet templates
     * @param {boolean} [sharedWithMe] 
     * @param {boolean} [sharedOnly] 
     * @param {string} [name] 
     * @param {boolean} [oneTimeUse] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public getPacketTemplates(sharedWithMe?: boolean, sharedOnly?: boolean, name?: string, oneTimeUse?: boolean, page?: number, size?: number, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).getPacketTemplates(sharedWithMe, sharedOnly, name, oneTimeUse, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users who have shared any packet template with the user
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public getSharedByInfo(name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).getSharedByInfo(name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users & teams with whom the user has shared any packet template
     * @param {'TEAM' | 'USER' | 'ALL'} [type] 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'TYPE' | 'NAME' | 'EMAIL'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public getSharedWithInfo(type?: 'TEAM' | 'USER' | 'ALL', name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'TYPE' | 'NAME' | 'EMAIL'>, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).getSharedWithInfo(type, name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove document template from existing packet template
     * @param {string} id 
     * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public removeDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).removeDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share packet templates with users
     * @param {ShareTemplatesWithUsersRequest} shareTemplatesWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public sharePacketTemplate(shareTemplatesWithUsersRequest: ShareTemplatesWithUsersRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).sharePacketTemplate(shareTemplatesWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share packet templates with teams
     * @param {ShareTemplatesWithTeamsRequest} shareTemplatesWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest: ShareTemplatesWithTeamsRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).sharePacketTemplateWithTeams(shareTemplatesWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare packet template with users
     * @param {string} id 
     * @param {UnshareWithUsersRequest} unshareWithUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public unsharePacketTemplate(id: string, unshareWithUsersRequest: UnshareWithUsersRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).unsharePacketTemplate(id, unshareWithUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unshare packet template with teams
     * @param {string} id 
     * @param {UnshareWithTeamsRequest} unshareWithTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public unsharePacketTemplateWithTeams(id: string, unshareWithTeamsRequest: UnshareWithTeamsRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).unsharePacketTemplateWithTeams(id, unshareWithTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update what document templates are included in an existing packet template
     * @param {string} id 
     * @param {DocumentTemplateIdListRequest} documentTemplateIdListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public updateDocumentTemplates(id: string, documentTemplateIdListRequest: DocumentTemplateIdListRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).updateDocumentTemplates(id, documentTemplateIdListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update packet template
     * @param {string} id 
     * @param {UpdatePacketTemplateRequest} updatePacketTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketTemplateControllerApi
     */
    public updatePacketTemplate(id: string, updatePacketTemplateRequest: UpdatePacketTemplateRequest, options?: AxiosRequestConfig) {
        return PacketTemplateControllerApiFp(this.configuration).updatePacketTemplate(id, updatePacketTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferenceDataControllerApi - axios parameter creator
 * @export
 */
export const ReferenceDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve document signee types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSigneeTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reference/document-signee-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceDataControllerApi - functional programming interface
 * @export
 */
export const ReferenceDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve document signee types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadSigneeTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentSigneeTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadSigneeTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceDataControllerApi - factory interface
 * @export
 */
export const ReferenceDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve document signee types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSigneeTypes(options?: any): AxiosPromise<DocumentSigneeTypesResponse> {
            return localVarFp.getDownloadSigneeTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceDataControllerApi - object-oriented interface
 * @export
 * @class ReferenceDataControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceDataControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve document signee types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataControllerApi
     */
    public getDownloadSigneeTypes(options?: AxiosRequestConfig) {
        return ReferenceDataControllerApiFp(this.configuration).getDownloadSigneeTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SentItemControllerApi - axios parameter creator
 * @export
 */
export const SentItemControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve total counts of documents and packets by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentDocumentsAndPacketsCountsByStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sent-items/total-by-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve sent items
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {'DOCUMENT' | 'PACKET'} [type] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [signeeName] 
         * @param {string} [signeeEmail] 
         * @param {string} [recipientName] 
         * @param {string} [recipientEmail] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'SIGNED' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentItems: async (viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', type?: 'DOCUMENT' | 'PACKET', searchText?: string, name?: string, signeeName?: string, signeeEmail?: string, recipientName?: string, recipientEmail?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'SIGNED' | 'CREATED_AT'>, sortDir?: string, sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewType' is not null or undefined
            assertParamExists('getSentItems', 'viewType', viewType)
            const localVarPath = `/api/v1/sent-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (signeeName !== undefined) {
                localVarQueryParameter['signeeName'] = signeeName;
            }

            if (signeeEmail !== undefined) {
                localVarQueryParameter['signeeEmail'] = signeeEmail;
            }

            if (recipientName !== undefined) {
                localVarQueryParameter['recipientName'] = recipientName;
            }

            if (recipientEmail !== undefined) {
                localVarQueryParameter['recipientEmail'] = recipientEmail;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SentItemControllerApi - functional programming interface
 * @export
 */
export const SentItemControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SentItemControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve total counts of documents and packets by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSentDocumentsAndPacketsCountsByStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentItemTotalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSentDocumentsAndPacketsCountsByStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve sent items
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {'DOCUMENT' | 'PACKET'} [type] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [signeeName] 
         * @param {string} [signeeEmail] 
         * @param {string} [recipientName] 
         * @param {string} [recipientEmail] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'SIGNED' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSentItems(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', type?: 'DOCUMENT' | 'PACKET', searchText?: string, name?: string, signeeName?: string, signeeEmail?: string, recipientName?: string, recipientEmail?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'SIGNED' | 'CREATED_AT'>, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentItemPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSentItems(viewType, type, searchText, name, signeeName, signeeEmail, recipientName, recipientEmail, page, size, sortDirection, sortBy, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SentItemControllerApi - factory interface
 * @export
 */
export const SentItemControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SentItemControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve total counts of documents and packets by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentDocumentsAndPacketsCountsByStatus(options?: any): AxiosPromise<SentItemTotalsResponse> {
            return localVarFp.getSentDocumentsAndPacketsCountsByStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve sent items
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {'DOCUMENT' | 'PACKET'} [type] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [signeeName] 
         * @param {string} [signeeEmail] 
         * @param {string} [recipientName] 
         * @param {string} [recipientEmail] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'SIGNED' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentItems(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', type?: 'DOCUMENT' | 'PACKET', searchText?: string, name?: string, signeeName?: string, signeeEmail?: string, recipientName?: string, recipientEmail?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'SIGNED' | 'CREATED_AT'>, sortDir?: string, sortProperty?: string, options?: any): AxiosPromise<SentItemPageableResponse> {
            return localVarFp.getSentItems(viewType, type, searchText, name, signeeName, signeeEmail, recipientName, recipientEmail, page, size, sortDirection, sortBy, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SentItemControllerApi - object-oriented interface
 * @export
 * @class SentItemControllerApi
 * @extends {BaseAPI}
 */
export class SentItemControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve total counts of documents and packets by status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SentItemControllerApi
     */
    public getSentDocumentsAndPacketsCountsByStatus(options?: AxiosRequestConfig) {
        return SentItemControllerApiFp(this.configuration).getSentDocumentsAndPacketsCountsByStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve sent items
     * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
     * @param {'DOCUMENT' | 'PACKET'} [type] 
     * @param {string} [searchText] 
     * @param {string} [name] 
     * @param {string} [signeeName] 
     * @param {string} [signeeEmail] 
     * @param {string} [recipientName] 
     * @param {string} [recipientEmail] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'SIGNED' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [sortDir] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SentItemControllerApi
     */
    public getSentItems(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', type?: 'DOCUMENT' | 'PACKET', searchText?: string, name?: string, signeeName?: string, signeeEmail?: string, recipientName?: string, recipientEmail?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'SIGNED' | 'CREATED_AT'>, sortDir?: string, sortProperty?: string, options?: AxiosRequestConfig) {
        return SentItemControllerApiFp(this.configuration).getSentItems(viewType, type, searchText, name, signeeName, signeeEmail, recipientName, recipientEmail, page, size, sortDirection, sortBy, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SignatureAdminControllerApi - axios parameter creator
 * @export
 */
export const SignatureAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create buyer broker agreement
         * @param {CreateBuyerBrokerAgreementRequest} createBuyerBrokerAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuyerBrokerAgreement: async (createBuyerBrokerAgreementRequest: CreateBuyerBrokerAgreementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBuyerBrokerAgreementRequest' is not null or undefined
            assertParamExists('createBuyerBrokerAgreement', 'createBuyerBrokerAgreementRequest', createBuyerBrokerAgreementRequest)
            const localVarPath = `/api/v1/admin/buyer-broker-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBuyerBrokerAgreementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create document for signee on behalf of template owner
         * @param {CreateDocumentOnBehalfOfTemplateOwnerRequest} createDocumentOnBehalfOfTemplateOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentOnBehalfOfTemplateOwner: async (createDocumentOnBehalfOfTemplateOwnerRequest: CreateDocumentOnBehalfOfTemplateOwnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentOnBehalfOfTemplateOwnerRequest' is not null or undefined
            assertParamExists('createDocumentOnBehalfOfTemplateOwner', 'createDocumentOnBehalfOfTemplateOwnerRequest', createDocumentOnBehalfOfTemplateOwnerRequest)
            const localVarPath = `/api/v1/admin/document-for-template-owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentOnBehalfOfTemplateOwnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignatureAdminControllerApi - functional programming interface
 * @export
 */
export const SignatureAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignatureAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create buyer broker agreement
         * @param {CreateBuyerBrokerAgreementRequest} createBuyerBrokerAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest: CreateBuyerBrokerAgreementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create document for signee on behalf of template owner
         * @param {CreateDocumentOnBehalfOfTemplateOwnerRequest} createDocumentOnBehalfOfTemplateOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest: CreateDocumentOnBehalfOfTemplateOwnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SignatureAdminControllerApi - factory interface
 * @export
 */
export const SignatureAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignatureAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create buyer broker agreement
         * @param {CreateBuyerBrokerAgreementRequest} createBuyerBrokerAgreementRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest: CreateBuyerBrokerAgreementRequest, options?: any): AxiosPromise<AuthTokenResponse> {
            return localVarFp.createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create document for signee on behalf of template owner
         * @param {CreateDocumentOnBehalfOfTemplateOwnerRequest} createDocumentOnBehalfOfTemplateOwnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest: CreateDocumentOnBehalfOfTemplateOwnerRequest, options?: any): AxiosPromise<AuthTokenResponse> {
            return localVarFp.createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignatureAdminControllerApi - object-oriented interface
 * @export
 * @class SignatureAdminControllerApi
 * @extends {BaseAPI}
 */
export class SignatureAdminControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create buyer broker agreement
     * @param {CreateBuyerBrokerAgreementRequest} createBuyerBrokerAgreementRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureAdminControllerApi
     */
    public createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest: CreateBuyerBrokerAgreementRequest, options?: AxiosRequestConfig) {
        return SignatureAdminControllerApiFp(this.configuration).createBuyerBrokerAgreement(createBuyerBrokerAgreementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create document for signee on behalf of template owner
     * @param {CreateDocumentOnBehalfOfTemplateOwnerRequest} createDocumentOnBehalfOfTemplateOwnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignatureAdminControllerApi
     */
    public createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest: CreateDocumentOnBehalfOfTemplateOwnerRequest, options?: AxiosRequestConfig) {
        return SignatureAdminControllerApiFp(this.configuration).createDocumentOnBehalfOfTemplateOwner(createDocumentOnBehalfOfTemplateOwnerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SigneeAuthTokenControllerApi - axios parameter creator
 * @export
 */
export const SigneeAuthTokenControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all document tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDocumentAuthTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/signee-auth-tokens/document-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all document tokens by document id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAuthTokensById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentAuthTokensById', 'id', id)
            const localVarPath = `/api/v1/signee-auth-tokens/document-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all packet tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPacketAuthTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/signee-auth-tokens/packet-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all packet tokens by packet id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketAuthTokensById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPacketAuthTokensById', 'id', id)
            const localVarPath = `/api/v1/signee-auth-tokens/packet-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SigneeAuthTokenControllerApi - functional programming interface
 * @export
 */
export const SigneeAuthTokenControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SigneeAuthTokenControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all document tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDocumentAuthTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SigneeDocumentAuthTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentAuthTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all document tokens by document id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentAuthTokensById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SigneeDocumentAuthTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentAuthTokensById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all packet tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPacketAuthTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SigneePacketAuthTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketAuthTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all packet tokens by packet id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketAuthTokensById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SigneePacketAuthTokenResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketAuthTokensById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SigneeAuthTokenControllerApi - factory interface
 * @export
 */
export const SigneeAuthTokenControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigneeAuthTokenControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all document tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDocumentAuthTokens(options?: any): AxiosPromise<Array<SigneeDocumentAuthTokenResponse>> {
            return localVarFp.getDocumentAuthTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all document tokens by document id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAuthTokensById(id: string, options?: any): AxiosPromise<Array<SigneeDocumentAuthTokenResponse>> {
            return localVarFp.getDocumentAuthTokensById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all packet tokens (only available in non-prod environments)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPacketAuthTokens(options?: any): AxiosPromise<Array<SigneePacketAuthTokenResponse>> {
            return localVarFp.getPacketAuthTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all packet tokens by packet id (only available in non-prod environments)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketAuthTokensById(id: string, options?: any): AxiosPromise<Array<SigneePacketAuthTokenResponse>> {
            return localVarFp.getPacketAuthTokensById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SigneeAuthTokenControllerApi - object-oriented interface
 * @export
 * @class SigneeAuthTokenControllerApi
 * @extends {BaseAPI}
 */
export class SigneeAuthTokenControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all document tokens (only available in non-prod environments)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SigneeAuthTokenControllerApi
     */
    public getDocumentAuthTokens(options?: AxiosRequestConfig) {
        return SigneeAuthTokenControllerApiFp(this.configuration).getDocumentAuthTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all document tokens by document id (only available in non-prod environments)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeAuthTokenControllerApi
     */
    public getDocumentAuthTokensById(id: string, options?: AxiosRequestConfig) {
        return SigneeAuthTokenControllerApiFp(this.configuration).getDocumentAuthTokensById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all packet tokens (only available in non-prod environments)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SigneeAuthTokenControllerApi
     */
    public getPacketAuthTokens(options?: AxiosRequestConfig) {
        return SigneeAuthTokenControllerApiFp(this.configuration).getPacketAuthTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all packet tokens by packet id (only available in non-prod environments)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeAuthTokenControllerApi
     */
    public getPacketAuthTokensById(id: string, options?: AxiosRequestConfig) {
        return SigneeAuthTokenControllerApiFp(this.configuration).getPacketAuthTokensById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SigneeDocumentControllerApi - axios parameter creator
 * @export
 */
export const SigneeDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve document with only signee signature
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentFromToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getDocumentFromToken', 'token', token)
            const localVarPath = `/api/v1/signee/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFileUrlFromToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getDocumentTemplateFileUrlFromToken', 'token', token)
            const localVarPath = `/api/v1/signee/documents/document-template/file-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document template images
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImagesFromToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getDocumentTemplateImagesFromToken', 'token', token)
            const localVarPath = `/api/v1/signee/documents/document-template/page-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDocumentUrl: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getSignedDocumentUrl', 'token', token)
            const localVarPath = `/api/v1/signee/documents/signed-file-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit document signature
         * @param {string} token 
         * @param {SubmitSignatureRequest} submitSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSignature: async (token: string, submitSignatureRequest: SubmitSignatureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('submitSignature', 'token', token)
            // verify required parameter 'submitSignatureRequest' is not null or undefined
            assertParamExists('submitSignature', 'submitSignatureRequest', submitSignatureRequest)
            const localVarPath = `/api/v1/signee/documents/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitSignatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SigneeDocumentControllerApi - functional programming interface
 * @export
 */
export const SigneeDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SigneeDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve document with only signee signature
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentFromToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentFromToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateFileUrlFromToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateFileUrlFromToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document template images
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentTemplateImagesFromToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTemplateImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentTemplateImagesFromToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedDocumentUrl(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedDocumentUrl(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit document signature
         * @param {string} token 
         * @param {SubmitSignatureRequest} submitSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSignature(token: string, submitSignatureRequest: SubmitSignatureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitSignature(token, submitSignatureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SigneeDocumentControllerApi - factory interface
 * @export
 */
export const SigneeDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigneeDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve document with only signee signature
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentFromToken(token: string, options?: any): AxiosPromise<DocumentResponse> {
            return localVarFp.getDocumentFromToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document template file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateFileUrlFromToken(token: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getDocumentTemplateFileUrlFromToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document template images
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentTemplateImagesFromToken(token: string, options?: any): AxiosPromise<DocumentTemplateImagesResponse> {
            return localVarFp.getDocumentTemplateImagesFromToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest signed document file URL
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedDocumentUrl(token: string, options?: any): AxiosPromise<DownloadUrlResponse> {
            return localVarFp.getSignedDocumentUrl(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit document signature
         * @param {string} token 
         * @param {SubmitSignatureRequest} submitSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSignature(token: string, submitSignatureRequest: SubmitSignatureRequest, options?: any): AxiosPromise<void> {
            return localVarFp.submitSignature(token, submitSignatureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SigneeDocumentControllerApi - object-oriented interface
 * @export
 * @class SigneeDocumentControllerApi
 * @extends {BaseAPI}
 */
export class SigneeDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve document with only signee signature
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeDocumentControllerApi
     */
    public getDocumentFromToken(token: string, options?: AxiosRequestConfig) {
        return SigneeDocumentControllerApiFp(this.configuration).getDocumentFromToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document template file URL
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeDocumentControllerApi
     */
    public getDocumentTemplateFileUrlFromToken(token: string, options?: AxiosRequestConfig) {
        return SigneeDocumentControllerApiFp(this.configuration).getDocumentTemplateFileUrlFromToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document template images
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeDocumentControllerApi
     */
    public getDocumentTemplateImagesFromToken(token: string, options?: AxiosRequestConfig) {
        return SigneeDocumentControllerApiFp(this.configuration).getDocumentTemplateImagesFromToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest signed document file URL
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeDocumentControllerApi
     */
    public getSignedDocumentUrl(token: string, options?: AxiosRequestConfig) {
        return SigneeDocumentControllerApiFp(this.configuration).getSignedDocumentUrl(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit document signature
     * @param {string} token 
     * @param {SubmitSignatureRequest} submitSignatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneeDocumentControllerApi
     */
    public submitSignature(token: string, submitSignatureRequest: SubmitSignatureRequest, options?: AxiosRequestConfig) {
        return SigneeDocumentControllerApiFp(this.configuration).submitSignature(token, submitSignatureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SigneePacketControllerApi - axios parameter creator
 * @export
 */
export const SigneePacketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get document template files URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketDocumentTemplateFileUrlsFromToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getPacketDocumentTemplateFileUrlsFromToken', 'token', token)
            const localVarPath = `/api/v1/signee/packets/document-templates/file-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest signed document file URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketSignedDocumentUrls: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getPacketSignedDocumentUrls', 'token', token)
            const localVarPath = `/api/v1/signee/packets/documents/signed-file-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve packet with documents auth tokens
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketWithDocumentTokens: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getPacketWithDocumentTokens', 'token', token)
            const localVarPath = `/api/v1/signee/packets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SigneePacketControllerApi - functional programming interface
 * @export
 */
export const SigneePacketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SigneePacketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get document template files URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketDocumentTemplateFileUrlsFromToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketDocumentTemplateFileUrlsFromToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest signed document file URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketSignedDocumentUrls(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketSignedDocumentUrls(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve packet with documents auth tokens
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPacketWithDocumentTokens(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketWithDocumentTokensResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPacketWithDocumentTokens(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SigneePacketControllerApi - factory interface
 * @export
 */
export const SigneePacketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigneePacketControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get document template files URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketDocumentTemplateFileUrlsFromToken(token: string, options?: any): AxiosPromise<PacketDownloadUrlResponse> {
            return localVarFp.getPacketDocumentTemplateFileUrlsFromToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest signed document file URLs for a packet
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketSignedDocumentUrls(token: string, options?: any): AxiosPromise<PacketDownloadUrlResponse> {
            return localVarFp.getPacketSignedDocumentUrls(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve packet with documents auth tokens
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPacketWithDocumentTokens(token: string, options?: any): AxiosPromise<PacketWithDocumentTokensResponse> {
            return localVarFp.getPacketWithDocumentTokens(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SigneePacketControllerApi - object-oriented interface
 * @export
 * @class SigneePacketControllerApi
 * @extends {BaseAPI}
 */
export class SigneePacketControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get document template files URLs for a packet
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneePacketControllerApi
     */
    public getPacketDocumentTemplateFileUrlsFromToken(token: string, options?: AxiosRequestConfig) {
        return SigneePacketControllerApiFp(this.configuration).getPacketDocumentTemplateFileUrlsFromToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest signed document file URLs for a packet
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneePacketControllerApi
     */
    public getPacketSignedDocumentUrls(token: string, options?: AxiosRequestConfig) {
        return SigneePacketControllerApiFp(this.configuration).getPacketSignedDocumentUrls(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve packet with documents auth tokens
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigneePacketControllerApi
     */
    public getPacketWithDocumentTokens(token: string, options?: AxiosRequestConfig) {
        return SigneePacketControllerApiFp(this.configuration).getPacketWithDocumentTokens(token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplatesSearchControllerApi - axios parameter creator
 * @export
 */
export const TemplatesSearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search Document/Packet templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {'DRAFT' | 'PUBLISHED'} [templateStatus] 
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {string} [name] 
         * @param {string} [folderId] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {'NAME' | 'CREATED_AT' | 'UPDATED_AT'} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTemplates: async (templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', templateStatus?: 'DRAFT' | 'PUBLISHED', viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', name?: string, folderId?: string, sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: 'NAME' | 'CREATED_AT' | 'UPDATED_AT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateType !== undefined) {
                localVarQueryParameter['templateType'] = templateType;
            }

            if (templateStatus !== undefined) {
                localVarQueryParameter['templateStatus'] = templateStatus;
            }

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (sharedUserIds) {
                localVarQueryParameter['sharedUserIds'] = Array.from(sharedUserIds);
            }

            if (sharedTeamIds) {
                localVarQueryParameter['sharedTeamIds'] = Array.from(sharedTeamIds);
            }

            if (sharedByUserIds) {
                localVarQueryParameter['sharedByUserIds'] = Array.from(sharedByUserIds);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort.dir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sort.property'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesSearchControllerApi - functional programming interface
 * @export
 */
export const TemplatesSearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesSearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search Document/Packet templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {'DRAFT' | 'PUBLISHED'} [templateStatus] 
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {string} [name] 
         * @param {string} [folderId] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {'NAME' | 'CREATED_AT' | 'UPDATED_AT'} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTemplates(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', templateStatus?: 'DRAFT' | 'PUBLISHED', viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', name?: string, folderId?: string, sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: 'NAME' | 'CREATED_AT' | 'UPDATED_AT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesSearchPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTemplates(templateType, templateStatus, viewType, name, folderId, sharedUserIds, sharedTeamIds, sharedByUserIds, page, size, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesSearchControllerApi - factory interface
 * @export
 */
export const TemplatesSearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesSearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search Document/Packet templates
         * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
         * @param {'DRAFT' | 'PUBLISHED'} [templateStatus] 
         * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
         * @param {string} [name] 
         * @param {string} [folderId] 
         * @param {Array<string>} [sharedUserIds] 
         * @param {Array<string>} [sharedTeamIds] 
         * @param {Array<string>} [sharedByUserIds] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortDir] 
         * @param {'NAME' | 'CREATED_AT' | 'UPDATED_AT'} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTemplates(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', templateStatus?: 'DRAFT' | 'PUBLISHED', viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', name?: string, folderId?: string, sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: 'NAME' | 'CREATED_AT' | 'UPDATED_AT', options?: any): AxiosPromise<TemplatesSearchPageableResponse> {
            return localVarFp.searchTemplates(templateType, templateStatus, viewType, name, folderId, sharedUserIds, sharedTeamIds, sharedByUserIds, page, size, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesSearchControllerApi - object-oriented interface
 * @export
 * @class TemplatesSearchControllerApi
 * @extends {BaseAPI}
 */
export class TemplatesSearchControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search Document/Packet templates
     * @param {'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE'} [templateType] 
     * @param {'DRAFT' | 'PUBLISHED'} [templateStatus] 
     * @param {'MINE' | 'SHARED_WITH_ME' | 'ALL'} [viewType] 
     * @param {string} [name] 
     * @param {string} [folderId] 
     * @param {Array<string>} [sharedUserIds] 
     * @param {Array<string>} [sharedTeamIds] 
     * @param {Array<string>} [sharedByUserIds] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sortDir] 
     * @param {'NAME' | 'CREATED_AT' | 'UPDATED_AT'} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesSearchControllerApi
     */
    public searchTemplates(templateType?: 'DOCUMENT_TEMPLATE' | 'PACKET_TEMPLATE', templateStatus?: 'DRAFT' | 'PUBLISHED', viewType?: 'MINE' | 'SHARED_WITH_ME' | 'ALL', name?: string, folderId?: string, sharedUserIds?: Array<string>, sharedTeamIds?: Array<string>, sharedByUserIds?: Array<string>, page?: number, size?: number, sortDir?: string, sortProperty?: 'NAME' | 'CREATED_AT' | 'UPDATED_AT', options?: AxiosRequestConfig) {
        return TemplatesSearchControllerApiFp(this.configuration).searchTemplates(templateType, templateStatus, viewType, name, folderId, sharedUserIds, sharedTeamIds, sharedByUserIds, page, size, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }
}


