import { FC } from 'react';
import { ChecklistTaskResponseStatusEnum } from '../../openapi/atlantis';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { cn } from '../../utils/classUtils';

export const variantToClassNameMap: EnumMap<
  ChecklistTaskResponseStatusEnum,
  string
> = {
  [ChecklistTaskResponseStatusEnum.NotStarted]: 'bg-[#E0EDFF]',
  [ChecklistTaskResponseStatusEnum.Pending]: 'bg-reskin-yellow-100',
  [ChecklistTaskResponseStatusEnum.InReview]: 'bg-reskin-purple-300',
  [ChecklistTaskResponseStatusEnum.RevisionRequested]: 'bg-reskin-sangria-300',
  [ChecklistTaskResponseStatusEnum.WaitingForReview]: 'bg-reskin-purple-300',
  [ChecklistTaskResponseStatusEnum.Accepted]: 'bg-[#E9F8F1]',
  [ChecklistTaskResponseStatusEnum.Done]: 'bg-[#E9F8F1]',
};

export const variantToTextClassNameMap: EnumMap<
  ChecklistTaskResponseStatusEnum,
  string
> = {
  [ChecklistTaskResponseStatusEnum.NotStarted]: 'text-primary-navy',
  [ChecklistTaskResponseStatusEnum.Pending]: 'text-reskin-yellow-900',
  [ChecklistTaskResponseStatusEnum.InReview]: 'text-[#321268]',
  [ChecklistTaskResponseStatusEnum.RevisionRequested]:
    'text-reskin-sangria-900',
  [ChecklistTaskResponseStatusEnum.WaitingForReview]: 'text-[#321268]',
  [ChecklistTaskResponseStatusEnum.Accepted]: 'text-reskin-midnight-green-900',
  [ChecklistTaskResponseStatusEnum.Done]: 'text-reskin-midnight-green-900',
};
export const statusToName: EnumMap<ChecklistTaskResponseStatusEnum, string> = {
  [ChecklistTaskResponseStatusEnum.NotStarted]: 'Requested',
  [ChecklistTaskResponseStatusEnum.Accepted]: 'Completed',
  [ChecklistTaskResponseStatusEnum.Pending]: 'Pending Review',
  [ChecklistTaskResponseStatusEnum.RevisionRequested]: 'Revision Requested',
  [ChecklistTaskResponseStatusEnum.InReview]: 'In Review',
  [ChecklistTaskResponseStatusEnum.WaitingForReview]: 'Waiting for Review',
  [ChecklistTaskResponseStatusEnum.Done]: 'Done',
};

interface ChecklistStatusProps {
  status: ChecklistTaskResponseStatusEnum;
}

const ChecklistStatus: FC<ChecklistStatusProps> = ({ status }) => {
  const styles = safeEnumMapGet(
    variantToClassNameMap,
    status,
    ChecklistTaskResponseStatusEnum.NotStarted,
  );

  const statusText = safeEnumMapGet(
    statusToName,
    status,
    ChecklistTaskResponseStatusEnum.NotStarted,
  );

  const statusTextClassName = safeEnumMapGet(
    variantToTextClassNameMap,
    status,
    ChecklistTaskResponseStatusEnum.NotStarted,
  );

  return (
    <div
      className={cn(
        'px-3 py-1 w-fit flex justify-center items-center rounded-[28px] text-center',
        styles,
      )}
    >
      <p
        className={cn(
          'font-inter-medium md:text-sm text-xs',
          statusTextClassName,
        )}
      >
        {statusText}
      </p>
    </div>
  );
};

export default ChecklistStatus;
