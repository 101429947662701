import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../utils/classUtils';

type LayoutMenuButtonProps = {
  label: string;
  path: string;
  onClick?: () => void;
  leftIcon?: IconProp;
};

const ConfigDrivenContainerLayoutHeaderNavLink: React.FC<
  LayoutMenuButtonProps
> = ({ label, path, onClick, leftIcon }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cn(
          'bg-white py-2 px-5 rounded-[4px] text-lg leading-6 items-center justify-center text-reskin-primary-dark hover:text-[#F84C6C]',
          { 'bg-[#F2F2F4]': isActive && path !== '#' },
        )
      }
      onClick={onClick}
      end
    >
      {leftIcon && (
        <FontAwesomeIcon
          icon={leftIcon}
          className='text-inherit text-lg leading-6 mr-2'
        />
      )}
      {label}
    </NavLink>
  );
};

export default ConfigDrivenContainerLayoutHeaderNavLink;
