import { capitalize } from 'lodash';
import { EnumMap } from '../types';

export const safeEnumMapGet = <T extends string, R>(
  map: EnumMap<T, R>,
  key: T,
  defaultValue: R,
): R => {
  return map[key] ?? defaultValue;
};

export const capitalizeEnum = (label: string) => {
  return label
    ?.split('_')
    ?.map((str) => prettyEnum(str))
    ?.join(' ');
};

const prettyEnum = (str: string) => {
  return capitalize(str.toLowerCase());
};

export const convertStringToEnums = <D>(txt: string): D =>
  txt.toUpperCase().split(' ').join('_') as unknown as D;
