import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { AuthControllerApi } from '../openapi/keymaker';
import {
  UserControllerApi,
  UserPublicInfo,
  UserResponse,
  UserResponseTypeEnum,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService.ts';
import { AppThunk, AuthState, meResponseData } from '../types';
import { resetApp } from '../utils/authActions';
import { deleteAuthCookie, setUserInLogServices } from '../utils/AuthUtils';
import Logger from '../utils/Logger';
import {
  getKeymakerConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { fetchLoans } from './LoanSlice.ts';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: AuthState = {
  authUserDetail: undefined,
  appCompatible: false,
  userById: {},
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAuthUserDetail(state, action: PayloadAction<UserResponse | undefined>) {
      state.authUserDetail = action.payload;
    },
    saveAppCompatibility(state, action: PayloadAction<boolean>) {
      state.appCompatible = action.payload;
    },
    saveUserById(state, action: PayloadAction<Array<UserPublicInfo>>) {
      action.payload.forEach((userInfo) => {
        state.userById[userInfo.id!] = userInfo;
      });
    },
  },
});

export const { saveAuthUserDetail, saveAppCompatibility, saveUserById } =
  AuthSlice.actions;

export const fetchAuthUserDetail =
  (
    leadId?: string,
    isOnboardingUser: boolean = false,
  ): AppThunk<Promise<meResponseData>> =>
  async (dispatch) => {
    try {
      const { data } = await new UserControllerApi(
        await getYentaConfiguration(),
      ).getCurrentGenericUser();

      if (
        data.type === UserResponseTypeEnum.Generic ||
        !isEmpty(data.onboardings)
      ) {
        // Call loans API only if user is not Onboarding user and onboarding data is empty
        if (!isOnboardingUser && isEmpty(data.onboardings)) {
          const isBorrowerSuccess = await dispatch(fetchLoans(leadId));
          if (!isBorrowerSuccess) {
            Logger.log('Oops! Something went wrong. Please login again.');
            return { isLoginSuccess: false };
          }
        }

        // If user is Onboarding user, then don't save user details in redux.
        // Prevents user redirection to home page in signup
        if (!isOnboardingUser) {
          dispatch(saveAuthUserDetail(data));
        }

        setUserInLogServices(data);

        return { isLoginSuccess: true, userData: data };
      }

      deleteAuthCookie();
      return { isLoginSuccess: false };
    } catch (e: any) {
      Logger.error('Token expired. Please login again', e);
      ErrorService.notify('Token expired. Please login again', e);
      dispatch(showErrorToast('Token expired. Please login again'));
      deleteAuthCookie();
      return { isLoginSuccess: false };
    }
  };

export const signOutUser = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new AuthControllerApi(await getKeymakerConfiguration()).signOut();
    deleteAuthCookie();
    dispatch(resetApp());
  } catch (e: any) {
    Logger.error('Unable to logout', e);
    ErrorService.notify('Unable to logout', e);
  }
};

export default AuthSlice.reducer;
