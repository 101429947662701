import {
  faArrowRightFromBracket,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RealLogoOriginal from '../../assets/img/logo.svg';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch } from '../../slices/store';
import ConfigDrivenContainerLayoutHeaderNavLink from './ConfigDrivenContainerLayoutHeaderNavLink';

type ConfigDrivenContainerLayoutMobileMenuProps = {
  toggleMenu: boolean;
  onClose: () => void;
  hideMyLoans?: boolean;
  hideMyTasks?: boolean;
};

const ConfigDrivenContainerLayoutMobileMenu: React.FC<
  ConfigDrivenContainerLayoutMobileMenuProps
> = ({ toggleMenu, hideMyLoans = false, hideMyTasks = false, onClose }) => {
  const { borrowerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        'bg-white absolute top-0 h-50 rounded-b-3xl shadow-lg w-full p-4',
        toggleMenu ? 'block' : 'hidden',
      )}
    >
      <img
        src={RealLogoOriginal}
        alt='logo'
        width={80}
        height={20}
        className='absolute lg:top-10 top-5 md:left-10 left-5'
      />
      <div className='absolute top-5 right-5'>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          fontSize={30}
          className='text-black'
        />
      </div>
      <div className='flex flex-col space-y-3 justify-center h-full items-start pt-14'>
        {!hideMyLoans && (
          <ConfigDrivenContainerLayoutHeaderNavLink
            label='My Loans'
            path='/loans'
          />
        )}
        {!hideMyTasks && (
          <ConfigDrivenContainerLayoutHeaderNavLink
            label='My Tasks'
            path={`/pre-approval-form/${borrowerId}/tasks`}
          />
        )}
        {borrowerId && (
          <ConfigDrivenContainerLayoutHeaderNavLink
            label='File Cabinet'
            path={`/pre-approval-form/${borrowerId}/file-cabinet`}
          />
        )}
        <ConfigDrivenContainerLayoutHeaderNavLink
          label='Logout'
          path='#'
          leftIcon={faArrowRightFromBracket}
          onClick={async () => {
            await dispatch(signOutUser());
            navigate('/');
          }}
        />
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayoutMobileMenu;
